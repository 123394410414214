import "./UserDetails.scss";

import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../core/costants";
import { dbDataToValMap } from "../../core/Utils";
import { useHistory } from "react-router-dom";

import { Row, Col, Form, Accordion, Card, Button, Modal } from "react-bootstrap";
import FileUploader from "../../components/FileUploader/FileUploader";
import Archive from "../../components/Archive/Archive";
import { createPdf as CPDF } from "../../core/Utils";

const defaultFilters = {
  details: ["employee_id", "inserted_by", "inserted_on", "last_changed"],
  form_data: [],
};

export default function UserDetails(props) {
  const {
    computedMatch: { params },
  } = props;

  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});
  const [parsedUserData, setParsedUserData] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const history = useHistory();

  const showDeleteUserModal = () => {
    setDeleteUserModal(true);
  };
  const hideDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  useEffect(() => {
    if (params.userId) {
      fetch(BACKEND_URL + "/getEmployeeDetails?employeeId=" + params.userId).then((res) => {
        if (res.status === 200) {
          res.json().then((val) => {
            setUserDetails(val.details);
            setUserData(val.data);
            parseFormData(val.data);
          });
        }
      });
    }
  }, [params.userId]);

  const handleShowDetails = (e) => {
    let target = e.target;
    if (target.checked) {
      let curr = { ...filters };
      curr[target.id] = [];
      setFilters(curr);
    } else {
      let curr = { ...filters };
      curr[target.id] = defaultFilters[target.id];
      setFilters(curr);
    }
  };

  const editUserDetails = () => {
    const localization = {
      キャスト: "cast",
      ドライバー: "driver",
      スタッフ: "staff",
    };

    history.push({
      pathname: "/" + localization[userDetails.type] + "/1/" + userDetails.employee_id,
      state: { valMap: dbDataToValMap(userData) },
    });
  };

  const localization = {
    signData: "サイン",
    type: "従業員タイプ",
    name: "名",
    surname: "性",
    fullname: "名前",
    age: "年齢",
    active: "働き中",
    employee_id: "ID",
    inserted_on: "登録日",
    inserted_by: "登録者",
    last_changed: "最後の変更",
    interviewer: "面接担当",
    fake_surname: "希望源氏名 (姓)",
    fake_name: "希望源氏名 (名)",
    address: "住所",
    travel_time: "通勤時間",
    cell_number: "携帯番号",
    identity: "身分証明書",
    pickup_request: "送迎希望",
    prefered_num_work_days: "週何日位働かれたいですか？",
    prefered_not_work_days: "勤務出来ない曜日",
    prefered_work_time: "希望勤務時間",
    expected_salary: "一日幾ら位稼がれたいですか？",
    other_work: "当店以外でお仕事に行かれてますか？",
    actual_status: "現在の状態",
    Q1: "ご結婚はされていますか？",
    Q2: "お子様はいらっしゃいますか？",
    Q3: "あなたのパートナー（彼氏、夫）は、あなたの仕事についてご存じですか？",
    Q4: "お住まい状況",
    q1TArea: "どちらでお知りになりましたか？",
    "q2-2TArea1": "検索の際キーワード",
    "q2-2TArea2": "その他見つかったサイト名",
    "q2-3TArea": "当店に興味を持って頂いた理由",
    q4TArea: "お友達は、なぜ当店をご存知だったのですか？",
    kanji_name: "名",
    furigana_name: "氏名 (ふりがな)",
    kanji_fakename: "希望源氏名",
    hour_pay: "時給",
    furigana_fakename: "源氏名 (ふりがな)",
    birthday: "生年月日",
    hired_date: "採用 年月日",
    retirement_date: "退職年月日",
    q5TArea: "求人雑誌や求人サイトにもっと載せてほしいと思う情報はございませんか？",
    q6TArea: "今回のお店探しで、あなたが重点を置いたことは何ですか？",
    q7TArea: "今回の面接の連絡や問い合わせをする前に、不安はありませんでしたか？",
    childage: "子供年齢",
    q1: "当店を以前からご存知でしたか？",
    q2: "お仕事探しには、何をご利用されましたか？",
    q3: "他のお店にもお問い合わせ（電話・メール）されましたか？",
    q1Cast3: "更衣室は使いやすかったですか？",
    q2Cast3: "更衣室は清潔でしたか？",
    q3Cast3: "待機は過ごしやすかったですか？",
    q4: "女性用トイレは清潔でしたか？",
    q5: "フロアは綺麗でしたか？",
    q1_2: "スタッフの営業中の対応はどうでしたか？",
    q2_2: "スタッフの営業中の対応はどうでしたか？",
    q3_2: "気遣いは出来ていましたか？",
    q4_2: "スタッフの身だしなみはどうでしたか？",
    q5_2: "嫌な部分はありましたか？",
    q1_3: "接客中の雰囲気はどうでしたか？",
    q2_3: "待機中の雰囲気はどうでしかた？",
    q3_3: "仲良くなれたキャストはいましたか？",
    q4_3: "苦手なタイプのキャストはいましたか？",
    q1_4: "客層はどうでしたか？",
    q2_4: "接客はしやすかったですか？",
    q3_4: "対応しづらいお客様はいましたか？",
    q1_5: "給料システムは理解できましたか？",
    sex: "性別",
    work_type: "作業タイプ",
    confirmation_date: "確認日",
    prefecture: "県",
    retired_status: "引退",
    telephone: "電話番号",
    subjectA: "甲",
    subjectB: "乙",
    date: "日にち",
    corporation: "会社名",
    company_address: "会社住所",
    corporation2: "会社名",
    interviewee: "源氏名",
    bankNum: "銀行名 (銀行番号)",
    bankBranch: "支店名 (支店番号)",
    account_type: "アカウント",
    cardNumber: "口座番号",
    accountHolder: "口座名義 (ｶﾀｶﾅ)",
    "Q1#divorced": "離婚",
    "Q1#married": "既婚",
    "Q1#remarried": "再婚",
    "Q1#separated": "別居",
    "Q1#unmarried": "未婚",
    "Q2#q2no": "いない",
    "Q2#q2yes": "いる",
    "Q3#official": "公認",
    "Q3#secret": "内緒",
    "Q3#single": "シングル",
    "Q4#alone": "一人暮らし",
    "Q4#husband": "夫や子供と同居",
    "Q4#parents": "両親と同居",
    "Q4#together": "同棲",
    "identity#passport": "パスポート",
    "identity#resident": "本籍地入りの住民票",
    "pickup_request#pickup_request_no": "無",
    "pickup_request#pickup_request_yes": "有",
    "q1#q1r1": "はい",
    "q1#q1r2": "いいえ",
    "q2#friends": "友人の紹介",
    "q2#other": "その他",
    "q2#pc": "パソコン",
    "q2#review": "口コミ",
    "q2#smartphone": "スマホ",
    "q3#q3r1": "はい",
    "q3#q3r2": "いいえ",
    "q1Cast3#q1r1": "とてもそう思う",
    "q1Cast3#q1r2": "そう思う",
    "q1Cast3#q1r3": "普通",
    "q1Cast3#q1r4": "そう思わない",
    "q1_2#q1_2r1": "とてもそう思う",
    "q1_2#q1_2r2": "そう思う",
    "q1_2#q1_2r3": "普通",
    "q1_2#q1_2r4": "そう思わない",
    "q1_3#q1_3r1": "とてもそう思う",
    "q1_3#q1_3r2": "そう思う",
    "q1_3#q1_3r3": "普通",
    "q1_3#q1_3r4": "そう思わない",
    "q1_4#q1_4r1": "とてもそう思う",
    "q1_4#q1_4r2": "そう思う",
    "q1_4#q1_4r3": "そう思わない",
    "q1_4#q1_4r4": "true",
    "q1_5#q1_5r1": "とてもそう思う",
    "q1_5#q1_5r2": "そう思う",
    "q1_5#q1_5r3": "普通",
    "q1_5#q1_5r4": "そう思わない",
    "q2Cast3#q2r1": "とても良い",
    "q2Cast3#q2r2": "良い",
    "q2Cast3#q2r3": "普通",
    "q2Cast3#q2r4": "良くない",
    "q2_2#q2_2r1": "とても良い",
    "q2_2#q2_2r2": "良い",
    "q2_2#q2_2r3": "普通",
    "q2_2#q2_2r4": "良くない",
    "q2_3#q2_3r1": "とてもそう思う",
    "q2_3#q2_3r2": "そう思う",
    "q2_3#q2_3r3": "普通",
    "q2_3#q2_3r4": "そう思わない",
    "q2_4#q2_4r1": "とても良い",
    "q2_4#q2_4r2": "良い",
    "q2_4#q2_4r3": "普通",
    "q2_4#q2_4r4": "良くない",
    "q3Cast3#q3r1": "とても良い",
    "q3Cast3#q3r2": "良い",
    "q3Cast3#q3r3": "普通",
    "q3Cast3#q3r4": "良くない",
    "q3_2#q3_2r1": "とても良い",
    "q3_2#q3_2r2": "良い",
    "q3_2#q3_2r3": "普通",
    "q3_2#q3_2r4": "良くない",
    "q3_3#q3_3r1": "いた",
    "q3_3#q3_3r2": "いなかった",
    "q3_4#q3_4r1": "いた",
    "q3_4#q3_4r2": "いなかった",
    "q4#q4r1": "とても良い",
    "q4#q4r2": "良い",
    "q4#q4r3": "普通",
    "q4#q4r4": "良くない",
    "q4_2#q4_2r1": "とてもそう思う",
    "q4_2#q4_2r2": "そう思う",
    "q4_2#q4_2r3": "普通",
    "q4_2#q4_2r4": "そう思わない",
    "q4_3#q4_3r1": "いた",
    "q4_3#q4_3r2": "いなかった",
    "q5#q5r1": "とてもそう思う",
    "q5#q5r2": "そう思う",
    "q5#q5r3": "普通",
    "q5#q5r4": "そう思わない",
    "q5_2#q5_2r1": "あった",
    "q5_2#q5_2r2": "無かった",
    "sex#man": "男",
    "sex#woman": "女",
    "work_type#customer_service": "接客業務",
    "work_type#hall_business": "ホール業務",
    "work_type#pick-up": "送迎業務",
    document_type: "身分証明書",
    document_juminhyo: "住民票(本籍地記載)",
    "document_type#document_juminhyo": "住民票(本籍地記載)",
    document_passport: "パスポート",
    "document_type#document_passport": "パスポート",
    "document_type#document_drive_license": "運転免許証",
    document_drive_license: "運転免許証",
    document_toseki_tohon: "戸籍謄本",
    document_residence_card: "在留カード",
    "account_type#current": "普通預金",
    "account_type#ordinary": "当座預金",
    contract_type: "勤務業態",
    school: "最終学歴を教えてください",
    Q6: "借金はございますか？",
    Q8: "ナイト業界の経験は御座いますか？",
    "Q6#q6no": "いない",
    "Q6#q6yes": "ない",
    "Q8#q8no": "いない",
    "Q8#q8yes": "ない",
    "school#middle_school": "中学",
    "school#high_school": "高校",
    "school#vocational_school": "専門学校",
    "school#university": "大学",
    "school#graduate": "大学院",
    "school#enrolled": "在学中",
    "contract_type#full_time": "正社員",
    "contract_type#part_time": "アルバイト",
  };

  const deactivateUser = () => {
    fetch(BACKEND_URL + "/deactivateUser?employeeId=" + params.userId).then((res) => {
      window.location.reload();
    });
  };

  const activateUser = () => {
    fetch(BACKEND_URL + "/activateUser?employeeId=" + params.userId).then((res) => {
      window.location.reload();
    });
  };

  const deleteUser = () => {
    fetch(BACKEND_URL + "/deleteEmployee?employeeId=" + params.userId).then((res) => {
      history.replace("/");
    });
  };

  const parseFormData = (data) => {
    // console.log(data);
    let parsedObj = data.map((d) => {
      return JSON.parse(d.data);
    });
    let uniqueKeys = {};

    for (let i = 0; i < parsedObj.length; i++) {
      uniqueKeys[i] = {};
      const element = parsedObj[i];
      for (const key in element) {
        if (Object.hasOwnProperty.call(element, key)) {
          let nameId = key.split("#");
          if (!uniqueKeys[i][nameId[0]]) uniqueKeys[i][nameId[0]] = [];
          if (typeof element[key] === "boolean") {
            if (element[key] !== false) {
              uniqueKeys[i][nameId[0]].push({ id: nameId[1], value: key });
            }
          } else {
            uniqueKeys[i][nameId[0]].push({ id: nameId[1], value: element[key] });
          }
        }
      }
    }

    setParsedUserData(uniqueKeys);

    console.log(uniqueKeys);
  };

  const getFormDataJsx = (idx) => {
    let objs = parsedUserData[idx];

    if (objs) {
      let res = Object.entries(objs).map((val) => {
        if (val[1].length > 1) {
          return (
            <Row key={val[0]} style={{ padding: "1em 0", borderBottom: "1px solid #DFDFDF" }}>
              <Col md={6}>
                <b>{localization[val[0]] ? localization[val[0]] : val[0]}</b>
              </Col>
              <Col md={6}>
                {/* //TODO : multiple sections */}
                {val[1].map((data) => {
                  // console.log("data ", data);
                  return (
                    <Form.Row key={data.id} style={{ padding: "1em 2em", borderBottom: "1px solid #DFDFDF" }}>
                      {/* <Col md={6}>
                        <b>{localization[data.id] ? localization[data.id] : data.id}</b>
                      </Col> */}
                      <Col md={12}>{localization[data.value] ? localization[data.value] : data.value}</Col>
                    </Form.Row>
                  );
                })}
              </Col>
            </Row>
          );
        } else {
          return (
            <Row key={val[0]} style={{ padding: "1em 0", borderBottom: "1px solid #DFDFDF" }}>
              <Col md={6}>
                <b>{localization[val[0]] ? localization[val[0]] : val[0]}</b>
              </Col>
              {val[1][0] && val[0] !== "signData" && (
                <Col md={6}>{localization[val[1][0].value] ? localization[val[1][0].value] : val[1][0].value.toString()}</Col>
              )}
              {val[0] === "signData" && (
                <Col md={6}>
                  <img src={val[1][0].value} style={{ width: "100%" }} alt="signature" />{" "}
                </Col>
              )}
            </Row>
          );
        }
      });
      return res;
    }
  };

  return (
    <React.Fragment>
      <h4>個人情報</h4>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle as="h3" eventKey="0">
              データ
            </Accordion.Toggle>
            <Form.Check
              style={{ borderTop: "1px solid #DFDFDF", paddingTop: ".3em" }}
              type="checkbox"
              onChange={handleShowDetails}
              value={false}
              label="もっと見る"
              name="details"
              id="details"
            />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {Object.entries(userDetails).map((vals) => {
                return (
                  !filters["details"].includes(vals[0]) && (
                    <Col key={vals[0]}>
                      <Row style={{ padding: "1em 0", borderBottom: "1px solid #DFDFDF" }}>
                        <Col>
                          <b style={{ textTransform: "capitalize" }}>{localization[vals[0]] ? localization[vals[0]] : vals[0]}</b>
                        </Col>
                        <Col>{vals[1]}</Col>
                      </Row>
                    </Col>
                  )
                );
              })}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <h4>その他</h4>
      {Object.entries(userData).map((vals) => {
        return (
          <Accordion key={vals[1].step}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as="h3" eventKey="0">
                  {vals[1].step_label}
                  {/* <Button style={{ float: "right" }}>Download PDF</Button> */}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>{getFormDataJsx(vals[1].step)}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}

      {/* <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle as="h3" eventKey="0">
              Form Data
            </Accordion.Toggle>
            <Form.Check
"              style={{ borderTop: "1px solid #DFDFDF", paddingTop":" ".3em" }}",
              type="checkbox"
              onChange={handleShowDetails}
              value={false}
              label="Show details"
              name="form_data"
              id="form_data"
            />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {Object.entries(userData).map((vals) => {
                return (
                  !filters["form_data"].includes(vals[0]) && (
                    <Col key={vals[0]}>
                      <Row style={{ padding: "1em 0", borderBottom: "1px solid #DFDFDF" }}>
                        <Col xs={12}>
                          <b style={{ textTransform: "capitalize" }}>{vals[0]}</b>
                        </Col>
                         //{console.log(JSON.stringify(vals[1].data))}
                        // <Col>{JSON.stringify(vals[1].data).replace("#", " ")}</Col>
                        {Object.entries(JSON.parse(vals[1].data)).map((v) => {
                          // console.log(vals);
                          return (
                            <React.Fragment>
                              <Col xs={6}>
                                <b>{v[0]}</b>
                              </Col>
                              <Col xs={6}>{v[1].toString()}</Col>
                            </React.Fragment>
                          );
                        })}
                      </Row>
                    </Col>
                  )
                );
              })}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion> */}

      <Form.Row style={{ paddingTop: "2em" }}>
        <Col>
          <Button onClick={editUserDetails}>編集</Button>
        </Col>
        <Col>
          {userDetails.active === 1 ? (
            <Button variant="danger" style={{ float: "right" }} onClick={deactivateUser}>
              隠す
            </Button>
          ) : (
            <Button variant="danger" style={{ float: "right" }} onClick={activateUser}>
              アクティブ
            </Button>
          )}
        </Col>
        <Col>
          <Button variant="danger" style={{ float: "right" }} onClick={showDeleteUserModal}>
            削除
          </Button>
        </Col>
      </Form.Row>
      <Archive userId={params.userId}></Archive>
      <Form.Row>
        <h4>写真アップロード</h4>
        <FileUploader userId={params.userId} uploadUrl={BACKEND_URL + "/upload"}></FileUploader>
      </Form.Row>
      {/* <Form.Row>
        <h4>書類アップロード</h4>
        <FileUploader userId={params.userId} uploadUrl={BACKEND_URL + "/upload"}></FileUploader>
      </Form.Row> */}

      <Modal show={deleteUserModal} onHide={hideDeleteUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>{userDetails["fullname"]} 削除</Modal.Title>
        </Modal.Header>
        <Modal.Body>このユーザー削除しますか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideDeleteUserModal}>
            キャンセル
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            削除
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
