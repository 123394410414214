import React from "react";
import logoLong from "../../assets/logos/Page_Logo.jpg";

export default function Loading() {
  return (
    <React.Fragment>
      <div id="header" className="logo-container">
        <img src={logoLong} alt="logo" />
      </div>
      <div style={{ position: "relative", margin: "auto", textAlign: "center", fontSize: "6em", marginTop: "35vh" }}>...</div>
    </React.Fragment>
  );
}
