import "./Panel.scss";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { BACKEND_URL } from "../../core/costants";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";

import Table from "../../components/Table/table";

import React from "react";

const defaultEmployeeFilter = ["name", "surname", "age", "active", "id", "inserted_on", "last_changed", "agency_address"];

export default function Panel(props) {
  const history = useHistory();

  const [modalState, setModalState] = useState(false);
  const [modalManager, setModalManager] = useState(false);
  const [modalEditManager, setModalEditManager] = useState(false);
  const [editManagerUsername, setEditManagerUsername] = useState(undefined);
  const [editManagerId, setEditManagerId] = useState(undefined);
  const [editManagerStatus, setEditManagerStatus] = useState(-1);
  const [editManagerData, setEditManagerData] = useState({});
  const [addedManagerStatus, setAddedManagerStatus] = useState(-1);
  const [addedManagerUsername, setAddedManagerUsername] = useState("");
  const [mangerValidated, setMangerValidated] = useState(false);

  const [deleteManagerModal, setDeleteManagerModal] = useState(false);

  const localization = {
    cast: "キャスト",
    driver: " ドライバー",
    staff: "スタッフ",
  };

  const newEmployee = (e) => {
    let type = e.target.id;
    history.push({
      pathname: "/" + type + "/1",
    });
  };

  const showModal = () => setModalState(true);
  const resetModalManager = () => {
    setAddedManagerStatus(false);
    setAddedManagerUsername("");
    setMangerValidated(false);
    setModalManager(false);
  };
  const resetModalEditManager = () => {
    setEditManagerUsername(undefined);
    setModalEditManager(false);
  };

  const hideModal = () => setModalState(false);
  const showAdminModal = () => setModalManager(true);
  const hideAdminModal = () => resetModalManager();
  const showEditAdminModal = () => setModalEditManager(true);
  const hideEditAdminModal = () => resetModalEditManager();
  const showDeleteManagerModal = () => setDeleteManagerModal(true);
  const hideDeleteManagerModal = () => setDeleteManagerModal(false);

  const addManager = () => {
    const username = document.getElementById("username");
    const psw = document.getElementById("psw");
    const name = document.getElementById("name");
    const agency_address = document.getElementById("agency_address");
    const agency_name = document.getElementById("agency_name");

    if (
      username.checkValidity() &&
      psw.checkValidity() &&
      name.checkValidity() &&
      agency_address.checkValidity() &&
      agency_name.checkValidity()
    ) {
      setAddedManagerUsername(username.value);

      let body = {
        username: username.value,
        password: psw.value,
        name: name.value,
        agency_name: agency_name.value,
        agency_address: agency_address.value,
      };

      fetch(BACKEND_URL + "/signup", {
        method: "POST",
        body: objToFormEncode(body),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {
        if (res.status === 204) {
          setAddedManagerStatus(0);
          window.location.reload();
        }

        if (res.status === 409) {
          setAddedManagerStatus(1);
        }
        if (res.status === 500) {
          setAddedManagerStatus(2);
        }
      });
    }

    setMangerValidated(true);
  };
  const editManager = () => {
    const username = document.getElementById("edit_username");
    const psw = document.getElementById("edit_psw");
    const name = document.getElementById("edit_name");
    const agency_address = document.getElementById("edit_agency_address");
    const agency_name = document.getElementById("edit_agency_name");

    let body = {
      username: username.value,
      name: name.value,
      psw: psw.value,
      agency_name: agency_name.value,
      agency_address: agency_address.value,
    };

    let filterEmpty = {};

    for (const key in body) {
      if (Object.hasOwnProperty.call(body, key)) {
        if (body[key]) {
          filterEmpty[key] = body[key];
        }
      }
    }

    fetch(BACKEND_URL + "/editManager?manager_id=" + editManagerId, {
      method: "POST",
      body: objToFormEncode(filterEmpty),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((res) => {
      if (res.status === 204) {
        setEditManagerStatus(0);
        window.location.reload();
      }
      if (res.status === 400) {
        setEditManagerStatus(1);
        window.location.reload();
      }
    });
  };

  const objToFormEncode = (obj) => {
    var formBody = [];
    for (var property in obj) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(obj[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
  };

  const logout = () => {
    fetch(BACKEND_URL + "/logout")
      .then(() => {
        history.replace("/login");
      })
      .catch(console.error);
  };

  const openDetailsColName = "他";

  const [be_employees, setDataEmployees] = useState([]);
  const [be_managers, setDataManagers] = useState([]);
  const [hideFieldsEmployees, setHideFielsEmployees] = useState(defaultEmployeeFilter);
  const [hideFieldsManagers, setHideFielsManagers] = useState(["user_id"]);

  const buildColums = (data, filters) => {
    let dModel = data[0];

    let localization = {
      type: "職業種",
      fullname: "名前",
      inserted_by: "登録者",
      agency_name: "会社名",
      agency_address: "会社住所",
      inserted_on: "登録日",
      last_changed: "最後の変更",
      age: "年齢",
      active: "働き中",
      id: "ID",
      user_id: "ID",
      username: "ユーザー名",
      name: "名前",
    };

    let res = [];

    for (const key in dModel) {
      if (!filters.includes(key)) {
        res.push({
          Header: localization[key] ? localization[key] : key, //Capitalize first letter
          accessor: key,
        });
      }
    }

    res.push({
      Header: openDetailsColName, //Capitalize first letter
      accessor: openDetailsColName,
    });

    return res;
  };

  const handleShowDetailsEmployees = (e) => {
    if (e.target.checked) {
      setHideFielsEmployees(props.role === "admin" ? ["name", "surname"] : ["name", "surname", "agency_address", "agency_name"]);
    } else {
      setHideFielsEmployees(props.role === "admin" ? defaultEmployeeFilter : [...defaultEmployeeFilter, "agency_name"]);
    }
  };

  const handleShowDetailsManagers = (e) => {
    if (e.target.checked) {
      setHideFielsManagers([]);
    } else {
      setHideFielsManagers(["user_id"]);
    }
  };

  const columnsEmployees = React.useMemo(
    () => buildColums(be_employees, hideFieldsEmployees),
    [be_employees, hideFieldsEmployees]
  );
  const dataEmployees = React.useMemo(() => be_employees, [be_employees]);

  const columnsManagers = React.useMemo(() => buildColums(be_managers, hideFieldsManagers), [be_managers, hideFieldsManagers]);
  const dataManagers = React.useMemo(() => be_managers, [be_managers]);

  const openDetailsEmployee = (event) => {
    let row = event.target.parentElement.parentElement;
    if (row.id) {
      history.push("/details/" + row.id);
    }
  };

  const openEditManager = (event) => {
    let row = event.target.parentElement.parentElement;
    if (row.id) {
      setEditManagerId(row.id);
      setEditManagerUsername(row.name);
      setEditManagerData({
        username: row.getAttribute("username"),
        name: row.getAttribute("name"),
        active: row.getAttribute("active") === "0" ? false : true,
        agency_name: row.getAttribute("agency_name"),
        agency_address: row.getAttribute("agency_address"),
      });
      showEditAdminModal();
    }
  };

  const removeManager = (event) => {
    if (editManagerId) {
      fetch(BACKEND_URL + "/removeManager?manager_id=" + editManagerId).then((res) => {
        window.location.reload();
      });
    }
  };

  const enableManager = (event) => {
    if (editManagerId) {
      fetch(BACKEND_URL + "/enableManager?manager_id=" + editManagerId).then((res) => {
        window.location.reload();
      });
    }
  };

  const deleteManager = () => {
    fetch(BACKEND_URL + "/deleteManager?manager_id=" + editManagerId).then((res) => {
      window.location.reload();
    });
  };

  useEffect(() => {
    fetch(BACKEND_URL + "/getEmployees").then((data) => {
      data.json().then(setDataEmployees);
    });

    if (props.role === "admin") {
      fetch(BACKEND_URL + "/getManagers").then((data) => {
        data.json().then(setDataManagers);
      });
    }
  }, []);

  useEffect(() => {
    setHideFielsEmployees(props.role === "manager" ? [...defaultEmployeeFilter, "agency_name"] : defaultEmployeeFilter);
  }, [props.role]);

  return (
    <React.Fragment>
      <Form.Row style={{ paddingBottom: "2em" }}>
        <Col>
          <Button id="AddNewEmployee" style={{ marginRight: "1em" }} className="minMobile" onClick={showModal}>
            スタッフ追加
          </Button>
          {props.role === "admin" && (
            <Button id="AddNewManager" className="minMobile" onClick={showAdminModal}>
              管理者追加
            </Button>
          )}

          <Button id="logout" className="minMobile" style={{ float: "right" }} onClick={logout}>
            ログアウト
          </Button>
        </Col>
      </Form.Row>
      <h4>スタッフ一覧</h4>
      <Form.Check
        type="checkbox"
        className="showDetails"
        onChange={handleShowDetailsEmployees}
        value={false}
        label="もっと見る"
        name="sde"
        id="sde"
      />
      <Form.Row style={{ paddingBottom: "5em" }}>
        <Table
          detailsCol={openDetailsColName}
          detailsOnClick={openDetailsEmployee}
          columns={columnsEmployees}
          data={dataEmployees}
          localization={localization}
        />
      </Form.Row>

      {props.role === "admin" && (
        <React.Fragment>
          <h4>管理者一覧</h4>
          <Form.Check
            type="checkbox"
            className="showDetails"
            onChange={handleShowDetailsManagers}
            value={false}
            label="もっと見る"
            name="sdm"
            id="sdm"
          />
          <Form.Row style={{ paddingBottom: "5em" }}>
            <Table
              detailsCol={openDetailsColName}
              detailsOnClick={openEditManager}
              columns={columnsManagers}
              data={dataManagers}
            />
          </Form.Row>
        </React.Fragment>
      )}

      <Modal show={modalState} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>従業員タイプ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-around">
            <Button id="cast" variant="primary" onClick={newEmployee}>
              キャスト
            </Button>
            {/* REMOVED DRIVER BUTTON
             <Button id="driver" variant="primary" onClick={newEmployee}>
               ドライバー
            </Button> 
            */}
            <Button id="staff" variant="primary" onClick={newEmployee}>
              スタッフ
            </Button>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalManager} onHide={hideAdminModal}>
        <Modal.Header closeButton>
          <Modal.Title>管理者追加</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-around">
            <Form id="signupForm">
              <Form.Row className={mangerValidated ? "was-validated" : ""}>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label id="mgrUsernameLabel">ユーザー名</Form.Label>
                    <Form.Control id="username" required type="text" name="username" />
                    <Form.Control.Feedback type="invalid">ユーザー名を入力してください</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label id="mgrPswLabel">パスワード</Form.Label>
                    <Form.Control id="psw" required type="password" name="psw" />
                    <Form.Control.Feedback type="invalid">パスワード入力してください</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className={mangerValidated ? "was-validated" : ""}>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>名前</Form.Label>
                    <Form.Control id="name" required type="text" name="name" />
                    <Form.Control.Feedback type="invalid">店長名を入力してください</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>会社名</Form.Label>
                    <Form.Control id="agency_name" required type="text" name="agency_name" />
                    <Form.Control.Feedback type="invalid">会社名を入力してください</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className={mangerValidated ? "was-validated" : ""}>
                <Col>
                  <Form.Group>
                    <Form.Label>会社住所</Form.Label>
                    <Form.Control id="agency_address" required type="text" name="agency_address" />
                    <Form.Control.Feedback type="invalid">会社住所入力してください</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form>
          </Row>
          {addedManagerStatus === 0 && (
            <Form.Row style={{ color: "green" }} className="justify-content-center">
              <b>{addedManagerUsername}を追加しました!</b>
            </Form.Row>
          )}
          {addedManagerStatus === 1 && (
            <Form.Row className="justify-content-center" style={{ color: "red" }}>
              <b>このユーザー名をすでに存在してます</b>
            </Form.Row>
          )}
          {addedManagerStatus === 2 && (
            <Form.Row className="justify-content-center" style={{ color: "red" }}>
              <b>エラー500: 店長追加出来ませんでした！システム管理者に連絡してください！</b>
            </Form.Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideAdminModal}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={addManager}>
            店長追加
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEditManager} onHide={hideEditAdminModal}>
        <Modal.Header closeButton>
          <Modal.Title>管理者編集ページ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-around">
            <Form id="signupForm">
              <Form.Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>ユーザー名</Form.Label>
                    <Form.Control
                      id="edit_username"
                      required
                      type="text"
                      name="edit_username"
                      defaultValue={editManagerData.username}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>パスワード</Form.Label>
                    <Form.Control id="edit_psw" type="password" name="edit_psw" />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>名前</Form.Label>
                    <Form.Control id="edit_name" required type="text" name="edit_name" defaultValue={editManagerData.name} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>会社名</Form.Label>
                    <Form.Control
                      id="edit_agency_name"
                      required
                      type="text"
                      name="edit_agency_name"
                      defaultValue={editManagerData.agency_name}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>会社住所</Form.Label>
                    <Form.Control
                      id="edit_agency_address"
                      required
                      type="text"
                      name="edit_agency_address"
                      defaultValue={editManagerData.agency_address}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form>
          </Row>
          {editManagerStatus === 0 && (
            <Form.Row style={{ color: "green" }} className="justify-content-center">
              <b>{editManagerUsername}をアップデートしました！</b>
            </Form.Row>
          )}
          {editManagerStatus === 1 && (
            <Form.Row className="justify-content-center" style={{ color: "red" }}>
              <b>エラー500: 店長の編集出来ませんでした！システム管理者に連絡してください！</b>
            </Form.Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideEditAdminModal}>
            キャンセル
          </Button>

          <Button variant="danger" onClick={showDeleteManagerModal}>
            削除
          </Button>

          {editManagerData.active ? (
            <Button variant="danger" onClick={removeManager}>
              隠す
            </Button>
          ) : (
            <Button variant="danger" onClick={enableManager}>
              アクティブ
            </Button>
          )}

          <Button variant="primary" onClick={editManager}>
            保存
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteManagerModal} onHide={hideDeleteManagerModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editManagerData.username} 削除</Modal.Title>
        </Modal.Header>
        <Modal.Body>このユーザー削除しますか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideDeleteManagerModal}>
            キャンセル
          </Button>
          <Button variant="danger" onClick={deleteManager}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
