import "./PdfApp.scss";
import { useEffect } from "react";
import { Button, Container, Form, Modal, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Steps from "../../components/Steps/Steps";
import FlowManager from "../../components/FlowManager/FlowManager";
import buildFlow from "../../core/flows";
import { useHistory } from "react-router-dom";
import React from "react";
import HTML2Pdf, { fromElement, getElementText } from "../../core/htmlToPdf";
import { getFormMapId, toTemplate } from "../../core/Utils";
import { BACKEND_URL } from "../../core/costants";
import { objToURLEncoded } from "../../core/Utils";
import { dbDataToValMap } from "../../core/Utils";
import { labelToFlow } from "../../core/costants";

//TODO: Aggiungere un messaggio per font mancanti

export default function PdfApp(props) {
  const {
    computedMatch: { params },
  } = props;

  const type = props.type;
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editedModalStatus, setEditedModalStatus] = useState(false);
  const [savedEmployeeStatus, setSavedEmployeeStatus] = useState(false);
  const [savedEmployeeModal, setSavedEmployeeModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [valueMap, setValueMap] = useState({});
  const [flow, setFlow] = useState({});
  const [manager_details, setManagerDetails] = useState({});
  const [signData, setSignData] = useState(undefined);
  const [name, setName] = useState("");

  const localization = {
    cast: "キャスト",
    driver: "ドライバー",
    staff: "スタッフ",
  };

  console.log(valueMap);

  const canPrevius = () => {
    return step > 0;
  };
  const canNext = () => {
    return props.totalSteps - 1 !== step;
  };

  const handleNext = () => {
    console.log(valueMap);
    if (canNext()) {
      let link = "/" + type + "/" + (step + 2);
      link += editMode ? "/" + employeeId : "";

      history.push(link);
    }
  };

  const getFormValues = () => {
    let inputs = document.querySelectorAll("input, textarea, select");

    let valMap = valueMap;
    if (!valMap[step] || valMap[step] == []) {
      valMap[step] = {};
    }

    inputs.forEach((element) => {
      if (valMap[step][getFormMapId(element)] === "" || valMap[step][getFormMapId(element)] !== element.value) {
        switch (element.type) {
          case "text":
          case "number":
          case "textarea":
          case "select-one":
            valMap[step][getFormMapId(element)] = element.value;
            setValueMap(valMap);
            break;
          case "radio":
          case "checkbox":
            valMap[step][getFormMapId(element)] = element.checked;
            setValueMap(valMap);
            break;
          default:
        }
      }
    });

    if (signData && signData[step] && document.getElementsByClassName("signContainer").length > 0) {
      let signCopy = signData[step];
      valMap[step]["signData"] = signCopy;
    }

    if (!name && document.getElementById("kanji_name")) {
      setName(document.getElementById("kanji_name").value);
    }
  };

  const saveFormValues = () => {
    if (Object.keys(flow).length > 0 && Object.keys(valueMap[step]).length > 0) {
      let _flow = { ...flow };
      _flow[step].props = valueMap[step];

      setFlow(_flow);
    }
  };

  const handlePrevius = () => {
    if (canPrevius()) {
      console.log(valueMap);
      let link = "/" + type + "/" + step;
      link += editMode ? "/" + employeeId : "";
      history.push(link);
      // history.push("/" + type + "/" + step);
    }
  };

  const saveToDb = () => {
    getFormValues();
    let step_labels = {};

    for (const key in flow) {
      if (Object.hasOwnProperty.call(flow, key)) {
        step_labels[key] = flow[key].pageName;
      }
    }

    console.log("SENDING DATA: ", { data: valueMap, type: localization[type], step_labels: step_labels });

    fetch(BACKEND_URL + "/saveEmployee", {
      method: "POST",
      body: JSON.stringify({ data: valueMap, type: localization[type], step_labels: step_labels }),
    })
      .then((res) => {
        setSavedEmployeeStatus(res.status);
        showSavedEmployeeModal();
      })
      .catch(console.error);
  };

  const saveEditToDb = () => {
    console.log(flow);
    getFormValues();
    fetch(BACKEND_URL + "/editEmployee", {
      method: "POST",
      body: JSON.stringify({ id: employeeId, data: valueMap[step], step: step, step_label: flow[step].pageName, type: type }),
    })
      .then((res) => {
        setEditedModalStatus(res.status);
      })
      .catch(console.error);
  };

  const showEditModal = () => setEditModal(true);

  const hideEditModal = () => {
    setEditModal(false);
    setEditedModalStatus(false);
  };

  const showSavedEmployeeModal = () => {
    setSavedEmployeeModal(true);
  };
  const hideSavedEmployeeModal = () => {
    setSavedEmployeeModal(false);
    setSavedEmployeeStatus(false);

    if (savedEmployeeStatus === 204) history.push("/");
  };

  const parseChanges = () => {
    if (showEditModal && valueMap[step]) {
      let changes = {};
      let inputs = document.querySelectorAll("input, textarea, select");

      inputs.forEach((element) => {
        if (element.type !== "radio" && element.type !== "checkbox") {
          if (valueMap[step][getFormMapId(element)] !== element.value) {
            let label = document.querySelector("label[for='" + element.id + "']");
            if (label) label = label.textContent;
            changes[element.id] = {
              radio: false,
              label: label ? label : element.id,
              after: element.value,
              before: valueMap[step][getFormMapId(element)],
            };
          }
        } else {
          if (valueMap[step][getFormMapId(element)] !== element.checked) {
            let label = document.querySelector("label[for='" + element.id + "']");
            if (label) {
              let parent = label.parentElement;
              while (!parent.classList.contains("col")) {
                parent = parent.parentElement;
              }
              label = parent.querySelector("label").textContent;

              let labelValue = document.querySelector("label[for='" + element.id + "']");
              if (labelValue) labelValue = labelValue.textContent;

              if (!changes[label]) changes[label] = [];
              changes[label].push({
                radio: true,
                label: labelValue ? labelValue : element.id,
                after: element.checked,
              });
            }
          }
        }
      });

      return Object.entries(changes).map((vals) => {
        if (!Array.isArray(vals[1])) {
          return (
            <Form.Row>
              <Col>
                <b>{vals[1].label} </b>
                <Form.Row style={{ paddingLeft: "1em" }}>
                  {vals[1].before} {" => "} {vals[1].after}
                </Form.Row>
              </Col>
            </Form.Row>
          );
        } else {
          return (
            <Form.Row>
              <Col>
                <b>{vals[0]} </b>
                {vals[1].map((element) => {
                  return (
                    <Form.Row style={{ paddingLeft: "1em" }}>
                      {element.label} {" => "} {element.after.toString()}
                    </Form.Row>
                  );
                })}
              </Col>
            </Form.Row>
          );
        }
      });
    }
  };

  const editUserBody = () => {
    if (!editedModalStatus) {
      return parseChanges();
    } else if (editedModalStatus === 200) {
      return (
        <Form.Row style={{ color: "green" }} className="justify-content-center">
          <b>保存しました!</b>
        </Form.Row>
      );
    } else {
      return (
        <Form.Row style={{ color: "red" }} className="justify-content-center">
          <b>エラー500: 従業員の編集は出来ませんでした！システム管理者に連絡してください！</b>
        </Form.Row>
      );
    }
  };

  const onSign = (data, step) => {
    let currentSignData = { ...signData };
    currentSignData[step] = data;

    setSignData(currentSignData);
  };

  useEffect(() => {
    setFlow(buildFlow(type, valueMap));
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.step) {
      getFormValues();
      saveFormValues();
      setStep(+params.step - 1);
    } else {
      history.replace("/" + type + "/" + 1);
    }

    if (params.edit) {
      setEditMode(true);
      setEmployeeId(params.edit);
    } else {
      setEditMode(false);
    }
  }, [params.step]);

  useEffect(() => {
    if (props.location.state && props.location.state.valMap) {
      //IN EDIT CON DATI
      setValueMap(props.location.state.valMap);
      setFlow(buildFlow(type, props.location.state.valMap));

      setName(props.location.state.valMap[0]["name#name"]);
    } else if (params.edit) {
      //REFRESH IN EDIT
      setEditMode(true);
      setEmployeeId(params.edit);

      fetch(BACKEND_URL + "/getEmployeeDetails?employeeId=" + params.edit).then((res) => {
        if (res.status === 200) {
          res
            .json()
            .then((val) => {
              return dbDataToValMap(val.data);
            })
            .then((parsedMap) => {
              setValueMap(parsedMap);
              setFlow(buildFlow(type, parsedMap));

              setName(parsedMap[0]["name#name"]);
            });
        }
      });
    } else {
      //NUOVO EMPLOYEE
      fetch(BACKEND_URL + "/getManagerDetails").then((data) => {
        data
          .json()
          .then((val) => {
            setManagerDetails(val);

            let inverviewer = val.name ? val.name : val.username;

            return dbDataToValMap([
              { step: 0, data: '{ "interviewer#interviewer": "' + inverviewer + '" }' },
              {
                step: labelToFlow[type].indexOf("nd"),
                data:
                  '{"corporation#corporation":"' +
                  val.agency_name +
                  '", "company_address#company_address":"' +
                  val.agency_address +
                  '", "corporation2#corporation2":"' +
                  val.agency_name +
                  '" }',
              },
              {
                step: labelToFlow[type].indexOf("!04") === -1 ? 4 : labelToFlow[type].indexOf("!04"),
                data: '{"subjectA#subjectA":"' + val.agency_name + '" }',
              },
            ]);
          })
          .then((parsedMap) => {
            setValueMap(parsedMap);
            setFlow(buildFlow(type, parsedMap));
          });
      });
    }
  }, [params.edit]);

  return (
    <React.Fragment>
      <Steps totalSteps={props.totalSteps} current={step} {...params} type={type}></Steps>
      <FlowManager
        flow={flow}
        step={step}
        signData={valueMap[step] ? valueMap[step].signData : undefined}
        onCreatePdf={getFormValues}
        onSign={onSign}
        name={name}
        type={type}
      />

      <div className="navigation">
        <Button
          id="annulla"
          style={{ marginRight: "1em" }}
          onClick={() => {
            if (!editMode) history.push("/");
            else history.push("/details/" + employeeId);
          }}
        >
          キャンセル
        </Button>
        <Button id="previus" style={{ marginRight: "1em" }} disabled={!canPrevius()} onClick={handlePrevius}>
          戻る
        </Button>
        <Button style={{ marginRight: "1em" }} id="next" disabled={!canNext()} onClick={handleNext}>
          次
        </Button>
        {editMode && <Button onClick={showEditModal}>保存</Button>}

        {!canNext() && !editMode && (
          <Button onClick={saveToDb} disabled={savedEmployeeStatus}>
            送信
          </Button>
        )}

        <Modal show={savedEmployeeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{savedEmployeeStatus === 204 ? "" : "Error"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {savedEmployeeStatus === 204 ? (
              <h3 style={{ color: "green" }}>従業員追加出来ました</h3>
            ) : (
              <h4 style={{ color: "red" }}>
                エラー500: 従業員追加は出来ませんでした！
                <br />
                システム管理者に連絡してください！
              </h4>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={hideSavedEmployeeModal}>閉じる</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editModal} onHide={hideEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>データを上書きします</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>{editUserBody()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideEditModal}>
              キャンセル
            </Button>
            <Button disabled={editedModalStatus} variant="primary" onClick={saveEditToDb}>
              保存
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
