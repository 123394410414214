import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import React from "react";
import { Button } from "react-bootstrap";
import { toTemplate } from "../../core/Utils";
import { PageSizes } from "pdf-lib";
import HTML2Pdf from "../../core/htmlToPdf";
import fontRegular from "../../font/YuGothR.ttf";
import fontBold from "../../font/YuGothB.ttf";
import { createPdf as CPDF } from "../../core/Utils";

import { BACKEND_URL } from "../../core/costants";
import { useHistory } from "react-router-dom";

function FlowManager(props) {
  const step = props.step;
  const flow = props.flow;

  console.log("PROPS : ", props);

  const [currentData, setCurrentData] = useState({
    component: () => <div></div>,
    props: {},
  });

  const [createTeplate, setCreateTeplate] = useState(false);
  const [template, setTemplate] = useState();
  const [signData, setSignData] = useState(props.signData);
  const history = useHistory();

  const checkFontCache = async () => {
    let regularStatus = await caches.match(fontRegular);
    let boldStatus = await caches.match(fontBold);

    if (!regularStatus || !boldStatus) {
      alert("フオントはまだダウンロード出来ていないのでしばらくたってからまたPDFダウンロードしてみてください！");
      caches.open("fonts").then((cache) => {
        cache.match(fontRegular).then((res) => {
          if (!res) cache.add(fontRegular);
        });
        cache.match(fontBold).then((res) => {
          if (!res) cache.add(fontBold);
        });
      });
      return false;
    }

    return true;
  };

  const createPdf = () => {
    CPDF(
      props.onCreatePdf,
      document.getElementById(currentData.template.options.rootId),
      currentData.template.data,
      currentData.props,
      currentData.template.options,
      document.getElementsByClassName("signContainer").length > 0 ? signData : undefined,
      props.name,
      "[" + currentData.type + "]_" + props.name + "_" + currentData.pageName
    );
    // checkFontCache().then((res) => {
    //   if (res) {
    //     props.onCreatePdf();

    //     new HTML2Pdf(
    //       document.getElementById(currentData.template.options.rootId),
    //       currentData.template.data,
    //       currentData.props,
    //       currentData.template.options,
    //       document.getElementsByClassName("signContainer").length > 0 ? signData : undefined,
    //       props.name,
    //       "[" + currentData.type + "]_" + props.name + "_" + currentData.pageName
    //     );
    //   }
    // });
  };

  const onSign = (data) => {
    setSignData(data);
    props.onSign(data, step);
  };
  const createTemplate = () => {
    setTemplate(
      JSON.stringify(
        toTemplate(document.getElementById("pageContent"), PageSizes.A4, currentData.template.options.pixelBreakPage),
        null,
        "\t"
      )
    );

    let c = { ...currentData };
    c.template = template;

    setCurrentData(c);
    setCreateTeplate(true);
  };

  useEffect(() => {
    if (Object.keys(flow).length > 0) {
      setCurrentData(flow[step]);
    }

    setSignData(props.signData);
  }, [flow, step, currentData]);

  return (
    <React.Fragment>
      <h6
        id="interviewed"
        style={{
          textAlign: "center",
          borderBottom: "1px solid",
          // marginLeft: "50%",
          // transform: "translate(-50%,0)",
          // display: "inline-block",
        }}
      >
        {props.name}
      </h6>
      {currentData.component({
        ...currentData.props,
        type: props.type,
        onSign: onSign,
        data: signData,
        name: props.name,
        step: step,
      })}
      <Button style={{ marginTop: "1em" }} onClick={createPdf}>
        ダウンロード
      </Button>
      {/* <Button style={{ marginTop: "1em" }} onClick={createTemplate}>
        Create Template
      </Button> */}
      {createTeplate && (
        <pre>
          <code>{template}</code>
        </pre>
      )}
    </React.Fragment>
  );
}

export default FlowManager;
