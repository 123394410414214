import { BACKEND_URL } from "./costants";

export async function getStatus(callback, role_callback) {
  let val = await fetch(BACKEND_URL + "/status");
  let res = val.status === 200 ? true : false;
  if (res) {
    let body = await val.json();
    role_callback.call(this, body.role);
  }
  callback.call(this, res);
  return res;
}
