import React, { useState, useEffect } from "react";
import { Col, FormGroup, Row, Form, InputGroup } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import { toTemplate } from "../../../core/Utils";
import Datetime from "react-datetime";
import WorkingStyle from "../Common/WorkingStyle";
import GeneralInfo from "../Common/GeneralInfo";
import ActualStatus from "../Common/ActualStatus";

export const label = "01";

export function Staff_01(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">店舗車利用規約《スタッフ用》</h2>
      </Form.Row>
      <Form.Row>
        <Col sm={6}>
          <Form.Label id="p149">車の外装はもちろん、車内も常に綺麗な状態を保つ</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p150">
          • 洗車を毎月１回は必ず行い、台風の後も水洗いでいいので必ず洗車する &nbsp;
          その際の費用はレシートがあれば店舗で清算をしていいものとする
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p151">• 車に関わる不具合は些細なことでもすぐに報告をする</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p152">• 車を利用する際は、目視にて必ず毎回傷が無いか車体全体の確認をする</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p153">• 事故を起こしたらもちろん、小さな擦り傷１つでもつけたら &nbsp;その日に報告をする</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p154">
            • 如何なる状況下においても飲酒運転をしない。その際に起きた事故等には当店は一切の関与をしない
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p155">
            • 送迎中の事故の場合、業務中の為、 &nbsp;過失の割合から判断し２割〜８割の修繕費を負担してもらう
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p156">
          • 営業時間外の買い出しなどの業務中の事故の場合、 &nbsp;過失の割合から３割〜８割の修繕費を負担してもらう
        </Form.Label>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p157">• 報告が１日以上遅れた状態で傷の存在が判明した場合、 &nbsp;修繕費を全額負担してもらう</Form.Label>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p158">
            • 当て逃げの可能性が低い場合の傷が新たに判明し、原因不明となった場合は連帯責任となり、
            &nbsp;修繕費全額を車の利用者全員に負担してもらう
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p159">• 入寮者、スタッフ以外の人に絶対に運転をさせない</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Label id="p160">
          <b>借りている車であることを常に忘れずに利用します。上記の内容を理解し同意しました。</b>
        </Form.Label>
      </Form.Row>

      <Signature {...props} />
      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
      */}
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <Staff_01 {...props}></Staff_01>;

component[label + "PageName"] = "店舗車利用規約《スタッフ用》";
component[label + "Template"] = {
  data: {
    interviewed: { page: 0, normalized: { x: 0, y: 0.10952168749999422 }, wraps: false, x: 40, y: 801.78 },
    h2_title: { page: 0, normalized: { x: 0, y: 4.680861687499994 }, wraps: false, x: 40, y: 797.21 },
    p149: { page: 0, normalized: { x: 2.321081081081081, y: 40.640482416666664 }, wraps: false, x: 42.32, y: 761.25 },
    p150: { page: 0, normalized: { x: 0, y: 57.19571720833333 }, wraps: false, x: 40, y: 744.69 },
    p151: { page: 0, normalized: { x: 2.321081081081081, y: 69.89388387499999 }, wraps: false, x: 42.32, y: 732 },
    p152: { page: 0, normalized: { x: 2.321081081081081, y: 82.08412387499999 }, wraps: false, x: 42.32, y: 719.81 },
    p153: { page: 0, normalized: { x: 2.321081081081081, y: 94.27436387499999 }, wraps: false, x: 42.32, y: 707.62 },
    p154: { page: 0, normalized: { x: 2.321081081081081, y: 106.464603875 }, wraps: false, x: 42.32, y: 695.43 },
    p155: { page: 0, normalized: { x: 2.321081081081081, y: 118.654843875 }, wraps: false, x: 42.32, y: 683.24 },
    p156: { page: 0, normalized: { x: 0, y: 135.21007866666665 }, wraps: false, x: 40, y: 666.68 },
    p157: { page: 0, normalized: { x: 0, y: 152.273240125 }, wraps: false, x: 40, y: 649.62 },
    p158: { page: 0, normalized: { x: 2.321081081081081, y: 164.97140679166665 }, wraps: false, x: 42.32, y: 636.92 },
    p159: { page: 0, normalized: { x: 2.321081081081081, y: 177.16164679166664 }, wraps: false, x: 42.32, y: 624.73 },
    p160: { page: 0, normalized: { x: 0, y: 193.7168815833333 }, wraps: false, x: 40, y: 608.17 },
    label_signature: {
      page: 0,
      normalized: { x: 483.24908108108104, y: 210.78004304166666 },
      wraps: false,
      x: 523.25,
      y: 591.11,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: { type: "RGB", red: 1, green: 0, blue: 0 },
    pixelBreakPage: 1500,
  },
};

export default component;
