import React from "react";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import Datetime from "react-datetime";

export const label = "04";

export function Cast_04(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">業 務 委 託 契 約 書</h2>
      </Form.Row>

      <Form.Row>
        <Col md={3}>
          <Form.Group>
            <Form.Control id="subjectA" required defaultValue={props["subjectA#subjectA"]} type="text" name="subjectA" />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Label id="subtitle1">（以下「甲」という。） と、</Form.Label>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Control id="subjectB" required defaultValue={props["subjectB#subjectB"]} type="text" name="subjectB" />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Label id="subtitle2">（以下「乙」 という。）</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="subtitle3">
            とは、甲の事業である接待飲食等営業の業務の一部を乙に委託するものとし、こ
            こに業務委託契約（以下「本契約」という。）を締結する。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_1">第 1 条（契約業務の内容）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="paragraph1">
            甲、乙共に法令を厳守し、甲は乙に対し本契約における業務内容を詳細に説明し乙は これを遵守すること。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_2">第 2 条（契約期間）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph2">１．本契約の有効期間は、契約締結の日から１ヶ月とする。</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph2b">
                ２．本契約には更新の定めはなく、契約期間満了後に改めて契約する場合は甲乙協議
                の上、再度契約を締結する。なお契約は書面契約、口頭契約を問わない。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_3">第 3 条（業務委託の遂行方法）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph31">
                １．委託業務の遂行にあたって、稼動日及び稼働時間は乙の裁量に属するものとし許 諾の自由を有するものとする。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph32">２．したがって勤務場所・時間についての指定・管理は行わないものとする。</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph33">３．業務の内容及び遂行方法に対する指揮監督は受けないものとする。</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph34">
                ４．但し、業務遂行の必要性などから、事前の十分な打合せ等、関係者間で協議しつ つ協力関係を保持することとする。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_4">第 4 条（委託手数料）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph41">
                １．甲が乙に対し支払う本契約の委託手数料は、甲の業務委託手数料表に基づき、法 令で定められた源泉所得税及び第 7
                条に定める経費等の合計額を差し引いた金額 を、甲が乙に対し現金による支払いを原則とする。
                なお、甲が乙に支払う業務委託手数料には消費税が含まれている。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph42">２．乙の公租については、乙自らの責任において対処すること。</Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_5"> 第 5 条（委託手数料率の改訂）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph5">
                第 4 条に規定した委託手数料率は、物価・経済状況の変化、その他料率の変更を必要
                とする事由が生じた場合は、甲乙協議の上、委託手数料率を改訂することができる。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_6"> 第 6 条（本契約における乙の地位）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph61">
                １．乙は、甲が委託する業務を請け負うものであり、甲との雇用関係にはなく、当然 に社会保険・労働保険の加入はない。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph62">
                ２．したがって、業務上の災害など万一の場合においても、雇用関係としての補償を
                甲に対して求めることはできないものとする。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph63">
                ３．乙は自己の責任において、適切な確定申告を行い納税の義務を果たすものとする。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph64">４．乙は独立した個人事業主であるため甲は乙の兼業を禁止することはできない。</Form.Label>{" "}
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_7">第７条（業務委託に付随する経費等）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph71">
                １．乙が委託業務を実施するために必要な着類、用具、ヘアメイク等に対しての経費、及び維持費は乙の負担とし、
                乙は自らの責任において着類等を調達し、ヘアメイ クを行う。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph72">
                ２．なお、乙が業務を遂行する際に必要となる用具、ヘアメイク等は甲から有料にて
                支給若しくは貸出を受けることもできる。 Ver.キャバクラ 170407
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_8">第 8 条（損害賠償）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph81">
                １．本契約業務の遂行中、乙の故意または過失により、甲もしくは第三者に与えた損 害に対し、乙は損害賠償の責任を負う。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph82">
                ２．甲の事業である接待飲食等営業の利用者がその業務に対して支払うべきサービス
                の利用料に関して未収が生じた場合には、乙が利用者から収受し、甲に受け渡す ものとする。
                なお、乙が利用者から収受した利用料を甲に受け渡す前に紛失した
                場合又は乙が利用者から収受できなかった場合は、未収の額と同等の額を甲に支 払わなければならない。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_9">第 9 条（禁止事項）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph91">
                １．乙は、委託業務の遂行時間以外で待機の時間が生じた場合であっても、待機時間
                を甲の事務所において過ごすことはできない。
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph92">
                ２．ただし待機場所の使用料を支払うことで甲の事務所にて待機することができる。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_10">第 10 条（守秘義務）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph10">
                乙は、本契約に基づき業務上知り得た情報、または甲の機密書類及びデータ等を外部
                に持ち出し、第三者に対し提供または漏洩してはならない。 万一、違反した場合は甲に 対し損害賠償の責を負う
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_11">第 11 条（所有権及び肖像権）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph11">業務遂行にあたり必要となる写真、情報については甲に所有権があることとする。</Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_12">第 12 条（協議）</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col>
              <Form.Label id="paragraph12">
                本契約書及び個別契約書に定めのない事項、または本契約及び個別契約の履行にあた
                り疑義を生じた事項は、甲乙協議の上円満に解決をはかるものとする。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="endline">
            本契約を締結したことを証するため本書を 1 通作成し、甲乙記名捺印の上、甲が原本を 乙が写しを保有する。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        {/* <Form.Label id="label_date" htmlFor="date" style={{ marginRight: "1em" }}>
          平成
        </Form.Label> */}
        <Datetime
          initialValue={props["date#date"]}
          inputProps={{ name: "date", id: "date", readOnly: "readonly" }}
          timeFormat={false}
        />
      </Form.Row>
      <Signature {...props} />

      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
      */}
    </React.Fragment>
  );
}

const component = {};
component[label] = Cast_04;

component[label + "PageName"] = "業 務 委 託 契 約 書";
component[label + "Template"] = {
  data: {
    interviewed: {
      page: 0,
      normalized: {
        x: 0,
        y: 0.10380421712807564,
      },
      wraps: false,
      x: 40,
      y: 801.79,
    },
    h2_title: {
      page: 0,
      normalized: {
        x: 0,
        y: 4.849139857266484,
      },
      wraps: false,
      x: 40,
      y: 797.04,
    },
    subjectA: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 46.502641587370285,
      },
      wraps: false,
      x: 42.32,
      y: 755.39,
      width: 124.17783783783784,
      height: 22.565059580449827,
    },
    subtitle1: {
      page: 0,
      normalized: {
        x: 131.14108108108107,
        y: 41.97150512543258,
      },
      wraps: false,
      x: 171.14,
      y: 759.92,
    },
    subjectB: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 46.502641587370285,
      },
      wraps: false,
      x: 299.96,
      y: 755.39,
      width: 124.17783783783784,
      height: 22.565059580449827,
    },
    subtitle2: {
      page: 0,
      normalized: {
        x: 388.781081081081,
        y: 41.97150512543258,
      },
      wraps: false,
      x: 428.78,
      y: 759.92,
    },
    subtitle3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 72.97271695501735,
      },
      wraps: false,
      x: 42.32,
      y: 728.92,
    },
    h4_1: {
      page: 0,
      normalized: {
        x: 0,
        y: 90.15808179065749,
      },
      wraps: false,
      x: 40,
      y: 711.73,
    },
    paragraph1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 130.44400415224916,
      },
      wraps: false,
      x: 42.32,
      y: 671.45,
    },
    h4_2: {
      page: 0,
      normalized: {
        x: 0,
        y: 147.6293689878893,
      },
      wraps: false,
      x: 40,
      y: 654.26,
    },
    paragraph2: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 187.91529134948104,
      },
      wraps: false,
      x: 44.64,
      y: 613.97,
    },
    paragraph2b: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 200.56951972318345,
      },
      wraps: true,
      x: 44.64,
      y: 601.32,
      lines: 2,
    },
    h4_3: {
      page: 0,
      normalized: {
        x: 0,
        y: 230.40911293252597,
      },
      wraps: false,
      x: 40,
      y: 571.48,
    },
    paragraph31: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 270.6950352941177,
      },
      wraps: false,
      x: 44.64,
      y: 531.19,
    },
    paragraph32: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 283.34926366782014,
      },
      wraps: false,
      x: 44.64,
      y: 518.54,
    },
    paragraph33: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 296.0034920415225,
      },
      wraps: false,
      x: 44.64,
      y: 505.89,
    },
    paragraph34: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 308.65772041522496,
      },
      wraps: false,
      x: 44.64,
      y: 493.23,
    },
    h4_4: {
      page: 0,
      normalized: {
        x: 0,
        y: 325.8430852508651,
      },
      wraps: false,
      x: 40,
      y: 476.05,
    },
    paragraph41: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 366.1290076124568,
      },
      wraps: true,
      x: 44.64,
      y: 435.76,
      lines: 2,
    },
    paragraph42: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 391.4374643598616,
      },
      wraps: false,
      x: 44.64,
      y: 410.45,
    },
    h4_5: {
      page: 0,
      normalized: {
        x: 0,
        y: 408.62282919550177,
      },
      wraps: false,
      x: 40,
      y: 393.27,
    },
    paragraph5: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 448.90875155709347,
      },
      wraps: true,
      x: 44.64,
      y: 352.98,
      lines: 2,
    },
    h4_6: {
      page: 0,
      normalized: {
        x: 0,
        y: 478.74834476643605,
      },
      wraps: false,
      x: 40,
      y: 323.14,
    },
    paragraph61: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 519.0342671280278,
      },
      wraps: false,
      x: 44.64,
      y: 282.86,
    },
    paragraph62: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 531.6884955017301,
      },
      wraps: false,
      x: 44.64,
      y: 270.2,
    },
    paragraph63: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 544.3427238754326,
      },
      wraps: false,
      x: 44.64,
      y: 257.55,
    },
    paragraph64: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 556.996952249135,
      },
      wraps: false,
      x: 44.64,
      y: 244.89,
    },
    h4_7: {
      page: 0,
      normalized: {
        x: 0,
        y: 574.1823170847751,
      },
      wraps: false,
      x: 40,
      y: 227.71,
    },
    paragraph71: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 614.4682394463669,
      },
      wraps: true,
      x: 44.64,
      y: 187.42,
      lines: 2,
    },
    paragraph72: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 639.7766961937716,
      },
      wraps: false,
      x: 44.64,
      y: 162.11,
    },
    h4_8: {
      page: 0,
      normalized: {
        x: 0,
        y: 656.9620610294118,
      },
      wraps: false,
      x: 40,
      y: 144.93,
    },
    paragraph81: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 697.2479833910035,
      },
      wraps: false,
      x: 44.64,
      y: 104.64,
    },
    paragraph82: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 709.902211764706,
      },
      wraps: true,
      x: 44.64,
      y: 91.99,
      lines: 3,
    },
    h4_9: {
      page: 0,
      normalized: {
        x: 0,
        y: 752.3960333477509,
      },
      wraps: false,
      x: 40,
      y: 49.49,
    },
    paragraph91: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 30.791955709342606,
      },
      wraps: false,
      x: 44.64,
      y: 771.1,
    },
    paragraph92: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 43.44618408304503,
      },
      wraps: false,
      x: 44.64,
      y: 758.44,
    },
    h4_10: {
      page: 1,
      normalized: {
        x: 0,
        y: 60.63154891868517,
      },
      wraps: false,
      x: 40,
      y: 741.26,
    },
    paragraph10: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 100.91747128027687,
      },
      wraps: true,
      x: 44.64,
      y: 700.97,
      lines: 2,
    },
    h4_11: {
      page: 1,
      normalized: {
        x: 0,
        y: 130.75706448961944,
      },
      wraps: false,
      x: 40,
      y: 671.13,
    },
    paragraph11: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 171.0429868512111,
      },
      wraps: false,
      x: 44.64,
      y: 630.85,
    },
    h4_12: {
      page: 1,
      normalized: {
        x: 0,
        y: 188.22835168685125,
      },
      wraps: false,
      x: 40,
      y: 613.66,
    },
    paragraph12: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 228.51427404844296,
      },
      wraps: true,
      x: 44.64,
      y: 573.38,
      lines: 2,
    },
    endline: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 253.82273079584778,
      },
      wraps: false,
      x: 42.32,
      y: 548.07,
    },
    date: {
      page: 1,
      normalized: {
        x: 0,
        y: 271.00809563148795,
      },
      wraps: false,
      x: 40,
      y: 530.88,
      width: 111.4118918918919,
      height: 22.565059580449827,
    },
    "": {
      page: 0,
      normalized: {
        x: -206.80832432432433,
        y: 750.5011944636678,
      },
      wraps: false,
      x: -166.81,
      y: 51.39,
    },
    label_signature: {
      page: 1,
      normalized: {
        x: 483.24908108108104,
        y: 293.5731552119378,
      },
      wraps: false,
      x: 523.25,
      y: 508.32,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1445,
  },
};
export default component;
