import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Step from "./Step";
import "./Steps.scss";
function Steps(props) {
  const indexes = [...Array(props.totalSteps).keys()];

  return (
    <ul id="stepper" className="pagination row">
      {indexes.map((index) => {
        return <Step {...props} key={index.toString()} index={index} current={props.current} total={props.totalSteps} />;
      })}
    </ul>
  );
}

Steps.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  current: PropTypes.number,
};

export default Steps;
