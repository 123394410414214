import React, { useEffect, useState } from "react";
import { Form, Button, Col, FormLabel } from "react-bootstrap";
import SignaturePad from "signature_pad";
import "./Signature.scss";

export default function Signature(props) {
  const canvas = React.useRef(null);
  const [signaturePad, setSignature] = useState(null);
  const [data, setData] = useState(props.data);

  useEffect(() => {
    if (canvas.current) {
      let pad = new SignaturePad(canvas.current);
      setSignature(pad);
      resizeCanvas();

      if (props.data) {
        setData(props.data);
        pad.fromDataURL(props.data);
      }
    }
  }, [canvas, props.data]);

  function resizeCanvas() {
    if (canvas.current) {
      let container = canvas.current.parentElement;
      canvas.current.width = container.clientWidth;
      canvas.current.height = container.clientHeight;
      if (signaturePad) {
        signaturePad.clear();
      }
    }
  }

  window.addEventListener("resize", resizeCanvas);

  const clear = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
    setData(undefined);
    props.onSign(undefined);
  };
  const savedata = () => {
    if (signaturePad) {
      setData(signaturePad.toDataURL());
      props.onSign(signaturePad.toDataURL());
    }
  };

  return (
    <Form.Row>
      {props.step !== 0 && (
        // <Form.Row>
        <Col>
          <FormLabel id="label_signature" style={{ float: "right" }}>
            <b>本人自著</b>
          </FormLabel>
        </Col>
        // </Form.Row>
      )}
      <Col xs="12">
        <div className="signContainer">
          <img src={data} className={!data ? "hidden" : ""} alt="signature" />
          <canvas className={data ? "hidden" : ""} ref={canvas}></canvas>
        </div>
      </Col>
      <Col>
        <div style={{ float: "right", marginTop: "1.5em" }}>
          <Button style={{ marginRight: "1em" }} onClick={clear}>
            サインを消す
          </Button>
          {!data && <Button onClick={savedata}>サイン保存</Button>}
        </div>
      </Col>
    </Form.Row>
  );
}
