import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import Datetime from "react-datetime";

export default function WorkingStyle(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h4 id="subtite_working_style">あなたの希望する働き方について教えてください。</h4>
      </Form.Row>
      <Form.Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label id="label_prefered_num_work_days" htmlFor="prefered_num_work_days">
              <b>Q1</b> 週何日位働かれたいですか？
            </Form.Label>
            <InputGroup>
              <Form.Control
                defaultValue={
                  props["prefered_num_work_days#prefered_num_work_days"]
                    ? props["prefered_num_work_days#prefered_num_work_days"]
                    : ""
                }
                name="prefered_num_work_days"
                id="prefered_num_work_days"
                type="number"
              />
              <InputGroup.Append>
                <InputGroup.Text id="span_prefered_num_work_days">日</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label id="label_prefered_not_work_days" htmlFor="prefered_not_work_days">
              勤務出来ない曜日
            </Form.Label>
            <Form.Control
              defaultValue={
                props["prefered_not_work_days#prefered_not_work_days"]
                  ? props["prefered_not_work_days#prefered_not_work_days"]
                  : ""
              }
              name="prefered_not_work_days"
              id="prefered_not_work_days"
              type="text"
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12}>
          <Form.Label id="label_prefered_work_time">
            <b>Q2</b> 希望勤務時間は？
          </Form.Label>
        </Col>
        <Col>
          <Form.Group>
            <Datetime
              initialValue={props["prefered_work_time#prefered_work_time_from"]}
              inputProps={{ name: "prefered_work_time", id: "prefered_work_time_from", readOnly: "readonly" }}
              dateFormat={false}
            />
          </Form.Group>
        </Col>
        <Col style={{ textAlign: "center", lineHeight: "2.5em" }} xs={1}>
          <Form.Label id="prefered_work_time_divisor">～</Form.Label>
        </Col>
        <Col>
          <Form.Group>
            <Datetime
              initialValue={props["prefered_work_time#prefered_work_time_to"]}
              inputProps={{ name: "prefered_work_time", id: "prefered_work_time_to", readOnly: "readonly" }}
              dateFormat={false}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      {props.type === "cast" && (
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label id="label_expected_salary" htmlFor="expected_salary">
                <b>Q3</b>　一日幾ら位稼がれたいですか？
              </Form.Label>
              <Form.Control
                defaultValue={props["expected_salary#expected_salary"]}
                name="expected_salary"
                id="expected_salary"
                type="text"
              />
            </Form.Group>
          </Col>
        </Form.Row>
      )}
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label id="label_other_work" htmlFor="other_work">
              {props.type === "cast" ? <b>Q4</b> : <b>Q3</b>} 当店以外でお仕事に行かれてますか？
            </Form.Label>
            <Form.Control defaultValue={props["other_work#other_work"]} name="other_work" id="other_work" type="text" />
          </Form.Group>
        </Col>
      </Form.Row>
    </React.Fragment>
  );
}
