import C2 from "./Common_02";
import C3 from "./Common_03";
import BD from "./Common_BD";
import ND from "./Common_ND";

const CommonComponents = {
  ...C2,
  ...C3,
  ...BD,
  ...ND,
};

export default CommonComponents;
