import React from "react";
import Signature from "../../Signature/Signature";
import { Col, Form, Row } from "react-bootstrap";
import Datetime from "react-datetime";

export const label = "07";

export function Cast_07(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">肖像権使用同意書</h2>
      </Form.Row>
      <Form.Row>
        <Col id="date1" style={{ textAlign: "right" }} md={{ span: 4, offset: 8 }}>
          年 月 日
          <Form.Group>
            <Datetime
              initialValue={props["date#date"]}
              inputProps={{ name: "date", id: "date", readOnly: "readonly" }}
              timeFormat={false}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p1">
            肖像権使用同意書 年 月 日 私は貴社に対し、貴社が私の肖像等を 年 月 日に撮影した画像について、下記の定める使用範囲に
            おいて無期限無償で使用する事に同意します。 また、同意書に記載した内容について偽りが無い事を保証します。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h41">【使用範囲等】</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p2">
            1:本画像を使用した貴社または貴社が指名した者の作品が広告・印刷・各種メディア(SNS等)
            あらゆる媒体で使用される事を許可いたします。また媒体へ使用されなかった場合でも、 異議申立てを行いません。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p3">2:本画像の選択、光学的創作、変形等に対して異議申立てを行いません。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p4">3:本画像の使用地域を制限しません。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p6">4:本画像の使用期間を制限しません。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p7">5:本画像のデータ、それを出力した印刷物等の提供を求めません。また、本画像を使用した</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col id="date2" md={4}>
          年 月 日
          <Form.Group>
            <Datetime
              initialValue={props["date2#date2"]}
              inputProps={{ name: "date2", id: "date2", readOnly: "readonly" }}
              timeFormat={false}
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={4}>
          <Form.Label id="label_address" htmlFor="address">
            住所
          </Form.Label>
          <Form.Group>
            <Form.Control id="address" required defaultValue={props["address#address"]} type="text" name="address" />
          </Form.Group>
        </Col>
        <Col md={{ span: 4, offset: 4 }}>
          <Form.Label id="label_address2" htmlFor="address2">
            住所
          </Form.Label>
          <Form.Group>
            <Form.Control id="address2" required defaultValue={props["address2#address2"]} type="text" name="address2" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4}>
          <Form.Label id="label_name" htmlFor="name">
            源氏名
          </Form.Label>
          <Form.Group>
            <Form.Control id="name" required defaultValue={props["name#name"]} type="text" name="name" />
          </Form.Group>
        </Col>
        <Col md={{ span: 4, offset: 4 }}>
          <Form.Label id="label_name2" htmlFor="name2">
            会社名
          </Form.Label>
          <Form.Group>
            <Form.Control id="name2" required defaultValue={props["name2#name2"]} type="text" name="name2" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={4}>
          <Form.Label id="label_fullname" htmlFor="fullname">
            氏名
          </Form.Label>
          <Form.Group>
            <Form.Control id="fullname" required defaultValue={props["fullname#fullname"]} type="text" name="fullname" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Signature {...props} />
    </React.Fragment>
  );
}

const component = {};
component[label] = Cast_07;

component[label + "PageName"] = "肖像権使用同意書";
component[label + "Template"] = {
  data: {
    h2_title: {
      page: 0,
      normalized: {
        x: 0,
        y: 0.17380615624998266,
      },
      x: 40,
      y: 801.72,
    },
    date: {
      page: 0,
      normalized: {
        x: 345.8410810810811,
        y: 78.64847615624998,
      },
      x: 385.84,
      y: 723.24,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    "": {
      page: 0,
      normalized: {
        x: -127.19524324324324,
        y: 149.25425099999998,
      },
      x: -87.2,
      y: 652.64,
    },
    p1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 123.44522724999997,
      },
      x: 42.32,
      y: 678.44,
    },
    h41: {
      page: 0,
      normalized: {
        x: 0,
        y: 167.3253294375,
      },
      x: 40,
      y: 634.56,
    },
    p2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 232.0859794375,
      },
      x: 42.32,
      y: 569.8,
    },
    p3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 275.966081625,
      },
      x: 42.32,
      y: 525.92,
    },
    p4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 301.56082381249996,
      },
      x: 42.32,
      y: 500.33,
    },
    p6: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 327.155566,
      },
      x: 42.32,
      y: 474.73,
    },
    p7: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 352.75030818749997,
      },
      x: 42.32,
      y: 449.14,
    },
    date2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 396.630410375,
      },
      x: 42.32,
      y: 405.26,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    label_address: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 441.42716146874994,
      },
      x: 42.32,
      y: 360.46,
    },
    address: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 467.02190365624995,
      },
      x: 42.32,
      y: 334.87,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    label_address2: {
      page: 0,
      normalized: {
        x: 345.8265743243243,
        y: 441.42716146874994,
      },
      x: 385.83,
      y: 360.46,
    },
    address2: {
      page: 0,
      normalized: {
        x: 345.8265743243243,
        y: 467.02190365624995,
      },
      x: 385.83,
      y: 334.87,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    label_name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 511.81865475,
      },
      x: 42.32,
      y: 290.07,
    },
    name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 537.4133969375,
      },
      x: 42.32,
      y: 264.48,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    label_name2: {
      page: 0,
      normalized: {
        x: 345.8265743243243,
        y: 511.81865475,
      },
      x: 385.83,
      y: 290.07,
    },
    name2: {
      page: 0,
      normalized: {
        x: 345.8265743243243,
        y: 537.4133969375,
      },
      x: 385.83,
      y: 264.48,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    label_fullname: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 582.21014803125,
      },
      x: 42.32,
      y: 219.68,
    },
    fullname: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 607.80489021875,
      },
      x: 42.32,
      y: 194.09,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1000,
  },
};

export default component;
