import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { allowedUploadExts } from "../../core/costants";

export default function FileUploader(props) {
  const [files, setFile] = useState(null);
  const [result, setResult] = useState(null);

  const onFileChange = (event) => {
    setFile(event.target.files);
  };

  const onFileUpload = () => {
    if (files) {
      const formData = new FormData();
      formData.set("employeeId", props.userId);

      for (var i = 0; i < files.length; i++) {
        formData.set("file" + i, files[i], files[i].name);
      }

      fetch(props.uploadUrl, {
        method: "POST",
        body: formData,
      }).then((res) => res.json().then(setResult).then(window.location.reload()));
    }
  };

  const fileData = () => {
    if (files) {
      let details = [];

      let num = Math.floor(files.length / 5);

      for (var i = 0; i < files.length; i++) {
        if (!details[num]) {
          details[num] = [];
        }

        console.log(files[i]);

        details[num].push(
          <Col key={files[i].name}>
            <h2>File Details:</h2>
            <p>File Name: {files[i].name}</p>
            <p>File Type: {files[i].type}</p>
            <p>Last Modified: {files[i].lastModifiedDate.toDateString()}</p>
          </Col>
        );

        if ((files.length - 1 - i) % 5 === 0) {
          num--;
        }
      }

      let rows = [];

      for (const key in details) {
        rows.push(<Form.Row key={key}>{details[key]}</Form.Row>);
      }

      return <Form.Row>{rows}</Form.Row>;
    } else {
      return (
        <div>
          <br />
          <h4>フアイル {JSON.stringify(allowedUploadExts)} フアイルを選択してください</h4>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <input multiple type="file" onChange={onFileChange} />
        <Button onClick={onFileUpload}>アップロード!</Button>
      </div>
      <div>{JSON.stringify(result)}</div>
    </div>
  );
}
