import {
  CAST_LABEL,
  DRIVER_LABEL,
  STAFF_LABEL,
  dedicatedChar,
  castFlow,
  driverFlow,
  staffFlow,
  labelToFlow,
  labelToLocal,
} from "./costants";
import CommonComponents from "../components/Forms/Common";
import CastComponents from "../components/Forms/Cast";
import DriverComponents from "../components/Forms/Driver";
import StaffComponents from "../components/Forms/Staff";

const labelComponentMap = {};
labelComponentMap[CAST_LABEL] = CastComponents;
labelComponentMap[DRIVER_LABEL] = DriverComponents;
labelComponentMap[STAFF_LABEL] = StaffComponents;

export default function buildFlow(label, props) {
  let data = {};
  let flow = labelToFlow[label];

  if (!flow) throw new Error('Logic for label "' + label + '" not defined');

  //if the elements starts with 'dedicatedChar' go in the specific component folder, else use common component folder
  flow.forEach((val, idx) => {
    let _val = val;
    let component = {};
    let template = {};
    let pageName = "Page Name";

    if (dedicatedChar === val[0]) {
      _val = val.substring(1); //take the component label excluding the 'dedicatedChar'
      component = labelComponentMap[label][_val];
      template = labelComponentMap[label][_val + "Template"];
      pageName = labelComponentMap[label][_val + "PageName"];
    } else {
      component = CommonComponents[val];
      template = CommonComponents[val + "Template"];
      pageName = CommonComponents[val + "PageName"];
    }

    let _props = props[idx] ? props[idx] : {};

    data[idx] = {
      component: component,
      template: template,
      props: _props,
      filename: _val,
      pageName: pageName,
      type: labelToLocal[label],
    };
  });
  return data;
}
