import { useEffect, useState } from "react";
import { Container, Form, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../core/costants";
import "./LoginPage.scss";
import logoLong from "../../assets/logos/Page_Logo.jpg";
import { objToURLEncoded } from "../../core/Utils";
import fontRegular from "../../font/YuGothR.ttf";
import fontBold from "../../font/YuGothB.ttf";

export default function LoginPage(props) {
  const history = useHistory();

  // const [validated, setValidated] = useState(false);

  const submit = (event) => {
    // const form = document.getElementById("loginForm");

    const username = document.getElementById("username");
    const psw = document.getElementById("psw");

    let body = {
      username: username.value,
      password: psw.value,
    };

    fetch(BACKEND_URL + "/login", {
      method: "POST",
      body: objToURLEncoded(body),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.ok && res.status === 204) {
          history.replace("/");
        } else {
          switch (res.status) {
            case 401:
              username.classList.add("wrong-credential");
              psw.classList.add("wrong-credential");
              break;
            default:
              username.classList.add("wrong-credential");
              psw.classList.add("wrong-credential");
          }
        }
      })
      .catch(console.error);

    // setValidated(true);
  };

  useEffect(() => {
    fetch(BACKEND_URL + "/status").then((res) => {
      if (res.status === 204) {
        history.replace("/");
      }
    });

    async function ceckFonts() {
      // await caches.match(fontRegular);
      // let boldStatus = await caches.match(fontBold);

      // if (!regularStatus) caches.add(fontRegular);
      // if (!boldStatus) caches.add(fontBold);

      caches.open("fonts").then((cache) => {
        cache.match(fontRegular).then((res) => {
          if (!res) cache.add(fontRegular);
        });
        cache.match(fontBold).then((res) => {
          if (!res) cache.add(fontBold);
        });
      });

      // caches.keys().then((cachesNames) => {
      //   if (!cachesNames.includes("fonts")) {
      //     caches.open("fonts").then((cache) => {
      //       cache
      //       cache.add(fontBold);
      //     });
      //   }
      // });
    }

    ceckFonts();
  }, []);

  return (
    <Container fluid className="fullsize">
      <div className="container">
        <img src={logoLong} className="App-logo" alt="logo" />
      </div>
      <div className="loginForm">
        <Form /*validated={validated} */ id="loginForm">
          <Form.Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>ユーザー名</Form.Label>
                <Form.Control id="username" required type="text" name="username" />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>パスワード</Form.Label>
                <Form.Control id="psw" required type="password" name="psw" />
              </Form.Group>
            </Col>
          </Form.Row>
          {/* <Form.Row className="justify-content-around">
            <Form.Check required inline type="radio" value="cast" label="Cast" name="workerType" id="formHorizontalRadios1" />
            <Form.Check required inline type="radio" value="driver" label="Driver" name="workerType" id="formHorizontalRadios2" />
            <Form.Check required inline type="radio" value="staff" label="Staff" name="workerType" id="formHorizontalRadios3" />
          </Form.Row>
          <div style={{ height: "1.5rem" }}></div> */}
          <Form.Row className="justify-content-end">
            <Button onClick={submit} className="col">
              ログイン
            </Button>
          </Form.Row>
        </Form>
      </div>
    </Container>
  );
}
