import React from "react";

import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import Datetime from "react-datetime";

export const label = "05";

export function Staff_05(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title0">誓約書 （スタッフ就業規則）</h2>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_0">
            当 店で 就 業 す る に 際 し、 以 下 の 事 柄 を 遵 守 す る 事 を 約 束 致 し ま す。{" "}
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_1">●18 歳 以 上 で あ り、 高 校 生 で は あ り ま せ ん。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_2">
            ● 身 分 証 明 書 （本 籍 地 入 り の 住 民 票 か パ ス ポー ト の ど ち ら か 1 点） を 提 出 し ま す。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_3">
            ● 給 与 シ ス テ ム を 説 明 さ れ、 そ れ に 納 得 し た 上 で 業 務 を 行 い ま す。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_4">
            ● 正 社 員 の ク ルー は 遅 刻 を 1 ヶ 月 間 の 中 で 3 回 す る と そ の 時 点 か ら 職 級 を 1 つ 降 格 と し、 こ
            れ 以 上 降 格 で き な い 職 級 の も の は ア ル バ イ ト に な る 事 に 納 得 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_5">
            ● 正 社 員 の ク ルー は、 職 級 が 店 長 に 昇 格 す る 為 に は、 店 舗 の 管 理 責 任 者 の 資 格 を 取 得 し な け
            れ ば い け な い 事 に 納 得 し ま し た。 そ の 後、 店 長 降 格・ 店 舗 移 動・ 退 職 な ど の 理 由 で 資 格 を 返
            還 希 望 の 場 合 は 次 の 該 当 者 が 取 得 す る ま で 資 格 を 残 し て お か な け れ ば な ら な い も の と す
            る。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_6">
            ● ルー ル ブッ ク （ク ルー） の 内 容 を 全 て 確 認 し、 そ の ルー ル の 元 で 業 務 を 行 い ま す。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_7">● 与 え ら れ い な い 権 限 の 業 務 は 行 い ま せ ん。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_8">● 指 摘、 注 意 を 受 け た 際 に は 真 摯 に 受 け 止 め ま す。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_9">
            ● そ の 日 の 売 上 が 合 わ な い 場 合、 そ の 日 の キ ャ ッ シ ャーー 業 務 を 行っ て い た 者 が 全 額 う め る
            事 に 納 得 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_10">
            ● 店 長 の み 店 舗 の 鍵 を 持 つ 事 が 出 来 る も の と し、 受 け 渡 し し な い と い け な い 状 況 の 場 合
            は、 原 則 オー ナー に 報 告 す る 事 に 納 得 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_11">
            ● 鍵 や セ キュ リ ティ タ グ を 紛 失 し た 場 合 は、 速 や か に 会 社 に 報 告 し 当 事 者 が 鍵 交 換 代 を 支 払
            う 事 に 納 得 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_12">
            ● お 客 様 か ら 売 上 以 外 の お 金 を 受 け 取 り ま せ ん。 店 内 で は 売 上 に す る 事 と し ま す。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_13">
            ● お 客 様 の 未 収 に 関 し て、 1 ヶ 月 以 上 回 収 出 来 な い 場 合 は 給 与 か ら 天 引 き さ れ る 事 を 納 得
            し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_14">
            ● 送 迎 の 中 で の、 盗 難 や 事 故 に 関 し て 全 て 自 己 責 任 で あ る 事 を 納 得 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_15">
            ● 店 舗 で の、 盗 難 や 事 故 に 関 し て 全 て 自 己 責 任 で あ る 事 を 納 得 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      {/*
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_16">
            ● 毎 月 の 給 与 か ら、 3 万 円 （時 給 ス タッ フ は 除 く） を 積 立 金 と し て 徴 収 さ れ る こ と に 納 得 し
            ま し た。 積 立 金 は あ ら ゆ る 不 正 が な い 場 合 に は 退 職 す る 際 に 返 金 さ れ る も の と す る。
          </Form.Label>
        </Col>
      </Form.Row>
      */}

      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_16">
            ● 退 職 後 1 年 間 は 貴 社 の 了 承 を 得 ず、競 合 す る 事 業 を 起 こ す こ と は 致 し ま せ ん。 万 一 違 反 し
            、貴 社 に 経 済 的 損 害 を 与 え た 場 合 に は イ ン セ ン テ ィ ブ の 返 還 お よ び、損 害 賠 償 の 求 め に 応
            じ ま す。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_17">
            ● 店 舗 内 ス タッ フ で の 交 際、 そ う で あ ろ う と い う 言 動、 状 況 が あっ た 場 合 に は 正 社 員 は 職 級
            が 最 低 職 級 に 降 格 す る も の と し、 最 低 職 級 の 者 は ア ル バ イ ト ス タッ フ に 降 格 す る も の と す
            る。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_172">
            ア ル バ イ ト ス タッ フ は 損 害 賠 償 を 支 払 う 事 を 約 束 し、 当 店 の 顧 問 弁 護 士 に 状 況 を 説 明 し 状
            況 を 真 摯 に 受 け 止 め る 事 を 約 束 し ま す。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_173">
            尚、 発 覚 し た 時 点 ま で の 積 立 金 を 店 舗 に 支 払 う 事 を 約 束 し ま す。
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_18">
            ● 当 店 に 在 職 後 に、 他 の 同 業 者 の お 店 と 掛 け 持 ち の 状 態 で の 勤 務 を し な い 事 に 納 得 し ま し
            た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_19">
            ● 退 職 は、 退 職 日 か ら 正 社 員 78 日、 ア ル バ イ ト 26 日 出 勤 前 （暦 日 数 で は な い） 申 告 と し、 無
            断 で 退 職 し な い。 こ の ルー ル を 守 ら ず に 退 職 に 至っ た 場 合 は 最 低 賃 金 で 給 与 が 支 払 わ れ る
            事 を 納 得 致 し ま し た。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_20">
            ● 退 職 決 定 後 や 退 職 後 に 当 社 の 批 評、 批 判 を し ま せ ん。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_21">
            ● 薬 物 や 如 何 な る 違 法 行 為 を 私 は 行 い ま せ ん。 ど の よ う な 時 も 法 律 遵 守 で あ る 事 を こ こ で
            約 束 致 し ま す。
          </Form.Label>
        </Col>
      </Form.Row>
      {/* <Form.Row>
        <Col>
          <Form.Label id="label_bulletpoint_22"></Form.Label>
        </Col>
      </Form.Row> */}
      <Form.Row>
        <Col md={4}>
          {/* <Form.Label id="label_dateform0">令和 年 月 日 </Form.Label> */}
          <Form.Group>
            <Datetime
              initialValue={props["date#date"]}
              inputProps={{ name: "date", id: "date", readOnly: "readonly" }}
              timeFormat={false}
              locale="ja"
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Signature {...props} />
    </React.Fragment>
  );
}

const component = {};
component[label] = Staff_05;

component[label + "PageName"] = "誓約書 （スタッフ就業規則）";
component[label + "Template"] = {
  data: {
    interviewed: {
      page: 0,
      normalized: {
        x: 0,
        y: 0.1271161811440531,
      },
      wraps: false,
      x: 40,
      y: 801.76,
    },
    h2_title0: {
      page: 0,
      normalized: {
        x: 0,
        y: 5.938141604872866,
      },
      wraps: false,
      x: 40,
      y: 795.95,
    },
    label_bulletpoint_0: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 51.397309242584726,
      },
      wraps: false,
      x: 42.32,
      y: 750.49,
    },
    label_bulletpoint_1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 66.8933770391949,
      },
      wraps: false,
      x: 42.32,
      y: 735,
    },
    label_bulletpoint_2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 82.38944483580507,
      },
      wraps: false,
      x: 42.32,
      y: 719.5,
    },
    label_bulletpoint_3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 97.88551263241524,
      },
      wraps: false,
      x: 42.32,
      y: 704,
    },
    label_bulletpoint_4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 113.3815804290254,
      },
      wraps: true,
      x: 42.32,
      y: 688.51,
      lines: 2,
    },
    label_bulletpoint_5: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 144.37371602224576,
      },
      wraps: true,
      x: 42.32,
      y: 657.52,
      lines: 3,
    },
    label_bulletpoint_6: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 190.86191941207625,
      },
      wraps: false,
      x: 42.32,
      y: 611.03,
    },
    label_bulletpoint_7: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 206.35798720868644,
      },
      wraps: false,
      x: 42.32,
      y: 595.53,
    },
    label_bulletpoint_8: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 221.8540550052966,
      },
      wraps: false,
      x: 42.32,
      y: 580.04,
    },
    label_bulletpoint_9: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 237.35012280190674,
      },
      wraps: false,
      x: 42.32,
      y: 564.54,
    },
    label_bulletpoint_10: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 252.84619059851693,
      },
      wraps: true,
      x: 42.32,
      y: 549.04,
      lines: 2,
    },
    label_bulletpoint_11: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 283.8383261917373,
      },
      wraps: false,
      x: 42.32,
      y: 518.05,
    },
    label_bulletpoint_12: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 299.3343939883475,
      },
      wraps: false,
      x: 42.32,
      y: 502.56,
    },
    label_bulletpoint_13: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 314.8304617849576,
      },
      wraps: false,
      x: 42.32,
      y: 487.06,
    },
    label_bulletpoint_14: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 330.3265295815678,
      },
      wraps: false,
      x: 42.32,
      y: 471.56,
    },
    label_bulletpoint_15: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 345.82259737817793,
      },
      wraps: false,
      x: 42.32,
      y: 456.07,
    },
    label_bulletpoint_16: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 361.3186651747881,
      },
      wraps: true,
      x: 42.32,
      y: 440.57,
      lines: 2,
    },
    label_bulletpoint_17: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 392.3108007680085,
      },
      wraps: true,
      x: 42.32,
      y: 409.58,
      lines: 2,
    },
    label_bulletpoint_172: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 423.3029363612288,
      },
      wraps: true,
      x: 42.32,
      y: 378.59,
      lines: 2,
    },
    label_bulletpoint_173: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 454.2950719544491,
      },
      wraps: false,
      x: 42.32,
      y: 347.59,
    },
    label_bulletpoint_18: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 469.7911397510593,
      },
      wraps: false,
      x: 42.32,
      y: 332.1,
    },
    label_bulletpoint_19: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 485.2872075476695,
      },
      wraps: true,
      x: 42.32,
      y: 316.6,
      lines: 2,
    },
    label_bulletpoint_20: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 516.2793431408899,
      },
      wraps: false,
      x: 42.32,
      y: 285.61,
    },
    label_bulletpoint_21: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 531.7754109375,
      },
      wraps: false,
      x: 42.32,
      y: 270.11,
    },
    label_bulletpoint_22: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 547.2714787341101,
      },
      wraps: false,
      x: 42.32,
      y: 254.62,
    },
    date: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 568.3162687235169,
      },
      wraps: false,
      x: 42.32,
      y: 233.57,
      width: 167.11058445945946,
      height: 27.632636520127118,
    },
    "": {
      page: 0,
      normalized: {
        x: -206.80832432432433,
        y: 232.69928474576267,
      },
      wraps: false,
      x: -166.81,
      y: 569.19,
    },
    label_signature: {
      page: 0,
      normalized: {
        x: 483.24908108108104,
        y: 606.2796171080508,
      },
      wraps: false,
      x: 523.25,
      y: 195.61,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1180,
  },
};
export default component;
