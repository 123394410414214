import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup, FormLabel } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import ActualStatus from "../Common/ActualStatus";
import GeneralInfo from "../Common/GeneralInfo";
import WorkingStyle from "../Common/WorkingStyle";
// import { maxlength } from "bootstrap-maxlength";

export const label = "01";

export function Cast_01(props) {
  const [hasChildren, setHasChildren] = useState(true);

  const onQ2Change = (e) => {
    setHasChildren(e.currentTarget.value === "yes" ? true : false);
  };

  useEffect(() => {
    if (props) {
      setHasChildren(props["Q2#q2yes"]);
    }
  }, [props]);

  return (
    <React.Fragment>
      {/*PAGE DELETED*/}
      <Form.Row style={{ marginBottom: "1em" }}>
        <Col md={8}>
          <h2 id="h2_title" style={{ border: "none" }}>
            面接シート（キャスト）
          </h2>
          <Form.Row className="subtitle">
            <h6 id="h6_subtitle0">出会えた事に感謝しています！せっかくの貴重なお時間ですので何でも聞いてください。</h6>
            <h6 id="h6_subtitle1">
              {"当店とのご縁がなければ、他店を紹介させて頂きますので、\nお互い納得信頼のできる時間を過ごしましょう。"}
            </h6>
          </Form.Row>
        </Col>
        <Col md={4}>
          <ActualStatus {...props} />
          <Form.Row>
            <Col xs={12}>
              <Form.Label id="label_interviewer" htmlFor="interviewer">
                面接担当
              </Form.Label>
              <Form.Group>
                <Form.Control
                  id="interviewer"
                  required
                  defaultValue={props["interviewer#interviewer"]}
                  type="text"
                  name="interviewer"
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>

      <GeneralInfo {...props} type="cast" />
      <Form.Row>
        <Col>
          <Form.Label id="label_identity">身分証明書</Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                // props["identity#resident"] ? props["identity#resident"] : false
                defaultChecked={props["identity#resident"]}
                required
                inline
                type="radio"
                value="resident"
                name="identity"
                id="resident"
              />
              <Form.Label id="label_resident" htmlFor="resident">
                本籍地入りの住民票
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["identity#passport"]}
                required
                inline
                type="radio"
                value="passport"
                name="identity"
                id="passport"
              />
              <Form.Label id="label_passport" htmlFor="passport">
                パスポート
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_pickup_request">送迎希望 </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["pickup_request#pickup_request_yes"]}
                required
                inline
                type="radio"
                value="yes"
                name="pickup_request"
                id="pickup_request_yes"
              />
              <Form.Label id="label_pickup_request_yes" htmlFor="pickup_request_yes">
                有
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["pickup_request#pickup_request_no"]}
                required
                inline
                type="radio"
                value="no"
                name="pickup_request"
                id="pickup_request_no"
              />
              <Form.Label id="label_pickup_request_no" htmlFor="pickup_request_no">
                無
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>

      <WorkingStyle {...props} type="cast"></WorkingStyle>
      <Form.Row>
        <h4 id="h4_subtitle1">あなた自身について教えてください。</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_Q1">
            <b>Q1</b> ご結婚はされていますか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q1#unmarried"]} id="unmarried" name="Q1" inline type="radio" value="unmarried" />
              <Form.Label id="label_unmarried" htmlFor="unmarried">
                1. 未婚
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q1#married"]} id="married" name="Q1" inline type="radio" value="married" />
              <Form.Label id="label_married" htmlFor="married">
                2. 既婚
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q1#divorced"]} id="divorced" name="Q1" inline type="radio" value="divorced" />
              <Form.Label id="label_divorced" htmlFor="divorced">
                3. 離婚
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q1#remarried"]} id="remarried" name="Q1" inline type="radio" value="remarried" />
              <Form.Label id="label_remarried" htmlFor="remarried">
                4. 再婚
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q1#separated"]} id="separated" name="Q1" inline type="radio" value="separated" />
              <Form.Label id="label_separated" htmlFor="separated">
                5. 別居
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_Q2">
            <b>Q2</b> お子様はいらっしゃいますか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col xs={6} md={4}>
              <Form.Check
                defaultChecked={props["Q2#q2yes"]}
                onChange={onQ2Change}
                id="q2yes"
                name="Q2"
                inline
                type="radio"
                value="yes"
              />
              <Form.Label id="label_q2yes" htmlFor="q2yes">
                いる
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check
                defaultChecked={props["Q2#q2no"]}
                onChange={onQ2Change}
                id="q2no"
                name="Q2"
                inline
                type="radio"
                value="no"
              />
              <Form.Label id="label_q2no" htmlFor="q2no">
                いない
              </Form.Label>
            </Col>
            {hasChildren && (
              <Form.Row>
                <Col>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control defaultValue={props["childage#child1"]} name="childage" id="child1" type="number" />
                      <InputGroup.Append>
                        <InputGroup.Text id="span_child1">才</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control defaultValue={props["childage#child2"]} name="childage" id="child2" type="number" />
                      <InputGroup.Append>
                        <InputGroup.Text id="span_child2">才</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control defaultValue={props["childage#child3"]} name="childage" id="child3" type="number" />
                      <InputGroup.Append>
                        <InputGroup.Text id="span_child3">才</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_Q3">
            <b>Q3</b> あなたのパートナー（彼氏、夫）は、あなたの仕事についてご存じですか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q3#official"]} id="official" name="Q3" inline type="radio" value="official " />
              <Form.Label id="label_official" htmlFor="official">
                1. 公認
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q3#secret"]} id="secret" name="Q3" inline type="radio" value="secret" />
              <Form.Label id="label_secret" htmlFor="secret">
                2. 内緒
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q3#single"]} id="single" inline name="Q3" type="radio" value="single" />
              <Form.Label id="label_single" htmlFor="single">
                3. シングル
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_Q4">
            <b>Q4</b> お住まいの状況について教えてください。
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q4#alone"]} id="alone" name="Q4" inline type="radio" value="alone" />
              <Form.Label id="label_alone" htmlFor="alone">
                1. 一人暮らし
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q4#together "]} id="together" name="Q4" inline type="radio" value="together" />
              <Form.Label id="label_together" htmlFor="together">
                2. 同棲
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q4#husband"]} id="husband" name="Q4" inline type="radio" value="husband" />
              <Form.Label id="label_husband" htmlFor="husband">
                3. 夫や子供と同居
              </Form.Label>
            </Col>
            <Col xs={6} md={4}>
              <Form.Check defaultChecked={props["Q4#parents"]} id="parents" name="Q4" inline type="radio" value="parents" />
              <Form.Label id="label_parents" htmlFor="parents">
                4. 両親と同居
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <FormLabel id="signTag" style={{ float: "right" }}>
            <b>面接担当者サイン</b>
          </FormLabel>
        </Col>
      </Form.Row>
      <Signature {...props} />
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <Cast_01 {...props}></Cast_01>;
component[label + "PageName"] = "面接シート（キャスト）";

component[label + "Template"] = {
  data: {
    h2_title: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 0.07761506363925362,
      },
      wraps: false,
      x: 82.32,
      y: 805.81,
    },
    h6_subtitle0: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 39.78102486263014,
      },
      wraps: false,
      x: 42.32,
      y: 762.11,
    },
    h6_subtitle1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 53.539779858968025,
      },
      wraps: false,
      x: 42.32,
      y: 748.35,
    },
    label_actual_status: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: -4.330657659342517,
      },
      wraps: false,
      x: 388.16,
      y: 797.56,
    },
    actual_status: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: 12.083148820963473,
      },
      wraps: false,
      x: 388.16,
      y: 789.81,
      width: 162.46907396801097,
      height: 21.738394844665528,
    },
    label_interviewer: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: 37.53720673030592,
      },
      wraps: false,
      x: 388.16,
      y: 764.35,
    },
    interviewer: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: 53.951044211995374,
      },
      wraps: false,
      x: 388.16,
      y: 747.94,
      width: 162.46907396801097,
      height: 21.738394844665528,
    },
    label_name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 88.83491693497714,
      },
      wraps: false,
      x: 42.32,
      y: 713.06,
    },
    name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 42.32,
      y: 696.64,
      width: 167.11123613017313,
      height: 21.738394844665528,
    },
    label_age: {
      page: 0,
      normalized: {
        x: 174.07447937341638,
        y: 88.83491693497714,
      },
      wraps: false,
      x: 214.07,
      y: 713.06,
    },
    age: {
      page: 0,
      normalized: {
        x: 174.07447937341638,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 214.07,
      y: 696.64,
      width: 62.214202613624366,
      height: 21.738394844665528,
    },
    span_age: {
      page: 0,
      normalized: {
        x: 235.82710787056587,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 275.83,
      y: 696.64,
    },
    label_fake_surname: {
      page: 0,
      normalized: {
        x: 259.9544793734164,
        y: 88.83491693497714,
      },
      wraps: false,
      x: 299.95,
      y: 713.06,
    },
    span_fake_surname: {
      page: 0,
      normalized: {
        x: 262.27556045449745,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 302.28,
      y: 696.64,
    },
    fake_surname: {
      page: 0,
      normalized: {
        x: 281.2991885953336,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 321.3,
      y: 696.64,
      width: 102.83312153254329,
      height: 21.738394844665528,
    },
    span_fake_name: {
      page: 0,
      normalized: {
        x: 388.7744510399071,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 428.77,
      y: 696.64,
    },
    fake_name: {
      page: 0,
      normalized: {
        x: 407.79807918074323,
        y: 105.2487544166666,
      },
      wraps: false,
      x: 447.8,
      y: 696.64,
      width: 102.83312153254329,
      height: 21.738394844665528,
    },
    label_address: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 130.70278132462556,
      },
      wraps: false,
      x: 42.32,
      y: 671.19,
    },
    address: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 147.11661880631505,
      },
      wraps: false,
      x: 42.32,
      y: 654.77,
      width: 510.6378378378378,
      height: 21.738394844665528,
    },
    label_travel_time: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 172.57067671565747,
      },
      wraps: false,
      x: 42.32,
      y: 629.32,
    },
    travel_time: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 188.98448319596346,
      },
      wraps: false,
      x: 42.32,
      y: 612.91,
      width: 233.97419553024702,
      height: 21.738394844665528,
    },
    span_travel_time: {
      page: 0,
      normalized: {
        x: 235.83368124472128,
        y: 188.98448319596346,
      },
      wraps: false,
      x: 275.83,
      y: 612.91,
    },
    label_cell_number: {
      page: 0,
      normalized: {
        x: 259.9610527475717,
        y: 172.57067671565747,
      },
      wraps: false,
      x: 299.96,
      y: 629.32,
    },
    cell_number: {
      page: 0,
      normalized: {
        x: 259.9610527475717,
        y: 188.98448319596346,
      },
      wraps: false,
      x: 299.96,
      y: 612.91,
      width: 252.9978378378378,
      height: 21.738394844665528,
    },
    label_identity: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 214.4385411053059,
      },
      wraps: false,
      x: 42.32,
      y: 587.45,
    },
    resident: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 238.97629112361645,
      },
      wraps: false,
      x: 44.64,
      y: 562.91,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_resident: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 234.93597382096345,
      },
      wraps: false,
      x: 55.32,
      y: 566.95,
    },
    passport: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 238.97629112361645,
      },
      wraps: false,
      x: 214.85,
      y: 562.91,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_passport: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 234.93597382096345,
      },
      wraps: false,
      x: 225.53,
      y: 566.95,
    },
    label_pickup_request: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 255.06545111629225,
      },
      wraps: false,
      x: 42.32,
      y: 546.82,
    },
    pickup_request_yes: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 279.6032321359863,
      },
      wraps: false,
      x: 44.64,
      y: 522.29,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_pickup_request_yes: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 275.5628838319498,
      },
      wraps: false,
      x: 55.32,
      y: 526.33,
    },
    pickup_request_no: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 279.6032321359863,
      },
      wraps: false,
      x: 214.85,
      y: 522.29,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_pickup_request_no: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 275.5628838319498,
      },
      wraps: false,
      x: 225.53,
      y: 526.33,
    },
    subtite_working_style: {
      page: 0,
      normalized: {
        x: 0,
        y: 300.1006648516438,
      },
      wraps: false,
      x: 40,
      y: 501.79,
    },
    label_prefered_num_work_days: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 338.94550683630365,
      },
      wraps: false,
      x: 42.32,
      y: 462.94,
    },
    prefered_num_work_days: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 355.35932881730133,
      },
      wraps: false,
      x: 42.32,
      y: 446.53,
      width: 233.97419553024702,
      height: 21.738394844665528,
    },
    span_prefered_num_work_days: {
      page: 0,
      normalized: {
        x: 235.83368124472128,
        y: 355.35932881730133,
      },
      wraps: false,
      x: 275.83,
      y: 446.53,
    },
    label_prefered_not_work_days: {
      page: 0,
      normalized: {
        x: 259.9610527475717,
        y: 338.94550683630365,
      },
      wraps: false,
      x: 299.96,
      y: 462.94,
    },
    prefered_not_work_days: {
      page: 0,
      normalized: {
        x: 259.9610527475717,
        y: 355.35932881730133,
      },
      wraps: false,
      x: 299.96,
      y: 446.53,
      width: 252.9978378378378,
      height: 21.738394844665528,
    },
    label_prefered_work_time: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 380.8133712259521,
      },
      wraps: false,
      x: 42.32,
      y: 421.08,
    },
    prefered_work_time_from: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 397.2272087076415,
      },
      wraps: false,
      x: 42.32,
      y: 404.66,
      width: 231.53442537874787,
      height: 21.738394844665528,
    },
    prefered_work_time_divisor: {
      page: 0,
      normalized: {
        x: 253.92757361169762,
        y: 396.9746869386392,
      },
      wraps: false,
      x: 293.93,
      y: 404.92,
    },
    prefered_work_time_to: {
      page: 0,
      normalized: {
        x: 281.4310527475718,
        y: 397.2272087076415,
      },
      wraps: false,
      x: 321.43,
      y: 404.66,
      width: 231.53442537874787,
      height: 21.738394844665528,
    },
    label_expected_salary: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 422.68125111629223,
      },
      wraps: false,
      x: 42.32,
      y: 379.21,
    },
    expected_salary: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 439.09507309728997,
      },
      wraps: false,
      x: 42.32,
      y: 362.79,
      width: 510.6378378378378,
      height: 21.738394844665528,
    },
    label_other_work: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 464.5491232562866,
      },
      wraps: false,
      x: 42.32,
      y: 337.34,
    },
    other_work: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 480.96294911245724,
      },
      wraps: false,
      x: 42.32,
      y: 320.93,
      width: 510.6378378378378,
      height: 21.738394844665528,
    },
    h4_subtitle1: {
      page: 0,
      normalized: {
        x: 0,
        y: 510.82528361995435,
      },
      wraps: false,
      x: 40,
      y: 291.06,
    },
    label_Q1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 549.6701294797872,
      },
      wraps: false,
      x: 42.32,
      y: 252.22,
    },
    unmarried: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 574.2078930612029,
      },
      wraps: false,
      x: 44.64,
      y: 227.68,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_q1_shoes: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 570.1675680082041,
      },
      wraps: false,
      x: 55.32,
      y: 231.72,
    },
    married: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 574.2078930612029,
      },
      wraps: false,
      x: 214.85,
      y: 227.68,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_married: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 570.1675680082041,
      },
      wraps: false,
      x: 225.53,
      y: 231.72,
    },
    divorced: {
      page: 0,
      normalized: {
        x: 345.06298624894424,
        y: 574.2078930612029,
      },
      wraps: false,
      x: 385.06,
      y: 227.68,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_divorced: {
      page: 0,
      normalized: {
        x: 355.73865588048983,
        y: 570.1675680082041,
      },
      wraps: false,
      x: 395.74,
      y: 231.72,
    },
    remarried: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 586.5813919267882,
      },
      wraps: false,
      x: 44.64,
      y: 215.31,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_remarried: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 582.5410629986164,
      },
      wraps: false,
      x: 55.32,
      y: 219.35,
    },
    separated: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 586.5813919267882,
      },
      wraps: false,
      x: 214.85,
      y: 215.31,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_separated: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 582.5410629986164,
      },
      wraps: false,
      x: 225.53,
      y: 219.35,
    },
    label_Q2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 602.6705441691181,
      },
      wraps: false,
      x: 42.32,
      y: 199.22,
    },
    q2yes: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 627.2083058129475,
      },
      wraps: false,
      x: 44.64,
      y: 174.68,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_q2yes: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 623.1679807599486,
      },
      wraps: false,
      x: 55.32,
      y: 178.72,
    },
    q2no: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 627.2083058129475,
      },
      wraps: false,
      x: 214.85,
      y: 174.68,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_q2no: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 623.1679807599486,
      },
      wraps: false,
      x: 225.53,
      y: 178.72,
    },
    label_Q3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 643.2974580552774,
      },
      wraps: false,
      x: 42.32,
      y: 158.59,
    },
    official: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 667.8352235742797,
      },
      wraps: false,
      x: 44.64,
      y: 134.05,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_official: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 663.7948985212809,
      },
      wraps: false,
      x: 55.32,
      y: 138.1,
    },
    secret: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 667.8352235742797,
      },
      wraps: false,
      x: 214.85,
      y: 134.05,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_secret: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 663.7948985212809,
      },
      wraps: false,
      x: 225.53,
      y: 138.1,
    },
    single: {
      page: 0,
      normalized: {
        x: 345.06298624894424,
        y: 667.8352235742797,
      },
      wraps: false,
      x: 385.06,
      y: 134.05,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_single: {
      page: 0,
      normalized: {
        x: 355.73865588048983,
        y: 663.7948985212809,
      },
      wraps: false,
      x: 395.74,
      y: 138.1,
    },
    label_Q4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 683.9243758166097,
      },
      wraps: false,
      x: 42.32,
      y: 117.97,
    },
    alone: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 708.4621413356118,
      },
      wraps: false,
      x: 44.64,
      y: 93.43,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_alone: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 704.4218085322672,
      },
      wraps: false,
      x: 55.32,
      y: 97.47,
    },
    together: {
      page: 0,
      normalized: {
        x: 174.8525742055532,
        y: 708.4621413356118,
      },
      wraps: false,
      x: 214.85,
      y: 93.43,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_together: {
      page: 0,
      normalized: {
        x: 185.52821550358954,
        y: 704.4218085322672,
      },
      wraps: false,
      x: 225.53,
      y: 97.47,
    },
    husband: {
      page: 0,
      normalized: {
        x: 345.06298624894424,
        y: 708.4621413356118,
      },
      wraps: false,
      x: 385.06,
      y: 93.43,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_husband: {
      page: 0,
      normalized: {
        x: 355.73865588048983,
        y: 704.4218085322672,
      },
      wraps: false,
      x: 395.74,
      y: 97.47,
    },
    parents: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 720.8356460139566,
      },
      wraps: false,
      x: 44.64,
      y: 81.05,
      width: 8.354572612865551,
      height: 9.141236498082478,
    },
    label_parents: {
      page: 0,
      normalized: {
        x: 15.317817626953124,
        y: 716.7953132106119,
      },
      wraps: false,
      x: 55.32,
      y: 85.09,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1500,
    backgroundColor: "238, 214, 231",
    titlesColor: {
      // h2: "66, 155, 245",
    },
    drawRects: [
      {
        x: "ref pickup_request_yes",
        y: "ref pickup_request_yes",
        width: "100%",
        height: "ref label_name",
        // color: "255, 255, 255",
      },
    ],
    addLogo: true,
  },
};
export default component;
