import React from "react";

import { Col, InputGroup, Form } from "react-bootstrap";

export default function GeneralInfo(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <Col md={4}>
          <Form.Label id="label_name" htmlFor="name">
            お名前
          </Form.Label>
          <Form.Group>
            <Form.Control id="name" required defaultValue={props["name#name"]} type="text" name="name" />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Label id="label_age" htmlFor="age">
            ご年齢
          </Form.Label>
          <Form.Group>
            <InputGroup>
              <Form.Control defaultValue={props["age#age"]} id="age" name="age" type="number" />
              <InputGroup.Append>
                <InputGroup.Text id="span_age">歳</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        {props.type === "cast" && (
          <Col md={6}>
            <Form.Label id="label_fake_surname" htmlFor="fake_surname">
              希望源氏名
            </Form.Label>
            <Form.Group as={Form.Row}>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="span_fake_surname">姓</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    id="fake_surname"
                    name="fake_surname"
                    defaultValue={props["fake_surname#fake_surname"]}
                    type="text"
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="span_fake_name">名</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control id="fake_name" name="fake_name" defaultValue={props["fake_name#fake_name"]} type="text" />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        )}
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_address" htmlFor="address">
            ご住所
          </Form.Label>
          <Form.Group>
            <Form.Control id="address" required defaultValue={props["address#address"]} type="text" name="address" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={6}>
          <Form.Label id="label_travel_time" htmlFor="travel_time">
            通勤時間
          </Form.Label>
          <Form.Group>
            <InputGroup>
              <Form.Control
                id="travel_time"
                required
                defaultValue={props["travel_time#travel_time"]}
                type="text"
                name="travel_time"
              />
              <InputGroup.Append>
                <InputGroup.Text id="span_travel_time">分</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        {/* <Col md={6}>
          <Form.Label id="label_cell_number" htmlFor="cell_number">
            ご連絡先　携帯番号
          </Form.Label>
          <Form.Group>
            <Form.Control
              id="cell_number"
              required
              defaultValue={props["cell_number#cell_number"]}
              type="text"
              name="cell_number"
            />
          </Form.Group>
        </Col> */}
      </Form.Row>
    </React.Fragment>
  );
}
