import React, { useEffect } from "react";
import { Form, Col } from "react-bootstrap";

export default function ActualStatus(props) {
  return (
    <Form.Row>
      <Col xs={12}>
        <Form.Label id="label_actual_status" htmlFor="actual_status">
          状況
        </Form.Label>
        <Form.Group>
          <Form.Control id="actual_status" name="actual_status" as="select">
            <option value="合格" selected={props["actual_status#actual_status"] === "合格"}>
              合格
            </option>
            <option value="不合格" selected={props["actual_status#actual_status"] === "不合格"}>
              不合格
            </option>
            <option value="辞退" selected={props["actual_status#actual_status"] === "辞退"}>
              辞退
            </option>
            <option value="保留" selected={props["actual_status#actual_status"] === "保留"}>
              保留
            </option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Form.Row>
  );
}
