import React, { useState } from "react";
import { Col, Form, InputGroup, FormGroup } from "react-bootstrap";
import Datetime from "react-datetime";
import WorkingStyle from "../Common/WorkingStyle";
import GeneralInfo from "../Common/GeneralInfo";
import ActualStatus from "../Common/ActualStatus";

export const label = "01";

export function Driver_01(props) {
  const [hasChildren, setHasChildren] = useState(true);
  const [Q8, setQ8] = useState(true);

  const onQ2Change = (e) => {
    setHasChildren(e.currentTarget.value === "yes" ? true : false);
  };

  const onQ8Change = (e) => {
    setQ8(e.currentTarget.value === "yes" ? true : false);
  };

  return (
    <React.Fragment>
      <Form.Row style={{ marginBottom: "1em" }}>
        <Col md={8}>
          <h2 id="h2_title" style={{ border: "none" }}>
            面接シート（フランク）
          </h2>
          <Form.Row className="subtitle">
            <h6 id="h6_subtitle0">出会えた事に感謝しています！せっかくの貴重なお時間ですので何でも聞いてください。</h6>
            <h6 id="h6_subtitle1">
              {"当店とのご縁がなければ、他店を紹介させて頂きますので、\nお互い納得信頼のできる時間を過ごしましょう。"}
            </h6>
          </Form.Row>
        </Col>
        <Col md={4}>
          <ActualStatus {...props} />
          <Form.Row>
            <Col xs={12}>
              <Form.Label id="label_interviewer" htmlFor="interviewer">
                面接担当
              </Form.Label>
              <Form.Group>
                <Form.Control
                  id="interviewer"
                  required
                  defaultValue={props["interviewer#interviewer"]}
                  type="text"
                  name="interviewer"
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>

      <GeneralInfo {...props} />
      <Form.Row>
        <Col>
          <Form.Label id="label_section0">身分証明書 </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={6}>
              <Form.Check
                defaultChecked={props["identity#resident"]}
                required
                inline
                type="radio"
                value="resident"
                name="identity"
                id="resident"
              />
              <Form.Label id="label_resident" htmlFor="resident">
                本籍地入りの住民票
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Check
                defaultChecked={props["identity#passport"]}
                required
                inline
                type="radio"
                value="passport"
                name="identity"
                id="passport"
              />
              <Form.Label id="label_passport" htmlFor="passport">
                パスポート
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Check
                defaultChecked={props["identity#driver_license"]}
                required
                inline
                type="checkbox"
                value="driver_license"
                name="identity"
                id="driver_license"
              />
              <Form.Label id="label_driver_license" htmlFor="driver_license">
                運転免許証
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Check
                defaultChecked={props["identity#vehile_certificate"]}
                required
                inline
                type="checkbox"
                value="vehile_certificate"
                name="identity"
                id="vehile_certificate"
              />
              <Form.Label id="label_vehile_certificate" htmlFor="vehile_certificate">
                車検証
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_vehile">車種</Form.Label>
          <Form.Group>
            <Form.Control id="vehile" required defaultValue={props["vehile#vehile"]} type="text" name="vehile" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Label id="label_numPassengers">乗車可能人数</Form.Label>
          <Form.Group>
            <InputGroup>
              <Form.Control
                id="numPassengers"
                required
                defaultValue={props["numPassengers#numPassengers"]}
                type="number"
                name="numPassengers"
              />
              <InputGroup.Append>
                <InputGroup.Text id="label_postappend_numPassengers">人（本人含めない）</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Label id="label_licensed_for_years">免許取得経過年数</Form.Label>
          <Form.Group>
            <InputGroup>
              <Form.Control
                id="licensed_for_years"
                required
                defaultValue={
                  props["licensed_for_years#licensed_for_years"] ? props["licensed_for_years#licensed_for_years"] : ""
                }
                type="number"
                name="licensed_for_years"
              />
              <InputGroup.Append>
                <InputGroup.Text id="label_postappend_licensed_for_years">年</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
      </Form.Row>
      <WorkingStyle {...props} />
      <Form.Row>
        <h4 id="h4_subtitle3">あなた自身について教えてください。</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q1section3">
            <b>Q1</b> 最終学歴を教えてください。
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["school#middle_school"]}
                id="middle_school"
                name="school"
                inline
                type="radio"
                value="middle_school"
              />
              <Form.Label id="label_middle_school" htmlFor="middle_school">
                中学
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["school#high_school"]}
                id="high_school"
                name="school"
                inline
                type="radio"
                value="high_school"
              />
              <Form.Label id="label_high_school" htmlFor="high_school">
                高校
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["school#vocational_school"]}
                id="vocational_school"
                name="school"
                inline
                type="radio"
                value="vocational_school"
              />
              <Form.Label id="label_vocational_school" htmlFor="vocational_school">
                専門学校
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["school#university"]}
                id="university"
                name="school"
                inline
                type="radio"
                value="university"
              />
              <Form.Label id="label_university" htmlFor="university">
                大学
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["school#graduate"]}
                id="graduate"
                name="school"
                inline
                type="radio"
                value="graduate"
              />
              <Form.Label id="label_graduate" htmlFor="graduate">
                大学院
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["school#enrolled"]}
                id="enrolled"
                name="school"
                inline
                type="radio"
                value="enrolled"
              />
              <Form.Label id="label_enrolled" htmlFor="enrolled">
                在学中
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q2section3">
            <b>Q2</b> ご結婚はされていますか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q1#unmarried"]} id="unmarried" name="Q1" inline type="radio" value="unmarried" />
              <Form.Label id="label_unmarried" htmlFor="unmarried">
                未婚
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q1#married"]} id="married" name="Q1" inline type="radio" value="married" />
              <Form.Label id="label_married" htmlFor="married">
                既婚
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q1#divorced"]} id="divorced" name="Q1" inline type="radio" value="divorced" />
              <Form.Label id="label_divorced" htmlFor="divorced">
                離婚
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q1#remarried"]} id="remarried" name="Q1" inline type="radio" value="remarried" />
              <Form.Label id="label_remarried" htmlFor="remarried">
                再婚
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q1#separated"]} id="separated" name="Q1" inline type="radio" value="separated" />
              <Form.Label id="label_separated" htmlFor="separated">
                別居
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>

      {/*DELETE Q3
      <Form.Row>
        <Col>
          <Form.Label id="label_q3section3">
            <b>Q3</b> お子様はいらっしゃいますか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["Q2#q2yes"]}
                onChange={onQ2Change}
                id="q2yes"
                name="Q2"
                inline
                type="radio"
                value="yes"
              />
              <Form.Label id="label_q2yes" htmlFor="q2yes">
                いる
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["Q2#q2no"]}
                onChange={onQ2Change}
                id="q2no"
                name="Q2"
                inline
                type="radio"
                value="no"
              />
              <Form.Label id="label_q2no" htmlFor="q2no">
                いない
              </Form.Label>
            </Col>
            {hasChildren && (
              <Form.Row>
                <Col>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control defaultValue={props["childage#child1"]} name="childage" id="child1" type="number" />
                      <InputGroup.Append>
                        <InputGroup.Text id="labelAgeChild0">才</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control defaultValue={props["childage#child2"]} name="childage" id="child2" type="number" />
                      <InputGroup.Append>
                        <InputGroup.Text id="labelAgeChild1">才</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control defaultValue={props["childage#child3"]} name="childage" id="child3" type="number" />
                      <InputGroup.Append>
                        <InputGroup.Text id="labelAgeChild2">才</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
      */}

      {/*DELETE Q4
      <Form.Row>
        <Col>
          <Form.Label id="label_q4">
            <b>Q4</b> あなたのパートナー（彼氏、夫）は、あなたの仕事についてご存じですか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q3#official"]} id="official" name="Q3" inline type="radio" value="official " />
              <Form.Label id="label_official" htmlFor="official">
                公認
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q3#secret"]} id="secret" name="Q3" inline type="radio" value="secret" />
              <Form.Label id="label_secret" htmlFor="secret">
                内緒
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q3#single"]} id="single" inline name="Q3" type="radio" value="single" />
              <Form.Label id="label_single" htmlFor="single">
                シングル
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      */}

      <Form.Row>
        <Col>
          <Form.Label id="label_q5">
            <b>Q3</b> お住まいの状況について教えてください。
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q4#alone"]} id="alone" name="Q4" inline type="radio" value="alone" />
              <Form.Label id="label_alone" htmlFor="alone">
                一人暮らし
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q4#together "]} id="together" name="Q4" inline type="radio" value="together " />
              <Form.Label id="label_together" htmlFor="together">
                同棲
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q4#husband"]} id="husband" name="Q4" inline type="radio" value="husband" />
              <Form.Label id="label_husband" htmlFor="husband">
                夫や子供と同居
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q4#parents"]} id="parents" name="Q4" inline type="radio" value="parents" />
              <Form.Label id="label_parents" htmlFor="parents">
                両親と同居
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q6">
            <b>Q4</b> 借金はございますか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q6#q6yes"]} id="q6yes" name="Q6" inline type="radio" value="yes" />
              <Form.Label id="label_q6yes" htmlFor="q6yes">
                いる
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["Q6#q6no"]} id="q6no" name="Q6" inline type="radio" value="no" />
              <Form.Label id="label_q6no" htmlFor="q6no">
                ない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q7">
            <b>Q5</b> ナイト業界で働かれたい理由を教えてください。
          </Form.Label>
          <Form.Group>
            <Form.Control defaultValue={props["q7TArea#q7TArea"]} name="q7TArea" id="q7TArea" as="textarea" rows={2} />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="label_q8">
            <b>Q6</b> ナイト業界の経験は御座いますか？
          </Form.Label>
          <Form.Group as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["Q8#q8yes"]}
                onChange={onQ8Change}
                id="q8yes"
                name="Q8"
                inline
                type="radio"
                value="yes"
              />
              <Form.Label id="label_q8yes" htmlFor="q8yes">
                いる
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["Q8#q8no"]}
                onChange={onQ8Change}
                id="q8no"
                name="Q8"
                inline
                type="radio"
                value="no"
              />
              <Form.Label id="label_q8no" htmlFor="q8no">
                いない
              </Form.Label>
            </Col>
          </Form.Group>

          {Q8 && (
            <React.Fragment>
              <Form.Row>
                <Col>
                  <Form.Label id="label_q1end">以前のお店の名前は？</Form.Label>
                  <FormGroup>
                    <Form.Control
                      defaultValue={props["previous_store#previous_store"]}
                      name="previous_store"
                      id="previous_store"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Form.Label id="label_q2end">働いていた時期は？</Form.Label>
                  <FormGroup>
                    <Datetime
                      inputProps={{ name: "previus_work_date", id: "previus_work_date", readOnly: "readonly" }}
                      initialValue={props["previus_work_date#previus_work_date"]}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label id="label_q3end">お辞めになった理由は？</Form.Label>
                  <FormGroup>
                    <Form.Control
                      defaultValue={props["previus_work_quit_motivation#previus_work_quit_motivation"]}
                      name="previus_work_quit_motivation"
                      id="previus_work_quit_motivation"
                      as="textarea"
                      rows={2}
                    />
                  </FormGroup>
                </Col>
              </Form.Row>
            </React.Fragment>
          )}
        </Col>
      </Form.Row>
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <Driver_01 {...props}></Driver_01>;

component[label + "PageName"] = "面接シート（フランク）";
component[label + "Template"] = {
  data: {
    h2_title: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 0.09999806249998845,
      },
      wraps: false,
      x: 82.32,
      y: 805.79,
    },
    h6_subtitle0: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 39.718278062499984,
      },
      wraps: false,
      x: 42.32,
      y: 762.17,
    },
    h6_subtitle1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 53.43229806249998,
      },
      wraps: false,
      x: 42.32,
      y: 748.46,
    },
    label_actual_status: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: -4.264996729166678,
      },
      wraps: false,
      x: 388.16,
      y: 797.63,
    },
    actual_status: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: 12.29023806249999,
      },
      wraps: false,
      x: 388.16,
      y: 789.6,
      width: 162.46842229729728,
      height: 21.737674062500002,
    },
    label_interviewer: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: 37.789743999999985,
      },
      wraps: false,
      x: 388.16,
      y: 764.1,
    },
    interviewer: {
      page: 0,
      normalized: {
        x: 348.16216216216213,
        y: 54.344978791666655,
      },
      wraps: false,
      x: 388.16,
      y: 747.55,
      width: 162.46842229729728,
      height: 21.737674062500002,
    },
    label_name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 88.68558327083332,
      },
      wraps: false,
      x: 42.32,
      y: 713.2,
    },
    name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 105.24081806249998,
      },
      wraps: false,
      x: 42.32,
      y: 696.65,
      width: 167.11058445945946,
      height: 21.737674062500002,
    },
    label_age: {
      page: 0,
      normalized: {
        x: 174.0738277027027,
        y: 88.68558327083332,
      },
      wraps: false,
      x: 214.07,
      y: 713.2,
    },
    age: {
      page: 0,
      normalized: {
        x: 174.0738277027027,
        y: 105.24081806249998,
      },
      wraps: false,
      x: 214.07,
      y: 696.65,
      width: 62.20497297297297,
      height: 21.737674062500002,
    },
    span_age: {
      page: 0,
      normalized: {
        x: 235.81458445945947,
        y: 105.24081806249998,
      },
      wraps: false,
      x: 275.81,
      y: 696.65,
    },
    label_address: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 130.740324,
      },
      wraps: false,
      x: 42.32,
      y: 671.15,
    },
    address: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 147.29555879166665,
      },
      wraps: false,
      x: 42.32,
      y: 654.59,
      width: 510.6378378378378,
      height: 21.737674062500002,
    },
    label_travel_time: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 172.79506472916665,
      },
      wraps: false,
      x: 42.32,
      y: 629.09,
    },
    travel_time: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 189.35029952083332,
      },
      wraps: false,
      x: 42.32,
      y: 612.54,
      width: 233.964972972973,
      height: 21.737674062500002,
    },
    span_travel_time: {
      page: 0,
      normalized: {
        x: 235.82183783783782,
        y: 189.35029952083332,
      },
      wraps: false,
      x: 275.82,
      y: 612.54,
    },
    label_cell_number: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 172.79506472916665,
      },
      wraps: false,
      x: 299.96,
      y: 629.09,
    },
    cell_number: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 189.35029952083332,
      },
      wraps: false,
      x: 299.96,
      y: 612.54,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_section0: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 214.84980545833332,
      },
      wraps: false,
      x: 42.32,
      y: 587.04,
    },
    resident: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 239.53186691666664,
      },
      wraps: false,
      x: 44.64,
      y: 562.36,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_resident: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 235.67479879166666,
      },
      wraps: false,
      x: 55.32,
      y: 566.22,
    },
    passport: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 239.53186691666664,
      },
      wraps: false,
      x: 299.96,
      y: 562.36,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_passport: {
      page: 0,
      normalized: {
        x: 270.638054054054,
        y: 235.67479879166666,
      },
      wraps: false,
      x: 310.64,
      y: 566.22,
    },
    driver_license: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 252.23003358333332,
      },
      wraps: false,
      x: 44.64,
      y: 549.66,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_driver_license: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 248.3729654583333,
      },
      wraps: false,
      x: 55.32,
      y: 553.52,
    },
    vehile_certificate: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 252.23003358333332,
      },
      wraps: false,
      x: 299.96,
      y: 549.66,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_vehile_certificate: {
      page: 0,
      normalized: {
        x: 270.638054054054,
        y: 248.3729654583333,
      },
      wraps: false,
      x: 310.64,
      y: 553.52,
    },
    label_vehile: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 268.69003212499996,
      },
      wraps: false,
      x: 42.32,
      y: 533.2,
    },
    vehile: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 285.24526691666665,
      },
      wraps: false,
      x: 42.32,
      y: 516.64,
      width: 167.11783783783784,
      height: 21.737674062500002,
    },
    label_numPassengers: {
      page: 0,
      normalized: {
        x: 174.08108108108107,
        y: 268.69003212499996,
      },
      wraps: false,
      x: 214.08,
      y: 533.2,
    },
    numPassengers: {
      page: 0,
      normalized: {
        x: 174.08108108108107,
        y: 285.24526691666665,
      },
      wraps: false,
      x: 214.08,
      y: 516.64,
      width: 88.66529729729729,
      height: 21.737674062500002,
    },
    label_postappend_numPassengers: {
      page: 0,
      normalized: {
        x: 262.28216216216214,
        y: 285.24526691666665,
      },
      wraps: false,
      x: 302.28,
      y: 516.64,
    },
    label_licensed_for_years: {
      page: 0,
      normalized: {
        x: 345.8410810810811,
        y: 268.69003212499996,
      },
      wraps: false,
      x: 385.84,
      y: 533.2,
    },
    licensed_for_years: {
      page: 0,
      normalized: {
        x: 345.8410810810811,
        y: 285.24526691666665,
      },
      wraps: false,
      x: 385.84,
      y: 516.64,
      width: 148.08497297297296,
      height: 21.737674062500002,
    },
    label_postappend_licensed_for_years: {
      page: 0,
      normalized: {
        x: 493.46183783783783,
        y: 285.24526691666665,
      },
      wraps: false,
      x: 533.46,
      y: 516.64,
    },
    subtite_working_style: {
      page: 0,
      normalized: {
        x: 0,
        y: 315.10976764583336,
      },
      wraps: false,
      x: 40,
      y: 486.78,
    },
    label_prefered_num_work_days: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 353.9185395208333,
      },
      wraps: false,
      x: 42.32,
      y: 447.97,
    },
    prefered_num_work_days: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 370.4737743125,
      },
      wraps: false,
      x: 42.32,
      y: 431.42,
      width: 233.964972972973,
      height: 21.737674062500002,
    },
    span_prefered_num_work_days: {
      page: 0,
      normalized: {
        x: 235.82183783783782,
        y: 370.4737743125,
      },
      wraps: false,
      x: 275.82,
      y: 431.42,
    },
    label_prefered_not_work_days: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 353.9185395208333,
      },
      wraps: false,
      x: 299.96,
      y: 447.97,
    },
    prefered_not_work_days: {
      page: 0,
      normalized: {
        x: 259.96108108108103,
        y: 370.4737743125,
      },
      wraps: false,
      x: 299.96,
      y: 431.42,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_prefered_work_time: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 395.97328024999996,
      },
      wraps: false,
      x: 42.32,
      y: 405.92,
    },
    prefered_work_time_from: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 412.52851504166665,
      },
      wraps: false,
      x: 42.32,
      y: 389.36,
      width: 231.5350912162162,
      height: 21.737674062500002,
    },
    prefered_work_time_divisor: {
      page: 0,
      normalized: {
        x: 253.92627027027027,
        y: 412.22693358333333,
      },
      wraps: false,
      x: 293.93,
      y: 389.66,
    },
    prefered_work_time_to: {
      page: 0,
      normalized: {
        x: 281.43108108108106,
        y: 412.52851504166665,
      },
      wraps: false,
      x: 321.43,
      y: 389.36,
      width: 231.5350912162162,
      height: 21.737674062500002,
    },
    label_other_work: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 438.0280209791666,
      },
      wraps: false,
      x: 42.32,
      y: 363.86,
    },
    other_work: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 454.5832557708333,
      },
      wraps: false,
      x: 42.32,
      y: 347.31,
      width: 510.6378378378378,
      height: 21.737674062500002,
    },
    h4_subtitle3: {
      page: 0,
      normalized: {
        x: 0,
        y: 484.4477565,
      },
      wraps: false,
      x: 40,
      y: 317.44,
    },
    label_q1section3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 523.256528375,
      },
      wraps: false,
      x: 42.32,
      y: 278.63,
    },
    middle_school: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 547.9385898333334,
      },
      wraps: false,
      x: 44.64,
      y: 253.95,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_middle_school: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 544.0815217083334,
      },
      wraps: false,
      x: 55.32,
      y: 257.81,
    },
    high_school: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 547.9385898333334,
      },
      wraps: false,
      x: 214.85,
      y: 253.95,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_high_school: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 544.0815217083334,
      },
      wraps: false,
      x: 225.53,
      y: 257.81,
    },
    vocational_school: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 547.9385898333334,
      },
      wraps: false,
      x: 385.06,
      y: 253.95,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_vocational_school: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 544.0815217083334,
      },
      wraps: false,
      x: 395.73,
      y: 257.81,
    },
    university: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 560.6367565,
      },
      wraps: false,
      x: 44.64,
      y: 241.25,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_university: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 556.779688375,
      },
      wraps: false,
      x: 55.32,
      y: 245.11,
    },
    graduate: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 560.6367565,
      },
      wraps: false,
      x: 214.85,
      y: 241.25,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_graduate: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 556.779688375,
      },
      wraps: false,
      x: 225.53,
      y: 245.11,
    },
    enrolled: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 560.6367565,
      },
      wraps: false,
      x: 385.06,
      y: 241.25,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_enrolled: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 556.779688375,
      },
      wraps: false,
      x: 395.73,
      y: 245.11,
    },
    label_q2section3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 577.0967550416667,
      },
      wraps: false,
      x: 42.32,
      y: 224.79,
    },
    unmarried: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 601.7788165000001,
      },
      wraps: false,
      x: 44.64,
      y: 200.11,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_unmarried: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 597.9217483750001,
      },
      wraps: false,
      x: 55.32,
      y: 203.97,
    },
    married: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 601.7788165000001,
      },
      wraps: false,
      x: 214.85,
      y: 200.11,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_married: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 597.9217483750001,
      },
      wraps: false,
      x: 225.53,
      y: 203.97,
    },
    divorced: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 601.7788165000001,
      },
      wraps: false,
      x: 385.06,
      y: 200.11,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_divorced: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 597.9217483750001,
      },
      wraps: false,
      x: 395.73,
      y: 203.97,
    },
    remarried: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 614.4769831666667,
      },
      wraps: false,
      x: 44.64,
      y: 187.41,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_remarried: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 610.6199150416667,
      },
      wraps: false,
      x: 55.32,
      y: 191.27,
    },
    separated: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 614.4769831666667,
      },
      wraps: false,
      x: 214.85,
      y: 187.41,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_separated: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 610.6199150416667,
      },
      wraps: false,
      x: 225.53,
      y: 191.27,
    },
    label_q3section3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 630.9369817083334,
      },
      wraps: false,
      x: 42.32,
      y: 170.95,
    },
    q2yes: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 655.6190431666666,
      },
      wraps: false,
      x: 44.64,
      y: 146.27,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_q2yes: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 651.7619750416667,
      },
      wraps: false,
      x: 55.32,
      y: 150.13,
    },
    q2no: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 655.6190431666666,
      },
      wraps: false,
      x: 214.85,
      y: 146.27,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_q2no: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 651.7619750416667,
      },
      wraps: false,
      x: 225.53,
      y: 150.13,
    },
    child1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 668.3172098333334,
      },
      wraps: false,
      x: 44.64,
      y: 133.57,
      width: 111.4118918918919,
      height: 21.737674062500002,
    },
    labelAgeChild0: {
      page: 0,
      normalized: {
        x: 115.58983783783783,
        y: 668.3172098333334,
      },
      wraps: false,
      x: 155.59,
      y: 133.57,
    },
    child2: {
      page: 0,
      normalized: {
        x: 139.72908108108106,
        y: 668.3172098333334,
      },
      wraps: false,
      x: 179.73,
      y: 133.57,
      width: 111.4118918918919,
      height: 21.737674062500002,
    },
    labelAgeChild1: {
      page: 0,
      normalized: {
        x: 250.67675675675676,
        y: 668.3172098333334,
      },
      wraps: false,
      x: 290.68,
      y: 133.57,
    },
    child3: {
      page: 0,
      normalized: {
        x: 274.816,
        y: 668.3172098333334,
      },
      wraps: false,
      x: 314.82,
      y: 133.57,
      width: 111.4118918918919,
      height: 21.737674062500002,
    },
    labelAgeChild2: {
      page: 0,
      normalized: {
        x: 385.76367567567564,
        y: 668.3172098333334,
      },
      wraps: false,
      x: 425.76,
      y: 133.57,
    },
    label_q4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 701.9435424375,
      },
      wraps: false,
      x: 42.32,
      y: 99.95,
    },
    official: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 726.6256038958334,
      },
      wraps: false,
      x: 44.64,
      y: 75.26,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_official: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 722.7685357708334,
      },
      wraps: false,
      x: 55.32,
      y: 79.12,
    },
    secret: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 726.6256038958334,
      },
      wraps: false,
      x: 214.85,
      y: 75.26,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_secret: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 722.7685357708334,
      },
      wraps: false,
      x: 225.53,
      y: 79.12,
    },
    single: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 726.6256038958334,
      },
      wraps: false,
      x: 385.06,
      y: 75.26,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_single: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 722.7685357708334,
      },
      wraps: false,
      x: 395.73,
      y: 79.12,
    },
    label_q5: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 743.0856024375,
      },
      wraps: false,
      x: 42.32,
      y: 58.8,
    },
    alone: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 5.877663895833379,
      },
      wraps: false,
      x: 44.64,
      y: 796.01,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_alone: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 2.0205957708333795,
      },
      wraps: false,
      x: 55.32,
      y: 799.87,
    },
    together: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 5.877663895833379,
      },
      wraps: false,
      x: 214.85,
      y: 796.01,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_together: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 2.0205957708333795,
      },
      wraps: false,
      x: 225.53,
      y: 799.87,
    },
    husband: {
      page: 1,
      normalized: {
        x: 345.0577162162162,
        y: 5.877663895833379,
      },
      wraps: false,
      x: 385.06,
      y: 796.01,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_husband: {
      page: 1,
      normalized: {
        x: 355.73468918918917,
        y: 2.0205957708333795,
      },
      wraps: false,
      x: 395.73,
      y: 799.87,
    },
    parents: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 18.575830562500045,
      },
      wraps: false,
      x: 44.64,
      y: 783.31,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_parents: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 14.718762437500047,
      },
      wraps: false,
      x: 55.32,
      y: 787.17,
    },
    label_q6: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 35.035829104166716,
      },
      wraps: false,
      x: 42.32,
      y: 766.85,
    },
    q6yes: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 59.71789056250004,
      },
      wraps: false,
      x: 44.64,
      y: 742.17,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_q6yes: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 55.86082243750004,
      },
      wraps: false,
      x: 55.32,
      y: 746.03,
    },
    q6no: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 59.71789056250004,
      },
      wraps: false,
      x: 214.85,
      y: 742.17,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_q6no: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 55.86082243750004,
      },
      wraps: false,
      x: 225.53,
      y: 746.03,
    },
    label_q7: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 76.17788910416671,
      },
      wraps: false,
      x: 42.32,
      y: 725.71,
    },
    q7TArea: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 92.73312389583337,
      },
      wraps: false,
      x: 42.32,
      y: 709.16,
      width: 510.6378378378378,
      height: 35.55486666666667,
    },
    label_q8: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 132.04982243750004,
      },
      wraps: false,
      x: 42.32,
      y: 669.84,
    },
    q8yes: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 156.7318838958334,
      },
      wraps: false,
      x: 44.64,
      y: 645.16,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_q8yes: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 152.87481577083338,
      },
      wraps: false,
      x: 55.32,
      y: 649.02,
    },
    q8no: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 156.7318838958334,
      },
      wraps: false,
      x: 214.85,
      y: 645.16,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_q8no: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 152.87481577083338,
      },
      wraps: false,
      x: 225.53,
      y: 649.02,
    },
    label_q1end: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 173.19188243750006,
      },
      wraps: false,
      x: 44.64,
      y: 628.7,
    },
    previous_store: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 189.74711722916672,
      },
      wraps: false,
      x: 44.64,
      y: 612.14,
      width: 250.67675675675676,
      height: 21.737674062500002,
    },
    label_q2end: {
      page: 1,
      normalized: {
        x: 259.96108108108103,
        y: 173.19188243750006,
      },
      wraps: false,
      x: 299.96,
      y: 628.7,
    },
    previus_work_date: {
      page: 1,
      normalized: {
        x: 259.96108108108103,
        y: 189.74711722916672,
      },
      wraps: false,
      x: 299.96,
      y: 612.14,
      width: 250.67675675675676,
      height: 21.737674062500002,
    },
    "": {
      page: 0,
      normalized: {
        x: -332.61091891891886,
        y: 474.09875066666666,
      },
      wraps: false,
      x: -292.61,
      y: 327.79,
    },
    label_q3end: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 215.24662316666672,
      },
      wraps: false,
      x: 44.64,
      y: 586.64,
    },
    previus_work_quit_motivation: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 231.80185795833336,
      },
      wraps: false,
      x: 44.64,
      y: 570.09,
      width: 505.9956756756756,
      height: 35.55486666666667,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1500,
    backgroundColor: "190, 230, 188",
    drawRects: [
      {
        x: "44.32",
        y: "506.64",
        width: "100%",
        height: "ref label_name",
        // color: "255, 255, 255",
      },
    ],
    addLogo: true,
  },
};

export default component;
