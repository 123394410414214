//export const BACKEND_URL = "https://tabe.work/wip/onlineform/backend"; //Test URL
export const BACKEND_URL = "https://infinitygp.info/backend"; //Export URL
//export const BACKEND_URL = "http://localhost:8003/backend/public/index.php"; //localhost
export const allowedUploadExts = [".jpg", ".jpeg", ".png", ".pdf"];

export const CAST_LABEL = "cast";
export const DRIVER_LABEL = "driver";
export const STAFF_LABEL = "staff";

export const CAST_LOCAL = "キャスト";
export const DRIVER_LOCAL = "ドライバー";
export const STAFF_LOCAL = "スタッフ";

export const dedicatedChar = "!";
//Step input !originali, senza ! sono in comune
export const castFlow = ["03", "!04", "!06", "nd", "02", "bd"]; //Deleted 01, 02, !03. Added 02
export const driverFlow = ["!01", "02", "03", "nd", "!05", "!06", "bd"]; //Driver deleted
export const staffFlow = ["03", "!05", "nd", "!01", "bd"]; //Deleted 01, 02. Added 02

export const castFlowSteps = castFlow.length;
export const driverFlowSteps = driverFlow.length;
export const staffFlowSteps = staffFlow.length;

const labelToFlow = {};
labelToFlow[CAST_LABEL] = castFlow;
labelToFlow[DRIVER_LABEL] = driverFlow;
labelToFlow[STAFF_LABEL] = staffFlow;

const labelToLocal = {};
labelToLocal[CAST_LABEL] = CAST_LOCAL;
labelToLocal[DRIVER_LABEL] = DRIVER_LOCAL;
labelToLocal[STAFF_LABEL] = STAFF_LOCAL;

export { labelToFlow, labelToLocal };
