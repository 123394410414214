import C4 from "./Cast_04";
import C1 from "./Cast_01";
import C6 from "./Cast_06";
import C7 from "./Cast_07";
import C3 from "./Cast_03";

const CastComponents = {
  ...C1,
  ...C3,
  ...C4,
  ...C6,
  ...C7,
};

export default CastComponents;
