import "./Page.scss";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header/Header";
import PropTypes from "prop-types";

function Page(props) {
  return (
    <React.Fragment>
      <Header />
      <Container>
        {/* {props.topActionBar && (
          <div id="topActionBar" className="topActionBar">
            {props.topActionBar}{" "}
          </div>
        )} */}

        <div id="pageContent" className="pageContent">
          {props.children}
        </div>

        {/* {props.bottomActionBar && (
          <div id="bottomActionBar" className="bottomActionBar">
            {props.bottomActionBar}
          </div>
        )} */}
      </Container>
    </React.Fragment>
  );
}

Page.propTypes = {
  children: PropTypes.any.isRequired,
  topActionBar: PropTypes.any,
  bottomActionBar: PropTypes.any,
};

export default Page;
