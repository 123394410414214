import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
function Step(props) {
  const history = useHistory();
  const isDone = () => {
    return props.index < props.current;
  };
  const isActive = () => {
    return props.index === props.current;
  };
  const goToStep = () => {
    let link = "/" + props.type + "/" + (props.index + 1);
    link += props.edit ? "/" + props.edit : "";
    history.push(link);
  };
  return (
    <div className={"page-item "} onClick={goToStep}>
      <li className={(isDone() ? "done " : "") + (isActive() ? "active " : "")}></li>
      <div className={isDone() ? "num done " : "num" + (isActive() ? " active" : "")}>{props.index + 1}</div>
      {props.total - 1 !== props.index && (
        <React.Fragment>
          <div className={isDone() ? "page-separator done " : "page-separator"}></div>
        </React.Fragment>
      )}
    </div>
  );
}

Step.propTypes = {
  index: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Step;
