import "./Header.scss";
import React from "react";
import logoLong from "../../assets/logos/Page_Logo.jpg";
import { useHistory } from "react-router-dom";

export default function Header() {
  const history = useHistory();

  const goHome = () => {
    if (history.location.pathname !== "/") history.push("/");
  };

  return (
    <div id="header" onClick={goHome} className="logo-container">
      <img src={logoLong} alt="logo" />
    </div>
  );
}
