import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import Datetime from "react-datetime";

export const label = "05";

export function Driver_05(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="label_h2_title0">誓約書 （フランク就業規則）</h2>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin0">●18 歳以上であり、高校生ではありません。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin1">●身分証明書本籍地入りの住民票かパスポートのどちらか 1 点を提出します。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin2">●給与システムを説明され、それに納得した上で業務を行います</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin3">
            ●ルールブック（フランク）の内容を全て確認し、そのルールのもとで業務を行います。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin4">●指摘、注意を受けた際には真摯に受け止める。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin5">●送迎の中での、盗難や事故に関して全て自己責任である事を納得しました。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin6">
            ●店舗内スタッフでの交際、そうであろうという言動や行動、状況があった場合、損害賠償をするこ
            とを約束し当店の顧問弁護士に状況を説明し状況を真摯に受け止める事を約束します。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin7">
            悪質な場合は即日解雇となり、その場合には最低賃金で給与が支払われる事を納得しました。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin8">
            ●当店に在職後に、他の同業者のお店と掛け持ちの状態での勤務をしない事に納得しました。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin9">●退職は、退職日から 52 日出勤（暦日数ではに）前申告とする。無断で退職しない。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin10">
            このルールを守らず退職に至った場合は最低賃金で給与が支払われる事を納得しました。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin11">●退職決定後や退職後に、当社の批評、批判をしません。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoin12">
            ●薬物や如何になる違法行為を私は行いません。どのような時も法律遵守である事をここで約束します。
          </Form.Label>
        </Col>
      </Form.Row>
      {/* <Form.Row>
        <Col>
          <Form.Label id="bulletpoin13"></Form.Label>
        </Col>
      </Form.Row> */}
      <Form.Row>
        <Col md={4}>
          {/* <Form.Label id="label_formdata0">令和</Form.Label> */}
          <Form.Group>
            <Datetime
              initialValue={props["date#date"]}
              inputProps={{ name: "date", id: "date", readOnly: "readonly" }}
              timeFormat={false}
              locale="ja"
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Signature {...props} />
    </React.Fragment>
  );
}

const component = {};
component[label] = Driver_05;
component[label + "PageName"] = "誓約書 （フランク就業規則）";
component[label + "Template"] = {
  data: {
    interviewed: {
      page: 0,
      normalized: {
        x: 0,
        y: 0.14999709375000433,
      },
      wraps: false,
      x: 40,
      y: 801.74,
    },
    label_h2_title0: {
      page: 0,
      normalized: {
        x: 0,
        y: 7.007007093750004,
      },
      wraps: false,
      x: 40,
      y: 794.88,
    },
    bulletpoin0: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 60.648824906250006,
      },
      wraps: false,
      x: 42.32,
      y: 741.24,
    },
    bulletpoin1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 78.93418490625,
      },
      wraps: false,
      x: 42.32,
      y: 722.96,
    },
    bulletpoin2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 97.21954490625001,
      },
      wraps: false,
      x: 42.32,
      y: 704.67,
    },
    bulletpoin3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 115.50490490625,
      },
      wraps: false,
      x: 42.32,
      y: 686.39,
    },
    bulletpoin4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 133.79026490625,
      },
      wraps: false,
      x: 42.32,
      y: 668.1,
    },
    bulletpoin5: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 152.07562490625,
      },
      wraps: false,
      x: 42.32,
      y: 649.81,
    },
    bulletpoin6: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 170.36098490625,
      },
      wraps: true,
      x: 42.32,
      y: 631.53,
      lines: 2,
    },
    bulletpoin7: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 206.93170490625,
      },
      wraps: false,
      x: 42.32,
      y: 594.96,
    },
    bulletpoin8: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 225.21706490624996,
      },
      wraps: false,
      x: 42.32,
      y: 576.67,
    },
    bulletpoin9: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 243.50242490624998,
      },
      wraps: false,
      x: 42.32,
      y: 558.39,
    },
    bulletpoin10: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 261.78778490624995,
      },
      wraps: false,
      x: 42.32,
      y: 540.1,
    },
    bulletpoin11: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 280.07314490625,
      },
      wraps: false,
      x: 42.32,
      y: 521.82,
    },
    bulletpoin12: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 298.35850490625,
      },
      wraps: false,
      x: 42.32,
      y: 503.53,
    },
    date: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 323.19135709374996,
      },
      wraps: false,
      x: 42.32,
      y: 478.7,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    "": {
      page: 0,
      normalized: {
        x: -196.82767567567566,
        y: -171.882384,
      },
      wraps: false,
      x: -156.83,
      y: 630.01,
    },
    label_signature: {
      page: 0,
      normalized: {
        x: 483.24908108108104,
        y: 367.9881081875,
      },
      wraps: false,
      x: 523.25,
      y: 433.9,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1000,
  },
};

export default component;
