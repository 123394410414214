import React, { useState } from "react";
import { Col, FormGroup, Row, Form, Container, Collapse } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import { toTemplate } from "../../../core/Utils";

import Datetime from "react-datetime";
import "moment/locale/ja";
import Retired from "./Retired";

export const label = "03";

export function C_03(props) {
  const [birthday, setBirthday] = useState();

  const onBirthdayChange = (value) => {
    setBirthday(value);
  };

  console.log("PROPS : ", props);

  return (
    <React.Fragment>
      <Form.Row>
        <Col md={8}>
          <h2 style={{ border: "none" }} id="h2_title">
            従業者名簿（風俗営業)
          </h2>
        </Col>
        <Col md={4}>
          <Retired {...props} />
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 style={{ border: "none" }} id="h4_subtitle1">
          【共通記載事項】
        </h4>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="label_address" htmlFor="address">
            現　住　所
          </Form.Label>
          <FormGroup>
            <Form.Control id="address" required defaultValue={props["address#address"]} type="text" name="address" />
          </FormGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={6}>
          <Form.Label id="label_kanji_name" htmlFor="kanji_name">
            氏 名
          </Form.Label>
          <FormGroup>
            <Form.Control id="kanji_name" required defaultValue={props["kanji_name#kanji_name"]} type="text" name="kanji_name" />
          </FormGroup>
        </Col>
        <Col md={6}>
          <Form.Label id="label_furigana_name" htmlFor="furigana_name">
            (ふりがな)
          </Form.Label>
          <FormGroup>
            <Form.Control
              id="furigana_name"
              required
              defaultValue={props["furigana_name#furigana_name"]}
              type="text"
              name="furigana_name"
            />
          </FormGroup>
        </Col>

        {props.type === "cast" && (
          <React.Fragment>
            <Col md={6}>
              <Form.Label id="label_kanji_fakename" htmlFor="kanji_fakename">
                源 氏 名
              </Form.Label>
              <FormGroup>
                <Form.Control
                  id="kanji_fakename"
                  required
                  defaultValue={props["kanji_fakename#kanji_fakename"]}
                  type="text"
                  name="kanji_fakename"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Form.Label id="label_furigana_fakename" htmlFor="furigana_fakename">
                (ふりがな)
              </Form.Label>
              <FormGroup>
                <Form.Control
                  id="furigana_fakename"
                  required
                  defaultValue={props["furigana_fakename#furigana_fakename"]}
                  type="text"
                  name="furigana_fakename"
                />
              </FormGroup>
            </Col>
          </React.Fragment>
        )}

        <Col md={12}>
          <Form.Label id="label_telephone" htmlFor="telephone">
            本籍地のある都道府県
          </Form.Label>
          <FormGroup>
            <Form.Control id="telephone" required defaultValue={props["telephone#telephone"]} type="text" name="telephone" />
          </FormGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_birthday" htmlFor="birthday">
            生年月日
          </Form.Label>
          <FormGroup>
            <Datetime
              initialValue={props["birthday#birthday"] ? props["birthday#birthday"] : "2000/01/01"}
              inputProps={{ name: "birthday", id: "birthday", readOnly: "readonly" }}
              timeFormat={false}
              locale="ja"
            />
          </FormGroup>
        </Col>
        <Col>
          <Form.Label id="label_hired_date" htmlFor="hired_date">
            採用 年月日
          </Form.Label>
          <FormGroup>
            <Datetime
              initialValue={props["hired_date#hired_date"]}
              inputProps={{ name: "hired_date", id: "hired_date", readOnly: "readonly" }}
              timeFormat={false}
              locale="ja"
            />
          </FormGroup>
        </Col>
        <Col>
          <Form.Label id="label_retirement_date" htmlFor="retirement_date">
            退職年月日
          </Form.Label>
          <FormGroup>
            <Datetime
              initialValue={props["retirement_date#retirement_date"]}
              inputProps={{ name: "retirement_date", id: "retirement_date", readOnly: "readonly" }}
              timeFormat={false}
              locale="ja"
            />
          </FormGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_sex" htmlFor="sex">
            性別
          </Form.Label>
          <FormGroup as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check defaultChecked={props["sex#man"]} required inline type="radio" value="man" name="sex" id="man" />
              <Form.Label id="label_man" htmlFor="man">
                男
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check defaultChecked={props["sex#woman"]} required inline type="radio" value="woman" name="sex" id="woman" />
              <Form.Label id="label_woman" htmlFor="woman">
                女
              </Form.Label>
            </Col>
          </FormGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_work_type" htmlFor="work_type">
            従事する業務の内容
          </Form.Label>
          <FormGroup as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["work_type#customer_service"]}
                required
                inline
                type="radio"
                value="Customer service"
                name="work_type"
                id="customer_service"
              />
              <Form.Label id="label_customer_service" htmlFor="customer_service">
                接客業務
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["work_type#hall_business"]}
                required
                inline
                type="radio"
                value="Hall business"
                name="work_type"
                id="hall_business"
              />
              <Form.Label id="label_hall_business" htmlFor="hall_business">
                ホール業務
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["work_type#pick-up"]}
                required
                inline
                type="radio"
                value="Pick-up service"
                name="work_type"
                id="pick-up"
              />
              <Form.Label id="label_pick-up" htmlFor="pick-up">
                送迎業務
              </Form.Label>
            </Col>
          </FormGroup>
        </Col>
      </Form.Row>

      {/* ADD NEW SELECTION WITH MULTIPLE CHOICE!!! */}
      {/* ADDED 5 DOCUMENT TYPE IN UserDetails.js document_type# */}

      <Form.Row>
        <Col>
          <Form.Label id="label_document_type" htmlFor="document_type">
            身分証明書
          </Form.Label>
          <FormGroup as={Form.Row} style={{ marginTop: "1em" }}>
            <Col md={4}>
              <Form.Check
                defaultChecked={props["document_type#document_juminhyo"]}
                required
                inline
                type="radio"
                value="Resident Certificate"
                name="document_type"
                id="document_juminhyo"
              />
              <Form.Label id="label_document_juminhyo" htmlFor="document_juminhyo">
                住民票(本籍地記載)
              </Form.Label>
            </Col>

            <Col md={4}>
              <Form.Check
                defaultChecked={props["document_type#document_passport"]}
                required
                inline
                type="radio"
                value="Passport"
                name="document_type"
                id="document_passport"
              />
              <Form.Label id="label_document_passport" htmlFor="document_passport">
                パスポート
              </Form.Label>
            </Col>

            <Col md={4}>
              <Form.Check
                defaultChecked={props["document_type#document_drive_license"]}
                required
                inline
                type="radio"
                value="Drive License"
                name="document_type"
                id="document_drive_license"
              />
              <Form.Label id="label_document_drive_license" htmlFor="document_drive_license">
                運転免許証
              </Form.Label>
            </Col>

            <Col md={4}>
              <Form.Check
                defaultChecked={props["document_type#document_toseki_tohon"]}
                required
                inline
                type="radio"
                value="Family Register"
                name="document_type"
                id="document_toseki_tohon"
              />
              <Form.Label id="label_document_toseki_tohon" htmlFor="document_toseki_tohon">
                戸籍謄本
              </Form.Label>
            </Col>

            <Col md={4}>
              <Form.Check
                defaultChecked={props["document_type#document_residence_card"]}
                required
                inline
                type="radio"
                value="Residence Card"
                name="document_type"
                id="document_residence_card"
              />
              <Form.Label id="label_document_residence_card" htmlFor="document_residence_card">
                在留カード
              </Form.Label>
            </Col>
          </FormGroup>
        </Col>
      </Form.Row>

      <Form.Row>
        <h4 id="h4_subtitle2">【接待飲食等営業】（身分確認の記録・保存）</h4>
      </Form.Row>
      {/* <Form.Row>
        <Col>
          <Form.Label id="label_confirmation_date" htmlFor="confirmation_date">
            確 認 年 月 日
          </Form.Label>
          <FormGroup>
            <Datetime
              initialValue={props["confirmation_date#confirmation_date"]}
              inputProps={{ name: "confirmation_date", id: "confirmation_date", readOnly: "readonly" }}
              timeFormat={false}
              locale="ja"
            />
          </FormGroup>
        </Col>
        <Col>
          <Form.Label id="label_prefecture" htmlFor="prefecture">
            都道府県名
          </Form.Label>
          <FormGroup>
            <Form.Control id="prefecture" required defaultValue={props["prefecture#prefecture"]} type="text" name="prefecture" />
          </FormGroup>
        </Col>
      </Form.Row> */}
      <Form.Row>
        <Col>
          <h5 id="p1">確認書類 の 保存義務</h5>
        </Col>
      </Form.Row>
      <div style={{ marginTop: "1em" }}>
        <Form.Row>
          <Col>
            <Form.Label id="p2">従業者名簿</Form.Label>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Label id="p3">身分確認書類の写しを従業者名簿に添付して保存</Form.Label>
          </Col>
        </Form.Row>
      </div>
      <div style={{ marginTop: "1em" }}>
        <Form.Row>
          <Col>
            <Form.Label id="p4">電磁的従業者名簿</Form.Label>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Label id="p5">
              身分証明書類の写しをスキャナで読み取った電磁的記録を 電磁的名簿の内容と照合できるようにして保存
            </Form.Label>
          </Col>
        </Form.Row>
      </div>
      {/* //GIUSTO!
      <Form.Row style={{ border: "1px solid" }}>
        <Col style={{ borderRight: "1px solid" }} sm={4}>
          <Form.Label id="p1">
            確認書類 <br />の <br /> 保存義務
          </Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Row style={{ borderBottom: "1px solid" }}>
            <Col style={{ borderRight: "1px solid" }}>
              <Form.Label id="p2">従業者名簿</Form.Label>
            </Col>
            <Col>
              <Form.Label id="p3">身分確認書類の写しを従業者名簿に添付して保存</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col style={{ borderRight: "1px solid" }}>
              <Form.Label id="p4">電磁的従業者名簿</Form.Label>
            </Col>
            <Col>
              <Form.Label id="p5">
                身分証明書類の写しをスキャナで読み取った電磁的記録を 電磁的名簿の内容と照合できるようにして保存
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
     
     
     //FINE GIUSTO */}
      {/* //INIZIO GIUSTO */}
      {/* <Form.Row>
        <h4 id="h4_subtitle3">※身分確認書類</h4>
      </Form.Row>
      <Form.Row style={{ border: "1px solid" }}>
        <Col style={{ borderRight: "1px solid" }}>
          <Form.Row>
            <Col style={{ borderBottom: "1px solid" }}>
              <Form.Label id="p6">（接客従業者が日本人の場合）</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label id="p7"> 1 住民票記載事項証明書（生年月日及び本籍地都道府県が 記載されているものに限る）</Form.Label>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="p8">2 旅券</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="p9">
                3 その他官公庁から発行又は発給された書類等生年月日 及び本籍地都道府県名の記載があるもの。
              </Form.Label>
            </Col>
          </Form.Row>
        </Col>
        <Col>
          <Form.Row>
            <Col style={{ borderBottom: "1px solid" }}>
              <Form.Label id="p10">（接客従業者が外国人の場合）</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="p11">1 在留力一ド</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="p12"> 2 旅券</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label id="p13">3 特別永住者証明書</Form.Label>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row> */}
      {/* //FINE GIUSTO */}
      <Form.Row>
        <h4 id="h4_subtitle3">※身分確認書類</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <h5 id="p6">（接客従業者が日本人の場合）</h5>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p7">1 住民票記載事項証明書（生年月日及び本籍地都道府県が 記載されているものに限る）</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p8">2 旅券</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p9">
            3 その他官公庁から発行又は発給された書類等生年月日 及び本籍地都道府県名の記載があるもの。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <h5 id="p10">（接客従業者が外国人の場合）</h5>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p11">1 在留力一ド</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p12">2 旅券</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p13">3 特別永住者証明書</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_subtitle4">※注意事項</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p14">
            1 常時営業所に待機している者はもちろん、必要に応じて他から派遣されてくる者であっても、
            当該営業にかかる業務に従事している限り、この名簿に記載しなければなりません。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p15"> 2 この名簿は、従業者の退職、解雇、死亡の日から起算して3年は保存しなければなりません</Form.Label>
        </Col>
      </Form.Row>
      <Signature {...props} />

      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
      */}
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <C_03 {...props}></C_03>;
component[label + "PageName"] = "従業者名簿（風俗営業)";
component[label + "Template"] = {
  data: {
    interviewed: { page: 0, normalized: { x: 0, y: 0.1095216875000231 }, wraps: false, x: 40, y: 801.78 },
    h2_title: { page: 0, normalized: { x: 2.321081081081081, y: 4.680861687500023 }, wraps: false, x: 42.32, y: 797.21 },
    label_retired_status: {
      page: 0,
      normalized: { x: 348.16216216216213, y: 0.3158668958333564 },
      wraps: false,
      x: 388.16,
      y: 801.57,
    },
    retired_status: {
      page: 0,
      normalized: { x: 348.16216216216213, y: 16.871101687500023 },
      wraps: false,
      x: 388.16,
      y: 785.02,
      width: 162.46842229729728,
      height: 21.737674062500002,
    },
    h4_subtitle1: { page: 0, normalized: { x: 0, y: 46.73560241666669 }, wraps: false, x: 40, y: 755.15 },
    label_address: { page: 0, normalized: { x: 2.321081081081081, y: 85.44120168750003 }, wraps: false, x: 42.32, y: 716.45 },
    address: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 101.99643647916669 },
      wraps: false,
      x: 42.32,
      y: 699.89,
      width: 510.6378378378378,
      height: 21.737674062500002,
    },
    label_kanji_name: { page: 0, normalized: { x: 2.321081081081081, y: 127.49594241666668 }, wraps: false, x: 42.32, y: 674.39 },
    kanji_name: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 144.05117720833334 },
      wraps: false,
      x: 42.32,
      y: 657.84,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_furigana_name: {
      page: 0,
      normalized: { x: 259.96108108108103, y: 127.49594241666668 },
      wraps: false,
      x: 299.96,
      y: 674.39,
    },
    furigana_name: {
      page: 0,
      normalized: { x: 259.96108108108103, y: 144.05117720833334 },
      wraps: false,
      x: 299.96,
      y: 657.84,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_kanji_fakename: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 169.55068314583337 },
      wraps: false,
      x: 42.32,
      y: 632.34,
    },
    kanji_fakename: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 186.1059179375 },
      wraps: false,
      x: 42.32,
      y: 615.78,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_furigana_fakename: {
      page: 0,
      normalized: { x: 259.96108108108103, y: 169.55068314583337 },
      wraps: false,
      x: 299.96,
      y: 632.34,
    },
    furigana_fakename: {
      page: 0,
      normalized: { x: 259.96108108108103, y: 186.1059179375 },
      wraps: false,
      x: 299.96,
      y: 615.78,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_telephone: { page: 0, normalized: { x: 2.321081081081081, y: 211.605423875 }, wraps: false, x: 42.32, y: 590.28 },
    telephone: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 228.1606586666667 },
      wraps: false,
      x: 42.32,
      y: 573.73,
      width: 510.6378378378378,
      height: 21.737674062500002,
    },
    label_birthday: { page: 0, normalized: { x: 2.321081081081081, y: 253.66016460416668 }, wraps: false, x: 42.32, y: 548.23 },
    birthday: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 270.21539939583334 },
      wraps: false,
      x: 42.32,
      y: 531.67,
      width: 167.11783783783784,
      height: 21.737674062500002,
    },
    "": { page: 0, normalized: { x: -184.06172972972973, y: 527.075502 }, wraps: false, x: -144.06, y: 274.81 },
    label_hired_date: {
      page: 0,
      normalized: { x: 174.08108108108107, y: 253.66016460416668 },
      wraps: false,
      x: 214.08,
      y: 548.23,
    },
    hired_date: {
      page: 0,
      normalized: { x: 174.08108108108107, y: 270.21539939583334 },
      wraps: false,
      x: 214.08,
      y: 531.67,
      width: 167.11783783783784,
      height: 21.737674062500002,
    },
    label_retirement_date: {
      page: 0,
      normalized: { x: 345.8410810810811, y: 253.66016460416668 },
      wraps: false,
      x: 385.84,
      y: 548.23,
    },
    retirement_date: {
      page: 0,
      normalized: { x: 345.8410810810811, y: 270.21539939583334 },
      wraps: false,
      x: 385.84,
      y: 531.67,
      width: 167.11783783783784,
      height: 21.737674062500002,
    },
    label_sex: { page: 0, normalized: { x: 2.321081081081081, y: 295.7149053333334 }, wraps: false, x: 42.32, y: 506.18 },
    man: {
      page: 0,
      normalized: { x: 4.642162162162162, y: 320.39696679166667 },
      wraps: false,
      x: 44.64,
      y: 481.49,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_man: { page: 0, normalized: { x: 15.319135135135134, y: 316.5398986666667 }, wraps: false, x: 55.32, y: 485.35 },
    woman: {
      page: 0,
      normalized: { x: 174.84993918918917, y: 320.39696679166667 },
      wraps: false,
      x: 214.85,
      y: 481.49,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_woman: { page: 0, normalized: { x: 185.52691216216215, y: 316.5398986666667 }, wraps: false, x: 225.53, y: 485.35 },
    label_work_type: { page: 0, normalized: { x: 2.321081081081081, y: 336.85696533333333 }, wraps: false, x: 42.32, y: 465.03 },
    customer_service: {
      page: 0,
      normalized: { x: 4.642162162162162, y: 361.5390267916667 },
      wraps: false,
      x: 44.64,
      y: 440.35,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_customer_service: {
      page: 0,
      normalized: { x: 15.319135135135134, y: 357.6819586666667 },
      wraps: false,
      x: 55.32,
      y: 444.21,
    },
    hall_business: {
      page: 0,
      normalized: { x: 174.84993918918917, y: 361.5390267916667 },
      wraps: false,
      x: 214.85,
      y: 440.35,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_hall_business: {
      page: 0,
      normalized: { x: 185.52691216216215, y: 357.6819586666667 },
      wraps: false,
      x: 225.53,
      y: 444.21,
    },
    "pick-up": {
      page: 0,
      normalized: { x: 345.0577162162162, y: 361.5390267916667 },
      wraps: false,
      x: 385.06,
      y: 440.35,
      width: 8.355891891891892,
      height: 9.14268,
    },
    "label_pick-up": { page: 0, normalized: { x: 355.73468918918917, y: 357.6819586666667 }, wraps: false, x: 395.73, y: 444.21 },
    label_document_type: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 377.99902533333335 },
      wraps: false,
      x: 42.32,
      y: 423.89,
    },
    document_juminhyo: {
      page: 0,
      normalized: { x: 4.642162162162162, y: 402.6810867916667 },
      wraps: false,
      x: 44.64,
      y: 399.21,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_document_juminhyo: {
      page: 0,
      normalized: { x: 15.319135135135134, y: 398.82401866666675 },
      wraps: false,
      x: 55.32,
      y: 403.07,
    },
    document_passport: {
      page: 0,
      normalized: { x: 174.84993918918917, y: 402.6810867916667 },
      wraps: false,
      x: 214.85,
      y: 399.21,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_document_passport: {
      page: 0,
      normalized: { x: 185.52691216216215, y: 398.82401866666675 },
      wraps: false,
      x: 225.53,
      y: 403.07,
    },
    document_drive_license: {
      page: 0,
      normalized: { x: 345.0577162162162, y: 402.6810867916667 },
      wraps: false,
      x: 385.06,
      y: 399.21,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_document_drive_license: {
      page: 0,
      normalized: { x: 355.73468918918917, y: 398.82401866666675 },
      wraps: false,
      x: 395.73,
      y: 403.07,
    },
    document_toseki_tohon: {
      page: 0,
      normalized: { x: 4.642162162162162, y: 415.3792534583333 },
      wraps: false,
      x: 44.64,
      y: 386.51,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_document_toseki_tohon: {
      page: 0,
      normalized: { x: 15.319135135135134, y: 411.52218533333337 },
      wraps: false,
      x: 55.32,
      y: 390.37,
    },
    document_residence_card: {
      page: 0,
      normalized: { x: 174.84993918918917, y: 415.3792534583333 },
      wraps: false,
      x: 214.85,
      y: 386.51,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_document_residence_card: {
      page: 0,
      normalized: { x: 185.52691216216215, y: 411.52218533333337 },
      wraps: false,
      x: 225.53,
      y: 390.37,
    },
    h4_subtitle2: { page: 0, normalized: { x: 0, y: 436.20424679166666 }, wraps: false, x: 40, y: 365.69 },
    p1: { page: 0, normalized: { x: 2.321081081081081, y: 479.7827675208334 }, wraps: false, x: 42.32, y: 322.11 },
    p2: { page: 0, normalized: { x: 2.321081081081081, y: 498.8220811666667 }, wraps: false, x: 42.32, y: 303.07 },
    p3: { page: 0, normalized: { x: 2.321081081081081, y: 511.0123211666667 }, wraps: false, x: 42.32, y: 290.88 },
    p4: { page: 0, normalized: { x: 2.321081081081081, y: 531.3293878333334 }, wraps: false, x: 42.32, y: 270.56 },
    p5: { page: 0, normalized: { x: 2.321081081081081, y: 543.5196278333334 }, wraps: false, x: 42.32, y: 258.37 },
    h4_subtitle3: { page: 0, normalized: { x: 0, y: 560.074862625 }, wraps: false, x: 40, y: 241.82 },
    p6: { page: 0, normalized: { x: 2.321081081081081, y: 603.6533833541666 }, wraps: false, x: 42.32, y: 198.24 },
    p7: { page: 0, normalized: { x: 2.321081081081081, y: 614.5658703333334 }, wraps: false, x: 42.32, y: 187.32 },
    p8: { page: 0, normalized: { x: 2.321081081081081, y: 626.7561103333334 }, wraps: false, x: 42.32, y: 175.13 },
    p9: { page: 0, normalized: { x: 2.321081081081081, y: 638.9463503333334 }, wraps: false, x: 42.32, y: 162.94 },
    p10: { page: 0, normalized: { x: 2.321081081081081, y: 655.501585125 }, wraps: false, x: 42.32, y: 146.39 },
    p11: { page: 0, normalized: { x: 2.321081081081081, y: 666.4140721041666 }, wraps: false, x: 42.32, y: 135.48 },
    p12: { page: 0, normalized: { x: 2.321081081081081, y: 678.6043121041666 }, wraps: false, x: 42.32, y: 123.29 },
    p13: { page: 0, normalized: { x: 2.321081081081081, y: 690.7945521041667 }, wraps: false, x: 42.32, y: 111.1 },
    h4_subtitle4: { page: 0, normalized: { x: 0, y: 707.3497868958334 }, wraps: false, x: 40, y: 94.54 },
    p14: { page: 0, normalized: { x: 2.321081081081081, y: 746.5633128333333 }, wraps: true, x: 42.32, y: 55.33, lines: 2 },
    p15: { page: 1, normalized: { x: 2.321081081081081, y: 9.053792833333356 }, wraps: false, x: 42.32, y: 792.84 },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: { type: "RGB", red: 1, green: 0, blue: 0 },
    pixelBreakPage: 1500,
  },
};
export default component;
