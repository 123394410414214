import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import Page from "../pages/Page";

import { getStatus } from "./status";

export default function PrivateRoute({ component: Component, path, topActionBar, bottomActionBar, ...rest }) {
  const [auth, setAuth] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    getStatus(setAuth, setRole);
  }, []);

  return (
    <React.Fragment>
      {auth === null ? (
        <Loading />
      ) : (
        <Route
          path={path}
          render={(props) =>
            auth ? (
              <Page role={role} topActionBar={topActionBar} bottomActionBar={bottomActionBar}>
                <Component {...rest} role={role} />
              </Page>
            ) : (
              <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
          }
        />
      )}
    </React.Fragment>
  );
}
