import React from "react";
import Signature from "../../Signature/Signature";
import { toTemplate } from "../../../core/Utils";
import { Col, Form, InputGroup } from "react-bootstrap";
import Datetime from "react-datetime";

export const label = "nd";

export function ND(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">寮使用誓約書</h2>
      </Form.Row>
      <Form.Row>
        <Col sm={6}>
          <Form.Label id="p1">寮に入居するに際して下記事項を制約します。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p2">
          <b>1 下記に定める寮管理規定を誠実に遵守します。</b>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p3">i.女性のみ寮には立ち入れるものとし、 男性の入室を如何なる場合でも禁止とする。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p4">
            ii.22時以降の洗濯機を使用する事は禁止とする。 22時以降は大きな音を立てたり騒いだりしない。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p5">
            iii 水周りが詰まっている、電気がつかないなど室内や共用部分に不備がある 場合は速やかに会社に報告する。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p6">
            iv ペットボトル、缶、ビン、燃えるゴミは分別するものとし共用部分の &nbsp; 分別ゴミ箱に適切に廃棄するものとする。&nbsp;
            ペットボトルのキャップ、 ラベルは剥 がし燃えるゴミとして分別をする。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p7">v 共用部分に私物を置かないものとする。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p170">
            vi.寮を留守にするのは最大2週間までとする。但し3ヶ月以上の在籍期間を置く者にのみ許可を与える。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p171">vii.部屋を常に綺麗な状態で保つ。掃除をせずに退寮した場合はクリーニング代を支払う。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p172">viii.室内及び備品の破損を確認したらすぐに報告をする。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p173">ix.鍵の紛失は弁償する。</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p8">
          <b>2 会社に対して一切損害を与えない様にします。</b>
        </Form.Label>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p9">
          <b>3 他の入居者に対して迷惑をかけない様にします。</b>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p10" style={{ fontWeight: "bold" }}>
            4
            会社の許可を得て施設内に変更を加えた者は、立ち退きの際に現状に復します。&nbsp;尚、その際に必要となる費用は私が負担します。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Label id="p11">
          <b>5 規定に違反した場合、会社側が契約を解除した場合、 &nbsp;会社の規定する期日までに立ち退きます。</b>
        </Form.Label>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p12">
          <b>6 立ち退きの際、会社の基準に対しての現状に回復しない場合、 &nbsp;予定している報酬から5万円お支払いします。</b>
        </Form.Label>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p13">
          <b>
            7 喫煙【iQOS含め全ての煙草】は禁止である事を遵守します。
            &nbsp;違反した場合は、壁紙、リネン、その他匂いがつくもの全ての &nbsp;修繕費及び買い替え費用をお支払いします。
          </b>
        </Form.Label>
      </Form.Row>

      <Signature {...props} />
      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
      */}
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <ND {...props}></ND>;

component[label + "PageName"] = "寮使用誓約書";
component[label + "Template"] = {
  data: {
    interviewed: { page: 0, normalized: { x: 0, y: 0.10952168749999422 }, wraps: false, x: 40, y: 801.78 },
    h2_title: { page: 0, normalized: { x: 0, y: 14.42670460416666 }, wraps: false, x: 40, y: 787.46 },
    p1: { page: 0, normalized: { x: 2.321081081081081, y: 50.386325333333325 }, wraps: false, x: 42.32, y: 751.5 },
    p2: { page: 0, normalized: { x: 0, y: 66.941560125 }, wraps: false, x: 40, y: 734.95 },
    p3: { page: 0, normalized: { x: 2.321081081081081, y: 79.63972679166666 }, wraps: false, x: 42.32, y: 722.25 },
    p4: { page: 0, normalized: { x: 2.321081081081081, y: 91.82996679166666 }, wraps: false, x: 42.32, y: 710.06 },
    p5: { page: 0, normalized: { x: 2.321081081081081, y: 104.02020679166665 }, wraps: false, x: 42.32, y: 697.87 },
    p6: { page: 0, normalized: { x: 2.321081081081081, y: 116.21044679166666 }, wraps: true, x: 42.32, y: 685.68, lines: 2 },
    p7: { page: 0, normalized: { x: 2.321081081081081, y: 140.59092679166665 }, wraps: false, x: 42.32, y: 661.3 },
    p170: { page: 0, normalized: { x: 2.321081081081081, y: 152.78116679166664 }, wraps: false, x: 42.32, y: 649.11 },
    p171: { page: 0, normalized: { x: 2.321081081081081, y: 164.97140679166665 }, wraps: false, x: 42.32, y: 636.92 },
    p172: { page: 0, normalized: { x: 2.321081081081081, y: 177.16164679166664 }, wraps: false, x: 42.32, y: 624.73 },
    p173: { page: 0, normalized: { x: 2.321081081081081, y: 189.35188679166666 }, wraps: false, x: 42.32, y: 612.54 },
    p8: { page: 0, normalized: { x: 0, y: 205.90712158333332 }, wraps: false, x: 40, y: 595.98 },
    p9: { page: 0, normalized: { x: 0, y: 222.97028304166665 }, wraps: false, x: 40, y: 578.92 },
    p10: { page: 0, normalized: { x: 2.321081081081081, y: 235.66844970833333 }, wraps: false, x: 42.32, y: 566.22 },
    p11: { page: 0, normalized: { x: 0, y: 252.2236845 }, wraps: false, x: 40, y: 549.67 },
    p12: { page: 0, normalized: { x: 0, y: 269.28684595833334 }, wraps: false, x: 40, y: 532.6 },
    p13: { page: 0, normalized: { x: 0, y: 286.3500074166667 }, wraps: false, x: 40, y: 515.54 },
    label_signature: { page: 0, normalized: { x: 483.24908108108104, y: 315.603408875 }, wraps: false, x: 523.25, y: 486.29 },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: { type: "RGB", red: 1, green: 0, blue: 0 },
    pixelBreakPage: 1500,
  },
};

export default component;
