import { PageSizes, PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import fontRegular from "../font/YuGothR.ttf";
import fontBold from "../font/YuGothB.ttf";
import * as download from "downloadjs";
import { getFormMapId, toKanjiDate } from "./Utils";
// import { logoPNG } from "./costants";
import logoPng from "../assets/logos/Page_Logo.jpg";

export default class HTML2Pdf {
  constructor(elementRoot, template, formData, options, signData, name, pdfName) {
    this.elementRoot = elementRoot;
    this.formData = formData;
    this.options = options;
    this.name = name;
    this.pages = [];
    this.x = 0;
    this.y = 0;
    this.template = template;
    this.savePromise = {};
    this.offsetY = 0;
    this.signData = signData;
    this.signImage = undefined;
    this.dynamicPaging = false;
    this.goToNextPage = false;
    this.loadedImg = false;

    this._init().then(() => {
      let entries = Object.entries(template);
      let sorted = entries.sort((a, b) => b[1].y - a[1].y);
      let sortedTemplate = Object.fromEntries(sorted);

      this.fromElement(template, formData, options);
      if (this.signData) {
        console.log("Sign Data: ", this.signData);
        this.addSignature();
      }

      this.savePromise = this.pdfDoc.save().then((bytes) => {
        if (this.options.addLogo) {
          const image = new Image(60, 45);
          image.src = logoPng;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = 340;
            canvas.height = 426;

            ctx.drawImage(image, 0, 0);
            let data = canvas.toDataURL();
            this.setPage(0);
            this.pdfDoc.embedPng(data).then((pdfImage) => {
              const logoDims = pdfImage.scale(0.08);
              this.currentPage.drawImage(pdfImage, {
                x: 30,
                y: this.options.height + 20,
                width: logoDims.width,
                height: logoDims.height,
              });

              console.log("DRAWNED IMAGE : ", data);
              download(bytes, pdfName, "application/pdf");
            });
          };
        } else {
          download(bytes, pdfName, "application/pdf");
        }
      });
    });
  }

  fromElement(template, formData, options) {
    let keys = Object.keys(template);

    if (options.backgroundColor) this.applyBackgroundColor(options);
    if (options.drawRects) this.drawRects(options);
    if (!options.noName) {
      console.log("NO NAME ", this.name);

      let widthofText = this.fontRegular.widthOfTextAtSize(this.name, 10);
      this.currentPage.drawText(this.name, {
        x: options.width / 2 + widthofText / 2,
        y: PageSizes.A4[1] - 12,
        size: 10,
        font: this.fontRegular,
        //   opacity: 0.75,
      });
    }

    for (let i = 0; i < keys.length; i++) {
      let elementId = keys[i];

      let htmlElement = document.getElementById(elementId);

      if (!options || !options.inputColor) {
        const defaultMargin = 40;
        options = {
          margin: defaultMargin,
          width: PageSizes.A4[0] - defaultMargin * 2,
          height: PageSizes.A4[1] - defaultMargin * 2,
          inputColor: rgb(238 / 255, 108 / 255, 77 / 255),
        };
      }

      if (htmlElement && elementMappings[htmlElement.localName]) {
        let dynamicTemplate = this.calcDynamicPage({ ...options, htmlElement: htmlElement }, template[elementId], this.offsetY);

        elementMappings[htmlElement.localName].call(this, htmlElement, dynamicTemplate, formData, {
          ...options,
          dynamicPaging: this.dynamicPaging,
        });
      }
    }
  }

  calcDynamicPage(pageOptions, elementTemplate, currentOffset) {
    // console.log("pageOptions.height: ", pageOptions.height);
    // console.log("currentOffset: ", currentOffset);
    // console.log("elementTemplate: ", elementTemplate);
    let newTemplate = { ...elementTemplate };

    if (elementTemplate.y - currentOffset <= pageOptions.margin / 2) {
      console.log("Incrementing page for element ", pageOptions.htmlElement);
      newTemplate.page = elementTemplate.page + 1;
      this.dynamicPaging = true;
      this.goToNextPage = true;
    }

    if (this.dynamicPaging) {
      console.log("Old Y ", elementTemplate.y);

      let fontSize = fontSizeMappings[pageOptions.htmlElement.localName]
        ? fontSizeMappings[pageOptions.htmlElement.localName]
        : 10;

      if (this.goToNextPage) {
        newTemplate.y = pageOptions.height + pageOptions.margin / 2 + fontSize;
        this.offsetY = 0;
      }

      // elementTemplate.y = pageOptions.height - Math.abs(elementTemplate.y - pageOptions.height * elementTemplate.page);

      console.log("NewY ", newTemplate.y);
    }

    this.setPage(newTemplate.page);

    this.goToNextPage = false;

    return newTemplate;
  }

  addSignature() {
    const signDims = this.signImage.scale(0.4);
    this.pages[this.pages.length - 1].drawImage(this.signImage, {
      x: this.options.width - signDims.width,
      y: 25,
      width: signDims.width,
      height: signDims.height,
      // opacity: 0.75,
    });
  }

  async _init() {
    this.pdfDoc = await PDFDocument.create();
    if (this.signData) this.signImage = await this.pdfDoc.embedPng(this.signData);

    this.form = this.pdfDoc.getForm();
    this.pdfDoc.registerFontkit(fontkit);

    const fontRegularBytes = await (await caches.match(fontRegular)).arrayBuffer();
    this.fontRegular = await this.pdfDoc.embedFont(fontRegularBytes, { subset: true });
    // const fontLightBytes = await fetch(fontLight).then((res) => res.arrayBuffer());
    // this.fontLight = await this.pdfDoc.embedFont(fontLightBytes);
    // const fontBoldBytes = await fetch(fontBold).then((res) => res.arrayBuffer());
    // this.fontBold = await this.pdfDoc.embedFont(enc.decode(localStorage.getItem("fontB")));
    const fontBoldBytes = await (await caches.match(fontBold)).arrayBuffer();
    this.fontBold = await this.pdfDoc.embedFont(fontBoldBytes, { subset: true });

    this.currentPage = this.pdfDoc.addPage(PageSizes.A4);
    this.pages.push(this.currentPage);
    const { width, height } = this.currentPage.getSize();
    this.width = width;
    this.height = height;
  }

  setPage(page) {
    if (!this.pages[page]) {
      this.currentPage = this.pdfDoc.addPage(PageSizes.A4);
      this.pages.push(this.currentPage);

      this.offsetY = 0;
    } else {
      this.currentPage = this.pages[page];
    }
  }

  static mapTextContent(element, template, formData, options) {
    let text = element.textContent;

    let wrap = this.needsWrap(element.textContent, this.fontRegular, options.width);
    wrap ? console.log("ELEMENT: ", element) : console.log();
    wrap ? console.log("WRAP: ", wrap) : console.log();
    text = wrap ? this.wrap(element.textContent, 68) : text;
    wrap ? console.log("TEXT: ", text) : console.log();
    let dynamicOffset = options.dynamicPaging ? 15 : 0;

    if (wrap) {
      let row = text.split("\n");

      let offsetLines = 0;
      let offsetYTimes = 0;

      for (let i = 0; i < row.length; i++) {
        this.currentPage.drawText(row[i], {
          x: template.x,
          y: template.y - offsetLines - this.offsetY - dynamicOffset,
          // y: template.y - offsetLines - this.offsetY,
          // y: template.y,
          size: fontSizeMappings[element.localName],
          font: this.fontRegular,
          color: rgb(60 / 255, 60 / 255, 60 / 255),
          //   lineHeight: 24,
          //   opacity: 0.75,
        });

        offsetLines += 14;

        if (!template.wraps || (template.wraps && i + 1 > template.lines)) {
          offsetYTimes++;
        }

        // this.offsetY += 10;
      }
      this.offsetY += 10 * offsetYTimes;
    } else {
      this.currentPage.drawText(text, {
        x: template.x,
        // y: template.y,
        y: template.y - this.offsetY - dynamicOffset,
        size: fontSizeMappings[element.localName],
        font: this.fontRegular,
        color: rgb(60 / 255, 60 / 255, 60 / 255),
        lineHeight: fontSizeMappings[element.localName] + 2,
        //   opacity: 0.75,
      });
    }
  }
  static mapInput(element, template, formData, options) {
    if (inputMappings[element.type]) {
      inputMappings[element.type].call(this, element, template, formData[getFormMapId(element)], options);
    }
  }
  static mapTitle(element, template, formData, options) {
    let color = ["0", "0", "0"];
    if (options.titlesColor && options.titlesColor[element.localName]) color = options.titlesColor[element.localName].split(",");

    let dynamicOffset = options.dynamicPaging ? 15 : 0;
    this.currentPage.drawText(element.textContent, {
      x: template.x,
      // y: template.y,
      y: template.y - this.offsetY - 15 - dynamicOffset,
      size: fontSizeMappings[element.localName],
      font: this.fontBold,
      color: rgb(color[0] / 255, color[1] / 255, color[2] / 255),
      //   lineHeight: 24,
      //   opacity: 0.75,
    });
    // this.currentPage.drawLine({
    //   start: { x: options.margin, y: template.y - fontSizeMappings[element.localName] - this.offsetY - 15 },
    //   end: { x: options.width + options.margin, y: template.y - fontSizeMappings[element.localName] - this.offsetY - 15 },
    //   thickness: 1,
    // });
  }

  static mapInputText(element, template, value, options) {
    let val = value ? value : "";
    const dateRegeg = /\d+?\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|1[0-9]|2[0-9]|3[0-1])/g;
    val = dateRegeg.test(val) ? toKanjiDate(val) : val;

    let dynamicOffset = this.dynamicPaging ? 15 : 0;

    this.currentPage.drawText(val, {
      x: template.x,
      y: template.y - this.offsetY - dynamicOffset,
      size: 11,
      font: this.fontBold,
      // color: options.inputColor,
      lineHeight: 1,
      //   opacity: 0.75,
    });
    this.currentPage.drawLine({
      start: { x: template.x, y: template.y - 5 - this.offsetY },
      end: { x: template.x + template.width, y: template.y - 5 - this.offsetY },
      thickness: 1,
      color: rgb(200 / 255, 200 / 255, 200 / 255),
    });
  }

  static mapInputTextArea(element, template, formData, options) {
    let val = formData[getFormMapId(element)] ? formData[getFormMapId(element)] : "";
    // let dynamicOffset = this.dynamicPaging ? 15 : 0;
    // let rows = val.replace(/\r/g, "").split(/\n/);

    // rows.forEach((row) => {
    //   console.log(this.wrapMultiline(row, template.width));
    // });

    this.currentPage.drawRectangle({
      x: template.x,
      y: template.y + 12,
      width: template.width,
      height: -template.height,
      borderWidth: 0.4,
      color: rgb(1, 1, 1),
      // borderOpacity: opacity,
    });

    let wrap = this.needsWrap(element.textContent, this.fontBold, template.width);
    val = wrap ? this.wrap(element.textContent, 78) : val;

    this.currentPage.drawText(val, {
      x: template.x + 10,
      y: template.y,
      size: 9,
      font: this.fontBold,
      maxWidth: template.width,
      // color: options.inputColor,
      lineHeight: 12,
      //   opacity: 0.75,
    });
  }
  static mapInputRadio(element, template, value, options) {
    let val = value ? value : false;
    let opacity = val ? 1 : 0.3;

    const xOffset = 3;
    const yOffset = 10;

    this.currentPage.drawCircle({
      x: template.x + xOffset,
      y: template.y + yOffset - this.offsetY,
      size: 4.5,
      borderWidth: 0.4,
      // borderColor: rgb(0.1, 0.2, 0.2),
      color: rgb(1, 1, 1),
      borderOpacity: opacity,
    });

    if (val) {
      this.currentPage.drawCircle({
        x: template.x + xOffset,
        y: template.y + yOffset - this.offsetY,
        size: 2.5,
        borderWidth: 0,
        // borderColor: rgb(0.1, 0.2, 0.2),
        color: rgb(0, 0, 0),
      });
    }
  }
  static mapInputCheckbox(element, template, value, options) {
    let val = value ? value : false;
    let opacity = val ? 1 : 0.3;

    const width = 10;
    const height = 10;
    const xOffset = 3;
    const yOffset = -5;

    this.currentPage.drawRectangle({
      x: template.x - xOffset,
      y: template.y - yOffset - this.offsetY,
      width: width,
      height: height,
      borderWidth: 0.4,
      color: rgb(1, 1, 1),
      borderOpacity: opacity,
    });

    if (val) {
      const crossPadding = 2;

      this.currentPage.drawRectangle({
        x: template.x - xOffset,
        y: template.y - yOffset - this.offsetY,
        width: width,
        height: height,
        borderWidth: 0,
      });

      this.currentPage.drawLine({
        start: { x: template.x - xOffset + crossPadding, y: template.y - yOffset + crossPadding - this.offsetY },
        end: { x: template.x - xOffset + width - crossPadding, y: template.y - yOffset + height - crossPadding - this.offsetY },
        thickness: 1,
        color: rgb(1, 1, 1),
      });

      this.currentPage.drawLine({
        start: { x: template.x - xOffset + crossPadding, y: template.y - yOffset + height - crossPadding - this.offsetY },
        end: { x: template.x - xOffset + width - crossPadding, y: template.y - yOffset + crossPadding - this.offsetY },
        thickness: 1,
        color: rgb(1, 1, 1),
      });
    }
  }
  static mapSelectOne(element, template, value, options) {
    let val = value ? value : "";
    const dateRegeg = /\d+?\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|1[0-9]|2[0-9]|3[0-1])/g;
    val = dateRegeg.test(val) ? toKanjiDate(val) : val;

    this.currentPage.drawText(val, {
      x: template.x,
      y: template.y - this.offsetY - 5,
      size: fontSizeMappings.input,
      font: this.fontBold,
      // color: options.inputColor,
      lineHeight: 1,
      //   opacity: 0.75,
    });

    this.currentPage.drawLine({
      start: { x: template.x, y: template.y - 10 - this.offsetY },
      end: { x: template.x + template.width, y: template.y - 10 - this.offsetY },
      thickness: 1,
      color: rgb(200 / 255, 200 / 255, 200 / 255),
    });
  }

  needsWrap(text, font, maxWidth) {
    let textLength = font.widthOfTextAtSize(text, 10);
    return Math.ceil(textLength / maxWidth) > 1;
  }

  wrap(text, limit) {
    if (text.length > limit) {
      // find the last space within limit
      var edge = this.findFirstSpace(text, limit);
      if (edge > 0) {
        var line = text.slice(0, edge);
        var remainder = text.slice(edge + 1);
        return line + "\n" + this.wrap(remainder, limit);
      }
    }
    return text;
  }

  findFirstSpace(text, limit) {
    var lastSpace = text.slice(0, limit).lastIndexOf(" ");
    var lastFakeSpace = text.slice(0, limit).lastIndexOf(String.fromCharCode(160));
    console.log("FOUND LAST SPACE:  ", lastSpace);
    console.log("FOUND FAKE SPACE:  ", lastFakeSpace);

    if (lastFakeSpace == -1) return lastSpace;
    if (lastSpace == -1) return lastFakeSpace;

    return Math.min(lastSpace, lastFakeSpace);
  }

  applyBackgroundColor(options) {
    console.log(options);

    let color = options.backgroundColor.split(",");
    this.pages.forEach((page) => {
      page.drawRectangle({
        x: options.margin / 2,
        y: options.margin / 2,
        width: options.width + options.margin,
        height: options.height + options.margin,
        // borderWidth: 5,
        // borderColor: grayscale(0.5),
        color: rgb(color[0] / 255, color[1] / 255, color[2] / 255),
        // opacity: 0.5,
        // borderOpacity: 0.75,
      });
    });

    console.log("COLOR : ", color);
  }

  drawRects(options) {
    options.drawRects.forEach((element) => {
      let color = [255, 255, 255];

      if (element.color) color = element.color.split(",");

      const fromRef = /ref (?<id>.+)/;
      let refX = { x: element.x };
      let refY = { y: element.y };
      let refHeight = { y: element.height };

      if (fromRef.test(element.x)) {
        let ref = element.x.match(fromRef).groups;
        console.log("found ref in x to : ", ref);
        console.log("reference found in : ", this.template[ref.id]);

        refX = this.template[ref.id];

        // element.x = this.template[]
      }
      if (fromRef.test(element.y)) {
        let ref = element.y.match(fromRef).groups;
        console.log("found ref in y to : ", ref);
        console.log("reference found in : ", this.template[ref.id]);

        refY = this.template[ref.id];
      }

      if (fromRef.test(element.height)) {
        let ref = element.height.match(fromRef).groups;
        console.log("found ref in height to : ", ref);
        console.log("reference found in : ", this.template[ref.id]);

        refHeight = { ...this.template[ref.id], id: ref.id };
      }

      let refHeightElement = document.getElementById(refHeight.id);

      let width = element.width === "100%" ? options.width + options.margin : element.width;

      this.currentPage.drawRectangle({
        x: refX.x - 15,
        y: refY.y - 5,
        width: width - 18,
        height: refHeight.y - refY.y + fontSizeMappings[refHeightElement.localName] + 10,
        color: rgb(color[0] / 255, color[1] / 255, color[2] / 255),
      });
    });
  }
}

export const fontSizeMappings = {
  h1: 23,
  h2: 18,
  h3: 17.5,
  h4: 13,
  h5: 12,
  h6: 10,
  label: 9,
  span: 9,
  input: 11,
};

export const elementMappings = {
  h1: HTML2Pdf.mapTextContent,
  h2: HTML2Pdf.mapTitle,
  h3: HTML2Pdf.mapTextContent,
  h4: HTML2Pdf.mapTitle,
  h5: HTML2Pdf.mapTextContent,
  h6: HTML2Pdf.mapTextContent,
  //   div: HTML2Pdf.mapTextContent,
  label: HTML2Pdf.mapTextContent,
  span: HTML2Pdf.mapTextContent,
  input: HTML2Pdf.mapInput,
  textarea: HTML2Pdf.mapInputTextArea,
  select: HTML2Pdf.mapInput,
};

export const inputMappings = {
  text: HTML2Pdf.mapInputText,
  number: HTML2Pdf.mapInputText,
  radio: HTML2Pdf.mapInputRadio,
  checkbox: HTML2Pdf.mapInputCheckbox,
  "select-one": HTML2Pdf.mapSelectOne,
};
