import React, { useEffect, useState } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./core/privateRoute";
import { CAST_LABEL, DRIVER_LABEL, STAFF_LABEL, castFlowSteps, driverFlowSteps, staffFlowSteps } from "./core/costants";
import LoginPage from "./pages/Login/LoginPage";
import PdfApp from "./pages/PdfApp/PdfApp";
import Panel from "./pages/Panel/Panel";
import UserDetails from "./pages/UserDetails/UserDetails";

export default function App() {
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <PrivateRoute exact path="/" component={Panel} />
      <PrivateRoute path="/details/:userId" component={UserDetails} />
      <PrivateRoute path="/cast/:step?/:edit?" totalSteps={castFlowSteps} type={CAST_LABEL} component={PdfApp} />
      <PrivateRoute path="/driver/:step?/:edit?" totalSteps={driverFlowSteps} type={DRIVER_LABEL} component={PdfApp} />
      <PrivateRoute path="/staff/:step?/:edit?" totalSteps={staffFlowSteps} type={STAFF_LABEL} component={PdfApp} />
    </Switch>
  );
}
