import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Signature from "../../Signature/Signature";
import Datetime from "react-datetime";

export const label = "06";

export function Driver_06(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="label_h2_title0">運転に関する誓約書</h2>
      </Form.Row>
      {/* <Form.Row>
        <Col style={{ textAlign: "right" }} md={{ span: 4, offset: 8 }}>
          令和　　
          <Form.Group>
            <Datetime
              initialValue={props["date#date"]}
              inputProps={{ name: "date", id: "date" }}
              timeFormat={false}
              locale="ja"
            />
          </Form.Group>
        </Col>
      </Form.Row> */}
      <Form.Row>
        <Col md={4}>
          {/* <Form.Label id="label_name" htmlFor="name">
            殿
          </Form.Label> */}
          <Form.Group>
            <Form.Control defaultValue={props["name#name"]} name="name" id="name" type="text" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint0">
            私は、私有車等（以下、「車両」という）の使用にあたり、以下の事項を厳守して使用することを誓約致します。
          </Form.Label>
        </Col>
      </Form.Row>
      {/* <Form.Row>
        <Col style={{ textAlign: "right" }} md={{ span: 4, offset: 8 }}>
          <Form.Label id="rightlabel">
            <b>以上</b>
          </Form.Label>
        </Col>
      </Form.Row> */}

      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint1">
            １. 私は、交通法令、交通標識および会社の定める規定を遵守し、会社の指示・命令に従って、安全運転に努めます。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint2">
            ２．如何なる事由がありましても、絶対に飲酒・酒気帯び運転および覚醒剤を帯びて運転を致しません。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint3">３．他の交通の危険および迷惑とならない運転を行います。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint4">
            ４．運行中異常気象に遭遇、もしくは通報を受けた場合は、適切な措置をとり、事故防止に努めます。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint5">
            ５．運転または不注意による事故等により車両や他の者に損害を与えた場合、それを弁償致します。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint6">
            ６. 交通違反による罰金・科料または反則金を科せられた場合、自己にて責任を負います。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint7">７.業務上外を問わず、違反歴が生じた場合は、上司に報告致します。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint8">８．運転中に眠気を感じたときは無理をせず、休憩を取るなど事故防止に努めます。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint9">
            ９. 運転免許証の写しを添付致します。運転免許証更新の際は、遅滞なく更新後の免許証の写しを提出致します。
          </Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint10">10・私有車を使用する場合は、必ず私有車の損害保険に加入します。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="bulletpoint11">11. その他貴社の規程に従います。</Form.Label>
        </Col>
      </Form.Row>
      {/* <Form.Row>
        <Col md={4}>
          <Form.Label>住所</Form.Label>
          <Form.Group>
            <Form.Control defaultValue={props["address#address"]} name="address" id="address" type="text" />
          </Form.Group>
        </Col>
      </Form.Row> */}
      <Signature {...props} />
    </React.Fragment>
  );
}

const component = {};
component[label] = Driver_06;
component[label + "PageName"] = "運転に関する誓約書";
component[label + "Template"] = {
  data: {
    interviewed: {
      page: 0,
      normalized: {
        x: 0,
        y: 0.14999709375000433,
      },
      wraps: false,
      x: 40,
      y: 801.74,
    },
    label_h2_title0: {
      page: 0,
      normalized: {
        x: 0,
        y: 7.007007093750004,
      },
      wraps: false,
      x: 40,
      y: 794.88,
    },
    name: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 67.19631709375001,
      },
      wraps: false,
      x: 42.32,
      y: 734.69,
      width: 167.11058445945946,
      height: 32.60651109375,
    },
    bulletpoint0: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 105.44557599999999,
      },
      wraps: false,
      x: 42.32,
      y: 696.44,
    },
    bulletpoint1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 123.73093600000001,
      },
      wraps: false,
      x: 42.32,
      y: 678.16,
    },
    bulletpoint2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 142.016296,
      },
      wraps: false,
      x: 42.32,
      y: 659.87,
    },
    bulletpoint3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 160.301656,
      },
      wraps: false,
      x: 42.32,
      y: 641.59,
    },
    bulletpoint4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 178.587016,
      },
      wraps: false,
      x: 42.32,
      y: 623.3,
    },
    bulletpoint5: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 196.87237599999997,
      },
      wraps: false,
      x: 42.32,
      y: 605.02,
    },
    bulletpoint6: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 215.15773599999997,
      },
      wraps: false,
      x: 42.32,
      y: 586.73,
    },
    bulletpoint7: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 233.44309599999997,
      },
      wraps: false,
      x: 42.32,
      y: 568.45,
    },
    bulletpoint8: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 251.72845599999997,
      },
      wraps: false,
      x: 42.32,
      y: 550.16,
    },
    bulletpoint9: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 270.01381599999996,
      },
      wraps: false,
      x: 42.32,
      y: 531.88,
    },
    bulletpoint10: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 288.299176,
      },
      wraps: false,
      x: 42.32,
      y: 513.59,
    },
    bulletpoint11: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 306.58453599999996,
      },
      wraps: false,
      x: 42.32,
      y: 495.31,
    },
    label_signature: {
      page: 0,
      normalized: {
        x: 483.24908108108104,
        y: 331.41738818749997,
      },
      wraps: false,
      x: 523.25,
      y: 470.47,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1000,
  },
};

export default component;
