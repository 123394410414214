import React from "react";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { toTemplate } from "../../../core/Utils";
import Signature from "../../Signature/Signature";
import Datetime from "react-datetime";

export const label = "02";

export function Common_02(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">店舗車利用規約《キャスト用》</h2>
      </Form.Row>
      <Form.Row>
        <Col sm={6}>
          <Form.Label id="p138">車の外装はもちろん、車内も常に綺麗な状態を保つ</Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p139">
          • 洗車を毎月１回は必ず行い、台風の後も水洗いでいいので必ず洗車する &nbsp;
          その際の費用はレシートがあれば店舗で清算をしていいものとする
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p140">• ガソリン代は自分達で負担をする。利用後に満タンにして返却する。</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p141">• 車に関わる不具合は些細なことでもすぐに報告をする</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p142">• 車を利用する際は、目視にて必ず毎回傷が無いか車体全体の確認をする</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p143">• 事故を起こしたらもちろん、小さな擦り傷１つでもつけたらその日に報告をする</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p144">
            • 如何なる状況下においても飲酒運転をしない。その際に起きた事故等には当店は一切の関与をしない
          </Form.Label>
        </Col>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p145">
          • 事故をおこしてしまった場合、その状況から原則運転者の負担額は算出するものとする。 &nbsp;
          明らかに傷とわかる傷をつけてしまった場合、過失の割合から算出し７割〜９割の修繕費を負担してもらう
        </Form.Label>
      </Form.Row>

      <Form.Row>
        <Form.Label id="p146">
          • 当て逃げの可能性が低い場合の傷が新たに判明し、原因不明となった場合は連帯責任となり、
          &nbsp;修繕費全額を車の利用者全員に負担してもらう
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="p147">• 入寮者、スタッフ以外の人に絶対に運転をさせない</Form.Label>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Label id="p148">
          <b>借りている車であることを常に忘れずに利用します。上記の内容を理解し同意しました。</b>
        </Form.Label>
      </Form.Row>

      <Signature {...props} />
      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
      */}
    </React.Fragment>
  );
}

const component = {};
component[label] = Common_02;

component[label + "PageName"] = "店舗車利用規約";
component[label + "Template"] = {
  data: {
    interviewed: { page: 0, normalized: { x: 0, y: 0.10952168749999422 }, wraps: false, x: 40, y: 801.78 },
    h2_title: { page: 0, normalized: { x: 0, y: 4.680861687499994 }, wraps: false, x: 40, y: 797.21 },
    p138: { page: 0, normalized: { x: 2.321081081081081, y: 40.640482416666664 }, wraps: false, x: 42.32, y: 761.25 },
    p139: { page: 0, normalized: { x: 0, y: 57.19571720833333 }, wraps: false, x: 40, y: 744.69 },
    p140: { page: 0, normalized: { x: 2.321081081081081, y: 69.89388387499999 }, wraps: false, x: 42.32, y: 732 },
    p141: { page: 0, normalized: { x: 2.321081081081081, y: 82.08412387499999 }, wraps: false, x: 42.32, y: 719.81 },
    p142: { page: 0, normalized: { x: 2.321081081081081, y: 94.27436387499999 }, wraps: false, x: 42.32, y: 707.62 },
    p143: { page: 0, normalized: { x: 2.321081081081081, y: 106.464603875 }, wraps: false, x: 42.32, y: 695.43 },
    p144: { page: 0, normalized: { x: 2.321081081081081, y: 118.654843875 }, wraps: false, x: 42.32, y: 683.24 },
    p145: { page: 0, normalized: { x: 0, y: 135.21007866666665 }, wraps: false, x: 40, y: 666.68 },
    p146: { page: 0, normalized: { x: 0, y: 164.463480125 }, wraps: false, x: 40, y: 637.43 },
    p147: { page: 0, normalized: { x: 2.321081081081081, y: 177.16164679166664 }, wraps: false, x: 42.32, y: 624.73 },
    p148: { page: 0, normalized: { x: 0, y: 193.7168815833333 }, wraps: false, x: 40, y: 608.17 },
    label_signature: {
      page: 0,
      normalized: { x: 483.24908108108104, y: 210.78004304166666 },
      wraps: false,
      x: 523.25,
      y: 591.11,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: { type: "RGB", red: 1, green: 0, blue: 0 },
    pixelBreakPage: 1500,
  },
};
export default component;
