import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { BACKEND_URL } from "../../core/costants";
import "./Archive.scss";

import * as download from "downloadjs";

export default function Archive(props) {
  const [data, setData] = useState([]);

  const downloadFile = (e) => {
    let button = e.target;
    let filename = button.parentElement.parentElement.querySelector(".filename").textContent;

    fetch(BACKEND_URL + "/downloadFile?file=" + filename + "&user=" + props.userId).then((res) => {
      res.arrayBuffer().then((data) => {
        console.log(res.headers.forEach(console.log));
        download(data, res.headers.get("filename"), res.headers.get("mime-type"));
      });
    });
  };

  const deleteFile = (e) => {
    let button = e.target;
    let filename = button.parentElement.parentElement.querySelector(".filename").textContent;

    fetch(BACKEND_URL + "/deleteFile?file=" + filename + "&user=" + props.userId).then((res) => {
      window.location.reload();
    });
  };

  useEffect(() => {
    fetch(BACKEND_URL + "/getArchive?employee_id=" + props.userId).then((res) => res.json().then(setData));
  }, [props.userId]);
  useEffect(() => {
    fetch(BACKEND_URL + "/getArchive?employee_id=" + props.userId).then((res) => res.json().then(setData));
  }, []);

  return (
    <Form.Row>
      <Col xs={12}>
        <h4>写真のアーカイブ</h4>
      </Col>
      <Col xs={12}>
        {data.map((data, i) => {
          return (
            <Form.Row style={{ borderBottom: "1px solid rgb(223,223,223)", padding: ".6em 0", lineHeight: "2.4" }}>
              <Col className="filename">
                <b>{data.path}</b>

                {data.data && <img style={{ display: "flex" }} src={data.data} alt="preview" width="200px"></img>}
              </Col>
              <Col>
                <Button className="archiveButton" style={{ marginRight: "1em" }} onClick={downloadFile}>
                  ダウンロード
                </Button>
                <Button className="archiveButton" style={{ marginRight: "1em" }} onClick={deleteFile}>
                  削除
                </Button>
              </Col>
            </Form.Row>
          );
        })}
      </Col>
    </Form.Row>
  );
}
