import React from "react";

import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import { matchSorter } from "match-sorter";
import { Row, Col, Button, Form } from "react-bootstrap";
import "./table.scss";
import { useHistory } from "react-router-dom";
import { useDoubleTap } from "use-double-tap";
import { isMobile } from "react-device-detect";

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="tableGlobalFilter">
      <div className="globalFilterContainer">
        <span>検索: </span>
        <input
          className="form-control"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default function Table({ columns, data, detailsCol, detailsOnClick, localization }) {
  const history = useHistory();

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const toggleSelectedClass = (event) => {
    let row = event.target.parentElement;

    let pS = document.querySelector(".tRowSelected");
    if (pS) pS.classList.remove("tRowSelected");

    row.classList.add("tRowSelected");
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setColumnOrder,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const changePage = (e) => {
    if (e.target.id == "pageUp") {
      gotoPage(nextPage);
    } else {
      gotoPage(previousPage);
    }

    // let tabPage = document.getElementById("tabPage").value;
    // let page = tabPage ? Number(tabPage) - 1 : 0;
  };

  // Render the UI for your table
  return (
    <Col style={{ overflow: "auto" }}>
      <table {...getTableProps()}>
        <thead>
          <tr>
            <th colSpan={visibleColumns.length}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                  <div>{column.canFilter && column.Header !== "Edit" ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                id={row.original.id ? row.original.id : row.original.user_id}
                name={row.original.name}
                username={row.original.username}
                agency_name={row.original.agency_name}
                agency_address={row.original.agency_address}
                active={row.original.active}
                onClick={toggleSelectedClass}
                className={row.original.active === 0 ? "deleted" : ""}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.column.Header === detailsCol ? (
                        <Button onClick={detailsOnClick}>もっと見る</Button>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={visibleColumns.length}>
              <Row style={{ paddingTop: ".6em" }}>
                <Col xs={12} md={4} className="tabNavigation">
                  <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                  </Button>{" "}
                  <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                  </Button>{" "}
                  <Button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                  </Button>{" "}
                  <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {">>"}
                  </Button>{" "}
                </Col>
                <Col xs={12} md={8}>
                  <Row>
                    <Col xs={12} md={6} className="pageIndexLabel">
                      <div>
                        ページ{" "}
                        <strong>
                          {pageIndex + 1} - {pageOptions.length}
                        </strong>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="pageFormLabel">
                      <div>
                        <div style={{ float: "left" }}>ページへ :</div>
                        <Form.Control
                          type="number"
                          // className="pageForm"
                          defaultValue={pageIndex + 1}
                          style={{ maxWidth: "50px" }}
                          onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col className="d-block d-sm-none" xs={12} /> */}
                <Col xs={{ span: 4 }} className="showCounter" sm={{ span: 3, offset: 9 }} md={{ span: 4, offset: 4 }}>
                  <Form.Control
                    as="select"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        ビュー {pageSize}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </td>
          </tr>
        </tfoot>
      </table>
    </Col>
  );
}
