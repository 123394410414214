import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
// import { maxlength } from "bootstrap-maxlength";
import Datetime from "react-datetime";

export const label = "03";

export function Cast_03(props) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div></div>

      {/* TUTTO ELIMINATO
      <Form.Row>
        <h2 id="h2_title">体験入店アンケート</h2>
      </Form.Row>
      <Form.Row>
        <Col md={{ offset: 8, span: 4 }}>
          <Datetime
            initialValue={props["date#date"]}
            inputProps={{ name: "date", id: "date", readOnly: "readonly" }}
            timeFormat={false}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_subtitle0"> ①店内環境について</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q1">更衣室は使いやすかったですか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q1Cast3#q1r1"]}
                required
                type="radio"
                value="A"
                name="q1Cast3"
                id="q1r1"
              />
              <Form.Label id="label_q1r1" htmlFor="q1r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q1Cast3#q1r2"]}
                required
                type="radio"
                value="B"
                name="q1Cast3"
                id="q1r2"
              />
              <Form.Label id="label_q1r2" htmlFor="q1r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q1Cast3#q1r3"]}
                required
                type="radio"
                value="C"
                name="q1Cast3"
                id="q1r3"
              />
              <Form.Label id="label_q1r3" htmlFor="q1r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q1Cast3#q1r4"]}
                required
                type="radio"
                value="D"
                name="q1Cast3"
                id="q1r4"
              />
              <Form.Label id="label_q1r4" htmlFor="q1r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q2">更衣室は清潔でしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q2Cast3#q2r1"]}
                required
                type="radio"
                value="A"
                name="q2Cast3"
                id="q2r1"
              />
              <Form.Label id="label_q2r1" htmlFor="q2r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q2Cast3#q2r2"]}
                required
                type="radio"
                value="B"
                name="q2Cast3"
                id="q2r2"
              />
              <Form.Label id="label_q2r2" htmlFor="q2r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q2Cast3#q2r3"]}
                required
                type="radio"
                value="C"
                name="q2Cast3"
                id="q2r3"
              />
              <Form.Label id="label_q2r3" htmlFor="q2r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q2Cast3#q2r4"]}
                required
                type="radio"
                value="D"
                name="q2Cast3"
                id="q2r4"
              />
              <Form.Label id="label_q2r4" htmlFor="q2r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q3">待機は過ごしやすかったですか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q3Cast3#q3r1"]}
                required
                type="radio"
                value="A"
                name="q3Cast3"
                id="q3r1"
              />
              <Form.Label id="label_q3r1" htmlFor="q3r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q3Cast3#q3r2"]}
                required
                type="radio"
                value="B"
                name="q3Cast3"
                id="q3r2"
              />
              <Form.Label id="label_q3r2" htmlFor="q3r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q3Cast3#q3r3"]}
                required
                type="radio"
                value="C"
                name="q3Cast3"
                id="q3r3"
              />
              <Form.Label id="label_q3r3" htmlFor="q3r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["q3Cast3#q3r4"]}
                required
                type="radio"
                value="D"
                name="q3Cast3"
                id="q3r4"
              />
              <Form.Label id="label_q3r4" htmlFor="q3r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q4">女性用トイレは清潔でしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4#q4r1"]} required type="radio" value="A" name="q4" id="q4r1" />
              <Form.Label id="label_q4r1" htmlFor="q4r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4#q4r2"]} required type="radio" value="B" name="q4" id="q4r2" />
              <Form.Label id="label_q4r2" htmlFor="q4r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4#q4r3"]} required type="radio" value="C" name="q4" id="q4r3" />
              <Form.Label id="label_q4r3" htmlFor="q4r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4#q4r4"]} required type="radio" value="D" name="q4" id="q4r4" />
              <Form.Label id="label_q4r4" htmlFor="q4r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q5">フロアは綺麗でしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q5#q5r1"]} required type="radio" value="A" name="q5" id="q5r1" />
              <Form.Label id="label_q5r1" htmlFor="q5r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q5#q5r2"]} required type="radio" value="B" name="q5" id="q5r2" />
              <Form.Label id="label_q5r2" htmlFor="q5r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q5#q5r3"]} required type="radio" value="C" name="q5" id="q5r3" />
              <Form.Label id="label_q5r3" htmlFor="q5r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q5#q5r4"]} required type="radio" value="D" name="q5" id="q5r4" />
              <Form.Label id="label_q5r4" htmlFor="q5r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_subtitle1">②スタッフの印象</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q1_2">スタッフの営業中の対応はどうでしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_2#q1_2r1"]} required type="radio" value="A" name="q1_2" id="q1_2r1" />
              <Form.Label id="label_q1_2r1" htmlFor="q1_2r1">
                とても良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_2#q1_2r2"]} required type="radio" value="B" name="q1_2" id="q1_2r2" />
              <Form.Label id="label_q1_2r2" htmlFor="q1_2r2">
                良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_2#q1_2r3"]} required type="radio" value="C" name="q1_2" id="q1_2r3" />
              <Form.Label id="label_q1_2r3" htmlFor="q1_2r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_2#q1_2r4"]} required type="radio" value="D" name="q1_2" id="q1_2r4" />
              <Form.Label id="label_q1_2r4" htmlFor="q1_2r4">
                良くない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q2_2">スタッフの営業中の対応はどうでしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_2#q2_2r1"]} required type="radio" value="A" name="q2_2" id="q2_2r1" />
              <Form.Label id="label_q2_2r1" htmlFor="q2_2r1">
                とても良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_2#q2_2r2"]} required type="radio" value="B" name="q2_2" id="q2_2r2" />
              <Form.Label id="label_q2_2r2" htmlFor="q2_2r2">
                良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_2#q2_2r3"]} required type="radio" value="C" name="q2_2" id="q2_2r3" />
              <Form.Label id="label_q2_2r3" htmlFor="q2_2r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_2#q2_2r4"]} required type="radio" value="D" name="q2_2" id="q2_2r4" />
              <Form.Label id="label_q2_2r4" htmlFor="q2_2r4">
                良くない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q3_2">気遣いは出来ていましたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_2#q3_2r1"]} required type="radio" value="A" name="q3_2" id="q3_2r1" />
              <Form.Label id="label_q3_2r1" htmlFor="q3_2r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_2#q3_2r2"]} required type="radio" value="B" name="q3_2" id="q3_2r2" />
              <Form.Label id="label_q3_2r2" htmlFor="q3_2r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_2#q3_2r3"]} required type="radio" value="C" name="q3_2" id="q3_2r3" />
              <Form.Label id="label_q3_2r3" htmlFor="q3_2r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_2#q3_2r4"]} required type="radio" value="D" name="q3_2" id="q3_2r4" />
              <Form.Label id="label_q3_2r4" htmlFor="q3_2r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q4_2">スタッフの身だしなみはどうでしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4_2#q4_2r1"]} required type="radio" value="A" name="q4_2" id="q4_2r1" />
              <Form.Label id="label_q4_2r1" htmlFor="q4_2r1">
                とても良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4_2#q4_2r2"]} required type="radio" value="B" name="q4_2" id="q4_2r2" />
              <Form.Label id="label_q4_2r2" htmlFor="q4_2r2">
                良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4_2#q4_2r3"]} required type="radio" value="C" name="q4_2" id="q4_2r3" />
              <Form.Label id="label_q4_2r3" htmlFor="q4_2r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4_2#q4_2r4"]} required type="radio" value="D" name="q4_2" id="q4_2r4" />
              <Form.Label id="label_q4_2r4" htmlFor="q4_2r4">
                良くない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q5_2">嫌な部分はありましたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q5_2#q5_2r1"]} required type="radio" value="A" name="q5_2" id="q5_2r1" />
              <Form.Label id="label_q5_2r1" htmlFor="q5_2r1">
                あった
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q5_2#q5_2r2"]} required type="radio" value="B" name="q5_2" id="q5_2r2" />
              <Form.Label id="label_q5_2r2" htmlFor="q5_2r2">
                無かった
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_subtitle2">③キャストの印象</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q1_3">接客中の雰囲気はどうでしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_3#q1_3r1"]} required type="radio" value="A" name="q1_3" id="q1_3r1" />
              <Form.Label id="label_q1_3r1" htmlFor="q1_3r1">
                とても良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_3#q1_3r2"]} required type="radio" value="B" name="q1_3" id="q1_3r2" />
              <Form.Label id="label_q1_3r2" htmlFor="q1_3r2">
                良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_3#q1_3r3"]} required type="radio" value="C" name="q1_3" id="q1_3r3" />
              <Form.Label id="label_q1_3r3" htmlFor="q1_3r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_3#q1_3r4"]} required type="radio" value="D" name="q1_3" id="q1_3r4" />
              <Form.Label id="label_q1_3r4" htmlFor="q1_3r4">
                良くない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q2_3">待機中の雰囲気はどうでしかた？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_3#q2_3r1"]} required type="radio" value="A" name="q2_3" id="q2_3r1" />
              <Form.Label id="label_q2_3r1" htmlFor="q2_3r1">
                とても良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_3#q2_3r2"]} required type="radio" value="B" name="q2_3" id="q2_3r2" />
              <Form.Label id="label_q2_3r2" htmlFor="q2_3r2">
                良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_3#q2_3r3"]} required type="radio" value="C" name="q2_3" id="q2_3r3" />
              <Form.Label id="label_q2_3r3" htmlFor="q2_3r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_3#q2_3r4"]} required type="radio" value="D" name="q2_3" id="q2_3r4" />
              <Form.Label id="label_q2_3r4" htmlFor="q2_3r4">
                良くない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q3_3">仲良くなれたキャストはいましたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_3#q3_3r1"]} required type="radio" value="A" name="q3_3" id="q3_3r1" />
              <Form.Label id="label_q3_3r1" htmlFor="q3_3r1">
                いた
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_3#q3_3r2"]} required type="radio" value="B" name="q3_3" id="q3_3r2" />
              <Form.Label id="label_q3_3r2" htmlFor="q3_3r2">
                いなかった
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q4_3">苦手なタイプのキャストはいましたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4_3#q4_3r1"]} required type="radio" value="A" name="q4_3" id="q4_3r1" />
              <Form.Label id="label_q4_3r1" htmlFor="q4_3r1">
                いた
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q4_3#q4_3r2"]} required type="radio" value="B" name="q4_3" id="q4_3r2" />
              <Form.Label id="label_q4_3r2" htmlFor="q4_3r2">
                いなかった
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_subtitle3">④お客様について</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q1_4">客層はどうでしたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_4#q1_4r1"]} required type="radio" value="A" name="q1_4" id="q1_4r1" />
              <Form.Label id="label_q1_4r1" htmlFor="q1_4r1">
                とても良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_4#q1_4r2"]} required type="radio" value="B" name="q1_4" id="q1_4r2" />
              <Form.Label id="label_q1_4r2" htmlFor="q1_4r2">
                良い
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_4#q1_4r3"]} required type="radio" value="C" name="q1_4" id="q1_4r3" />
              <Form.Label id="label_q1_4r3" htmlFor="q1_4r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_4#q1_4r4"]} required type="radio" value="D" name="q1_4" id="q1_4r4" />
              <Form.Label id="label_q1_4r4" htmlFor="q1_4r4">
                良くない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q2_4">接客はしやすかったですか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_4#q2_4r1"]} required type="radio" value="A" name="q2_4" id="q2_4r1" />
              <Form.Label id="label_q2_4r1" htmlFor="q2_4r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_4#q2_4r2"]} required type="radio" value="B" name="q2_4" id="q2_4r2" />
              <Form.Label id="label_q2_4r2" htmlFor="q2_4r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_4#q2_4r3"]} required type="radio" value="C" name="q2_4" id="q2_4r3" />
              <Form.Label id="label_q2_4r3" htmlFor="q2_4r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q2_4#q2_4r4"]} required type="radio" value="D" name="q2_4" id="q2_4r4" />
              <Form.Label id="label_q2_4r4" htmlFor="q2_4r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q3_4">対応しづらいお客様はいましたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_4#q3_4r1"]} required type="radio" value="A" name="q3_4" id="q3_4r1" />
              <Form.Label id="label_q3_4r1" htmlFor="q3_4r1">
                いた
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q3_4#q3_4r2"]} required type="radio" value="B" name="q3_4" id="q3_4r2" />
              <Form.Label id="label_q3_4r2" htmlFor="q3_4r2">
                いなかった
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <h4 id="h4_subtitle4">⑤その他</h4>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_q1_5">給料システムは理解できましたか？</Form.Label>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_5#q1_5r1"]} required type="radio" value="A" name="q1_5" id="q1_5r1" />
              <Form.Label id="label_q1_5r1" htmlFor="q1_5r1">
                とてもそう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_5#q1_5r2"]} required type="radio" value="B" name="q1_5" id="q1_5r2" />
              <Form.Label id="label_q1_5r2" htmlFor="q1_5r2">
                そう思う
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_5#q1_5r3"]} required type="radio" value="C" name="q1_5" id="q1_5r3" />
              <Form.Label id="label_q1_5r3" htmlFor="q1_5r3">
                普通
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check inline defaultChecked={props["q1_5#q1_5r4"]} required type="radio" value="D" name="q1_5" id="q1_5r4" />
              <Form.Label id="label_q1_5r4" htmlFor="q1_5r4">
                そう思わない
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>

*/}
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <Cast_03 {...props}></Cast_03>;
component[label + "Template"] = {
  data: {
    h2_title: {
      page: 0,
      normalized: {
        x: 0,
        y: 0.12711618114412654,
      },
      wraps: false,
      x: 40,
      y: 801.76,
    },
    label_date: {
      page: 0,
      normalized: {
        x: 345.8410810810811,
        y: 45.58628381885599,
      },
      wraps: false,
      x: 385.84,
      y: 756.3,
    },
    date: {
      page: 0,
      normalized: {
        x: 345.8410810810811,
        y: 66.63107380826277,
      },
      wraps: false,
      x: 385.84,
      y: 735.26,
      width: 167.11058445945946,
      height: 27.632636520127118,
    },
    "": {
      page: 1,
      normalized: {
        x: -118.83935135135135,
        y: 117.77011525423735,
      },
      wraps: false,
      x: -78.84,
      y: 684.12,
    },
    h4_subtitle0: {
      page: 0,
      normalized: {
        x: 0,
        y: 94.26371032838989,
      },
      wraps: false,
      x: 40,
      y: 707.63,
    },
    label_q1: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 143.5968949152543,
      },
      wraps: false,
      x: 42.32,
      y: 658.29,
    },
    q1r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 164.64168490466108,
      },
      wraps: false,
      x: 44.64,
      y: 637.25,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 159.7386322033899,
      },
      wraps: false,
      x: 55.32,
      y: 642.15,
    },
    q1r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 164.64168490466108,
      },
      wraps: false,
      x: 214.85,
      y: 637.25,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 159.7386322033899,
      },
      wraps: false,
      x: 225.53,
      y: 642.15,
    },
    q1r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 164.64168490466108,
      },
      wraps: false,
      x: 385.06,
      y: 637.25,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 159.7386322033899,
      },
      wraps: false,
      x: 395.73,
      y: 642.15,
    },
    q1r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 180.78342219279665,
      },
      wraps: false,
      x: 44.64,
      y: 621.11,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 175.88036949152547,
      },
      wraps: false,
      x: 55.32,
      y: 626.01,
    },
    label_q2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 201.70714915254246,
      },
      wraps: false,
      x: 42.32,
      y: 600.18,
    },
    q2r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 222.7519391419492,
      },
      wraps: false,
      x: 44.64,
      y: 579.14,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 217.84888644067803,
      },
      wraps: false,
      x: 55.32,
      y: 584.04,
    },
    q2r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 222.7519391419492,
      },
      wraps: false,
      x: 214.85,
      y: 579.14,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 217.84888644067803,
      },
      wraps: false,
      x: 225.53,
      y: 584.04,
    },
    q2r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 222.7519391419492,
      },
      wraps: false,
      x: 385.06,
      y: 579.14,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 217.84888644067803,
      },
      wraps: false,
      x: 395.73,
      y: 584.04,
    },
    q2r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 238.8936764300848,
      },
      wraps: false,
      x: 44.64,
      y: 563,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 233.99062372881363,
      },
      wraps: false,
      x: 55.32,
      y: 567.9,
    },
    label_q3: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 259.81740338983053,
      },
      wraps: false,
      x: 42.32,
      y: 542.07,
    },
    q3r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 280.86219337923734,
      },
      wraps: false,
      x: 44.64,
      y: 521.03,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 275.95914067796616,
      },
      wraps: false,
      x: 55.32,
      y: 525.93,
    },
    q3r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 280.86219337923734,
      },
      wraps: false,
      x: 214.85,
      y: 521.03,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 275.95914067796616,
      },
      wraps: false,
      x: 225.53,
      y: 525.93,
    },
    q3r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 280.86219337923734,
      },
      wraps: false,
      x: 385.06,
      y: 521.03,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 275.95914067796616,
      },
      wraps: false,
      x: 395.73,
      y: 525.93,
    },
    q3r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 297.00393066737297,
      },
      wraps: false,
      x: 44.64,
      y: 504.89,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 292.10087796610173,
      },
      wraps: false,
      x: 55.32,
      y: 509.79,
    },
    label_q4: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 317.9276576271187,
      },
      wraps: false,
      x: 42.32,
      y: 483.96,
    },
    q4r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 338.97244761652547,
      },
      wraps: false,
      x: 44.64,
      y: 462.92,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 334.0693949152543,
      },
      wraps: false,
      x: 55.32,
      y: 467.82,
    },
    q4r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 338.97244761652547,
      },
      wraps: false,
      x: 214.85,
      y: 462.92,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 334.0693949152543,
      },
      wraps: false,
      x: 225.53,
      y: 467.82,
    },
    q4r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 338.97244761652547,
      },
      wraps: false,
      x: 385.06,
      y: 462.92,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 334.0693949152543,
      },
      wraps: false,
      x: 395.73,
      y: 467.82,
    },
    q4r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 355.11418490466104,
      },
      wraps: false,
      x: 44.64,
      y: 446.78,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 350.21113220338987,
      },
      wraps: false,
      x: 55.32,
      y: 451.68,
    },
    label_q5: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 376.03791186440685,
      },
      wraps: false,
      x: 42.32,
      y: 425.85,
    },
    q5r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 397.0827018538136,
      },
      wraps: false,
      x: 44.64,
      y: 404.81,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q5r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 392.1796491525424,
      },
      wraps: false,
      x: 55.32,
      y: 409.71,
    },
    q5r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 397.0827018538136,
      },
      wraps: false,
      x: 214.85,
      y: 404.81,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q5r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 392.1796491525424,
      },
      wraps: false,
      x: 225.53,
      y: 409.71,
    },
    q5r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 397.0827018538136,
      },
      wraps: false,
      x: 385.06,
      y: 404.81,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q5r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 392.1796491525424,
      },
      wraps: false,
      x: 395.73,
      y: 409.71,
    },
    q5r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 413.2244391419492,
      },
      wraps: false,
      x: 44.64,
      y: 388.67,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q5r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 408.32138644067805,
      },
      wraps: false,
      x: 55.32,
      y: 393.57,
    },
    h4_subtitle1: {
      page: 0,
      normalized: {
        x: 0,
        y: 439.6968882944916,
      },
      wraps: false,
      x: 40,
      y: 362.19,
    },
    label_q1_2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 489.030072881356,
      },
      wraps: false,
      x: 42.32,
      y: 312.86,
    },
    q1_2r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 510.07486287076273,
      },
      wraps: false,
      x: 44.64,
      y: 291.82,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_2r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 505.1718101694916,
      },
      wraps: false,
      x: 55.32,
      y: 296.72,
    },
    q1_2r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 510.07486287076273,
      },
      wraps: false,
      x: 214.85,
      y: 291.82,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_2r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 505.1718101694916,
      },
      wraps: false,
      x: 225.53,
      y: 296.72,
    },
    q1_2r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 510.07486287076273,
      },
      wraps: false,
      x: 385.06,
      y: 291.82,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_2r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 505.1718101694916,
      },
      wraps: false,
      x: 395.73,
      y: 296.72,
    },
    q1_2r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 526.2166001588984,
      },
      wraps: false,
      x: 44.64,
      y: 275.67,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_2r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 521.3135474576271,
      },
      wraps: false,
      x: 55.32,
      y: 280.58,
    },
    label_q2_2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 547.1403271186441,
      },
      wraps: false,
      x: 42.32,
      y: 254.75,
    },
    q2_2r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 568.1851171080509,
      },
      wraps: false,
      x: 44.64,
      y: 233.7,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_2r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 563.2820644067797,
      },
      wraps: false,
      x: 55.32,
      y: 238.61,
    },
    q2_2r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 568.1851171080509,
      },
      wraps: false,
      x: 214.85,
      y: 233.7,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_2r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 563.2820644067797,
      },
      wraps: false,
      x: 225.53,
      y: 238.61,
    },
    q2_2r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 568.1851171080509,
      },
      wraps: false,
      x: 385.06,
      y: 233.7,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_2r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 563.2820644067797,
      },
      wraps: false,
      x: 395.73,
      y: 238.61,
    },
    q2_2r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 584.3268543961865,
      },
      wraps: false,
      x: 44.64,
      y: 217.56,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_2r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 579.4238016949153,
      },
      wraps: false,
      x: 55.32,
      y: 222.47,
    },
    label_q3_2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 605.2505813559322,
      },
      wraps: false,
      x: 42.32,
      y: 196.64,
    },
    q3_2r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 626.295371345339,
      },
      wraps: false,
      x: 44.64,
      y: 175.59,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_2r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 621.3923186440679,
      },
      wraps: false,
      x: 55.32,
      y: 180.5,
    },
    q3_2r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 626.295371345339,
      },
      wraps: false,
      x: 214.85,
      y: 175.59,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_2r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 621.3923186440679,
      },
      wraps: false,
      x: 225.53,
      y: 180.5,
    },
    q3_2r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 626.295371345339,
      },
      wraps: false,
      x: 385.06,
      y: 175.59,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_2r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 621.3923186440679,
      },
      wraps: false,
      x: 395.73,
      y: 180.5,
    },
    q3_2r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 642.4371086334746,
      },
      wraps: false,
      x: 44.64,
      y: 159.45,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_2r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 637.5340559322034,
      },
      wraps: false,
      x: 55.32,
      y: 164.36,
    },
    label_q4_2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 663.3608355932204,
      },
      wraps: false,
      x: 42.32,
      y: 138.53,
    },
    q4_2r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 684.4056255826272,
      },
      wraps: false,
      x: 44.64,
      y: 117.48,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4_2r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 679.502572881356,
      },
      wraps: false,
      x: 55.32,
      y: 122.39,
    },
    q4_2r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 684.4056255826272,
      },
      wraps: false,
      x: 214.85,
      y: 117.48,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4_2r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 679.502572881356,
      },
      wraps: false,
      x: 225.53,
      y: 122.39,
    },
    q4_2r3: {
      page: 0,
      normalized: {
        x: 345.0577162162162,
        y: 684.4056255826272,
      },
      wraps: false,
      x: 385.06,
      y: 117.48,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4_2r3: {
      page: 0,
      normalized: {
        x: 355.73468918918917,
        y: 679.502572881356,
      },
      wraps: false,
      x: 395.73,
      y: 122.39,
    },
    q4_2r4: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 700.5473628707628,
      },
      wraps: false,
      x: 44.64,
      y: 101.34,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4_2r4: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 695.6443101694916,
      },
      wraps: false,
      x: 55.32,
      y: 106.25,
    },
    label_q5_2: {
      page: 0,
      normalized: {
        x: 2.321081081081081,
        y: 721.4710898305085,
      },
      wraps: false,
      x: 42.32,
      y: 80.42,
    },
    q5_2r1: {
      page: 0,
      normalized: {
        x: 4.642162162162162,
        y: 742.5158798199153,
      },
      wraps: false,
      x: 44.64,
      y: 59.37,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q5_2r1: {
      page: 0,
      normalized: {
        x: 15.319135135135134,
        y: 737.6128271186441,
      },
      wraps: false,
      x: 55.32,
      y: 64.28,
    },
    q5_2r2: {
      page: 0,
      normalized: {
        x: 174.84993918918917,
        y: 742.5158798199153,
      },
      wraps: false,
      x: 214.85,
      y: 59.37,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q5_2r2: {
      page: 0,
      normalized: {
        x: 185.52691216216215,
        y: 737.6128271186441,
      },
      wraps: false,
      x: 225.53,
      y: 64.28,
    },
    h4_subtitle2: {
      page: 1,
      normalized: {
        x: 0,
        y: 7.0983289724576855,
      },
      wraps: false,
      x: 40,
      y: 794.79,
    },
    label_q1_3: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 56.43151355932209,
      },
      wraps: false,
      x: 42.32,
      y: 745.46,
    },
    q1_3r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 77.47630354872888,
      },
      wraps: false,
      x: 44.64,
      y: 724.41,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_3r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 72.57325084745769,
      },
      wraps: false,
      x: 55.32,
      y: 729.32,
    },
    q1_3r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 77.47630354872888,
      },
      wraps: false,
      x: 214.85,
      y: 724.41,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_3r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 72.57325084745769,
      },
      wraps: false,
      x: 225.53,
      y: 729.32,
    },
    q1_3r3: {
      page: 1,
      normalized: {
        x: 345.0577162162162,
        y: 77.47630354872888,
      },
      wraps: false,
      x: 385.06,
      y: 724.41,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_3r3: {
      page: 1,
      normalized: {
        x: 355.73468918918917,
        y: 72.57325084745769,
      },
      wraps: false,
      x: 395.73,
      y: 729.32,
    },
    q1_3r4: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 93.61804083686447,
      },
      wraps: false,
      x: 44.64,
      y: 708.27,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_3r4: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 88.71498813559327,
      },
      wraps: false,
      x: 55.32,
      y: 713.18,
    },
    label_q2_3: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 114.54176779661022,
      },
      wraps: false,
      x: 42.32,
      y: 687.35,
    },
    q2_3r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 135.586557786017,
      },
      wraps: false,
      x: 44.64,
      y: 666.3,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_3r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 130.6835050847458,
      },
      wraps: false,
      x: 55.32,
      y: 671.21,
    },
    q2_3r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 135.586557786017,
      },
      wraps: false,
      x: 214.85,
      y: 666.3,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_3r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 130.6835050847458,
      },
      wraps: false,
      x: 225.53,
      y: 671.21,
    },
    q2_3r3: {
      page: 1,
      normalized: {
        x: 345.0577162162162,
        y: 135.586557786017,
      },
      wraps: false,
      x: 385.06,
      y: 666.3,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_3r3: {
      page: 1,
      normalized: {
        x: 355.73468918918917,
        y: 130.6835050847458,
      },
      wraps: false,
      x: 395.73,
      y: 671.21,
    },
    q2_3r4: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 151.7282950741526,
      },
      wraps: false,
      x: 44.64,
      y: 650.16,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_3r4: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 146.8252423728814,
      },
      wraps: false,
      x: 55.32,
      y: 655.06,
    },
    label_q3_3: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 172.65202203389836,
      },
      wraps: false,
      x: 42.32,
      y: 629.24,
    },
    q3_3r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 193.69681202330514,
      },
      wraps: false,
      x: 44.64,
      y: 608.19,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_3r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 188.79375932203396,
      },
      wraps: false,
      x: 55.32,
      y: 613.1,
    },
    q3_3r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 193.69681202330514,
      },
      wraps: false,
      x: 214.85,
      y: 608.19,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_3r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 188.79375932203396,
      },
      wraps: false,
      x: 225.53,
      y: 613.1,
    },
    label_q4_3: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 214.6205389830509,
      },
      wraps: false,
      x: 42.32,
      y: 587.27,
    },
    q4_3r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 235.6653289724577,
      },
      wraps: false,
      x: 44.64,
      y: 566.22,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4_3r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 230.76227627118647,
      },
      wraps: false,
      x: 55.32,
      y: 571.13,
    },
    q4_3r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 235.6653289724577,
      },
      wraps: false,
      x: 214.85,
      y: 566.22,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q4_3r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 230.76227627118647,
      },
      wraps: false,
      x: 225.53,
      y: 571.13,
    },
    h4_subtitle3: {
      page: 1,
      normalized: {
        x: 0,
        y: 262.13777812500007,
      },
      wraps: false,
      x: 40,
      y: 539.75,
    },
    label_q1_4: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 311.47096271186444,
      },
      wraps: false,
      x: 42.32,
      y: 490.42,
    },
    q1_4r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 332.51575270127125,
      },
      wraps: false,
      x: 44.64,
      y: 469.37,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_4r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 327.6127000000001,
      },
      wraps: false,
      x: 55.32,
      y: 474.28,
    },
    q1_4r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 332.51575270127125,
      },
      wraps: false,
      x: 214.85,
      y: 469.37,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_4r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 327.6127000000001,
      },
      wraps: false,
      x: 225.53,
      y: 474.28,
    },
    q1_4r3: {
      page: 1,
      normalized: {
        x: 345.0577162162162,
        y: 332.51575270127125,
      },
      wraps: false,
      x: 385.06,
      y: 469.37,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_4r3: {
      page: 1,
      normalized: {
        x: 355.73468918918917,
        y: 327.6127000000001,
      },
      wraps: false,
      x: 395.73,
      y: 474.28,
    },
    q1_4r4: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 348.6574899894068,
      },
      wraps: false,
      x: 44.64,
      y: 453.23,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_4r4: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 343.75443728813565,
      },
      wraps: false,
      x: 55.32,
      y: 458.14,
    },
    label_q2_4: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 369.5812169491526,
      },
      wraps: false,
      x: 42.32,
      y: 432.31,
    },
    q2_4r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 390.6260069385594,
      },
      wraps: false,
      x: 44.64,
      y: 411.26,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_4r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 385.7229542372882,
      },
      wraps: false,
      x: 55.32,
      y: 416.17,
    },
    q2_4r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 390.6260069385594,
      },
      wraps: false,
      x: 214.85,
      y: 411.26,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_4r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 385.7229542372882,
      },
      wraps: false,
      x: 225.53,
      y: 416.17,
    },
    q2_4r3: {
      page: 1,
      normalized: {
        x: 345.0577162162162,
        y: 390.6260069385594,
      },
      wraps: false,
      x: 385.06,
      y: 411.26,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_4r3: {
      page: 1,
      normalized: {
        x: 355.73468918918917,
        y: 385.7229542372882,
      },
      wraps: false,
      x: 395.73,
      y: 416.17,
    },
    q2_4r4: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 406.76774422669496,
      },
      wraps: false,
      x: 44.64,
      y: 395.12,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q2_4r4: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 401.86469152542384,
      },
      wraps: false,
      x: 55.32,
      y: 400.03,
    },
    label_q3_4: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 427.6914711864407,
      },
      wraps: false,
      x: 42.32,
      y: 374.2,
    },
    q3_4r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 448.7362611758476,
      },
      wraps: false,
      x: 44.64,
      y: 353.15,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_4r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 443.83320847457634,
      },
      wraps: false,
      x: 55.32,
      y: 358.06,
    },
    q3_4r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 448.7362611758476,
      },
      wraps: false,
      x: 214.85,
      y: 353.15,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q3_4r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 443.83320847457634,
      },
      wraps: false,
      x: 225.53,
      y: 358.06,
    },
    h4_subtitle4: {
      page: 1,
      normalized: {
        x: 0,
        y: 475.2087103283899,
      },
      wraps: false,
      x: 40,
      y: 326.68,
    },
    label_q1_5: {
      page: 1,
      normalized: {
        x: 2.321081081081081,
        y: 524.5418949152543,
      },
      wraps: false,
      x: 42.32,
      y: 277.35,
    },
    q1_5r1: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 545.5866849046611,
      },
      wraps: false,
      x: 44.64,
      y: 256.3,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_5r1: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 540.6836322033898,
      },
      wraps: false,
      x: 55.32,
      y: 261.21,
    },
    q1_5r2: {
      page: 1,
      normalized: {
        x: 174.84993918918917,
        y: 545.5866849046611,
      },
      wraps: false,
      x: 214.85,
      y: 256.3,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_5r2: {
      page: 1,
      normalized: {
        x: 185.52691216216215,
        y: 540.6836322033898,
      },
      wraps: false,
      x: 225.53,
      y: 261.21,
    },
    q1_5r3: {
      page: 1,
      normalized: {
        x: 345.0577162162162,
        y: 545.5866849046611,
      },
      wraps: false,
      x: 385.06,
      y: 256.3,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_5r3: {
      page: 1,
      normalized: {
        x: 355.73468918918917,
        y: 540.6836322033898,
      },
      wraps: false,
      x: 395.73,
      y: 261.21,
    },
    q1_5r4: {
      page: 1,
      normalized: {
        x: 4.642162162162162,
        y: 561.7284221927966,
      },
      wraps: false,
      x: 44.64,
      y: 240.16,
      width: 8.355891891891892,
      height: 11.622050847457627,
    },
    label_q1_5r4: {
      page: 1,
      normalized: {
        x: 15.319135135135134,
        y: 556.8253694915254,
      },
      wraps: false,
      x: 55.32,
      y: 245.06,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: {
      type: "RGB",
      red: 1,
      green: 0,
      blue: 0,
    },
    pixelBreakPage: 1180,
  },
};

component[label + "PageName"] = "体験入店アンケート";

export default component;
