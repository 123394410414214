import C1 from "./Driver_01";
import C5 from "./Driver_05";
import C6 from "./Driver_06";

const DriverComponents = {
  ...C1,
  ...C5,
  ...C6,
};

export default DriverComponents;
