import React from "react";
import { Form, Col } from "react-bootstrap";

export default function Retired(props) {
  return (
    <Form.Row>
      <Col xs={12}>
        <Form.Label id="label_retired_status" htmlFor="retired_status">
          引退
        </Form.Label>
        <Form.Group>
          <Form.Control id="retired_status" name="retired_status" as="select">
            <option value="在職中" selected={props["retired_status#retired_status"] === "在職中"}>
              在職中
            </option>
            <option value="退職済" selected={props["retired_status#retired_status"] === "退職済"}>
              退職済
            </option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Form.Row>
  );
}
