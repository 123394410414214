import { rgb } from "pdf-lib";
import fontRegular from "../font/YuGothR.ttf";
import fontBold from "../font/YuGothB.ttf";
import HTML2Pdf from "./htmlToPdf";

export function objToURLEncoded(obj) {
  return Object.keys(obj)
    .map((key) => {
      let val = obj[key];
      if (typeof val === "object") val = objToURLEncoded(val);
      return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, "_"))}`;
    })
    .join("&");
  // var formBody = [];
  // for (var property in obj) {
  //   var encodedKey = encodeURIComponent(property);
  //   var encodedValue = encodeURIComponent(obj[property]);
  //   if (recursive && isObject(obj[property])) {
  //     encodedValue = objToURLEncoded(obj[property], true);
  //   }
  //   console.log("pushing ", encodedKey, ": ", encodedValue);
  //   formBody.push(encodedKey + "=" + encodedValue);
  // }
  // return formBody.join("&");
}

// function isObject(obj) {
//   return obj === Object(obj);
// }

//!!IMPORTANT: If u change this all defaultValues mappings in the components must change as well
export function getFormMapId(element) {
  return element.name + "#" + element.id;
}

export function toTemplate(root, format, pixelBreakpage = 1500) {
  const rootPos = root.getBoundingClientRect();
  const documentPos = document.body.getBoundingClientRect();
  let template = {};
  template["data"] = {};

  let leafs = root.querySelectorAll("h1, h2, h3, h4, h5, h6, label, span:not(.rdtBtn), input, textarea, select");

  const pdfMargin = 40;
  const pdfWidth = format[0] - pdfMargin * 2;
  const pdfHeight = format[1] - pdfMargin * 2;
  const pixelBreakPage = pixelBreakpage;
  // const pixelBreakPage = 1500; // allunga(<)/restringe(>) altezza
  let offsetTop = 0;
  let page = 0;

  template["options"] = {
    rootId: root.id,
    format: format,
    margin: pdfMargin,
    width: pdfWidth,
    height: pdfHeight,
    inputColor: rgb(1, 0, 0),
    pixelBreakPage: pixelBreakPage,
  };

  const firstChild = root.firstChild;
  if (firstChild.id === "stepper") {
    const style = firstChild.currentStyle || window.getComputedStyle(firstChild),
      height = firstChild.offsetHeight, // or use style.width
      margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom),
      padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom),
      border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

    const totalHeight = height + margin + padding + border;

    // console.log(height, margin, padding, border);

    let pos = firstChild.getBoundingClientRect().y.toFixed(1) - documentPos.y.toFixed(1);
    offsetTop = pos + totalHeight;
  }

  //console.log(+offsetTop.toFixed(1));

  leafs.forEach((element) => {
    let elementPos = element.getBoundingClientRect();
    let relativePos = {};
    let templateElement = {};
    let normalizedElement = {};

    relativePos.x = elementPos.x - rootPos.x;
    relativePos.y = elementPos.y - documentPos.y - offsetTop;

    normalizedElement.x = (relativePos.x / rootPos.width) * pdfWidth;
    normalizedElement.y = (relativePos.y / pixelBreakPage) * pdfHeight;

    // console.log(normalizedElement.y / pdfHeight);
    templateElement.page = Math.floor(Math.abs(normalizedElement.y / pdfHeight));
    if (relativePos.y / pixelBreakPage >= 1) {
      normalizedElement.y = ((relativePos.y - pixelBreakPage * templateElement.page) / pixelBreakPage) * pdfHeight;
    }

    // templateElement.element = element;
    // templateElement.pos = elementPos;
    templateElement.normalized = { x: normalizedElement.x, y: normalizedElement.y };
    // templateElement.relative = { x: relativePos.x, y: relativePos.y };
    // templateElement.rootPos = rootPos;

    templateElement.wraps = false;
    templateElement.x = normalizedElement.x + pdfMargin;
    templateElement.y = pdfHeight - Math.abs(normalizedElement.y - pdfHeight * page) + pdfMargin;

    templateElement.x = parseFloat(templateElement.x.toFixed(2));
    templateElement.y = parseFloat(templateElement.y.toFixed(2));

    // const { lineHeight } = getComputedStyle(element);
    // const lineHeightParsed = parseInt(lineHeight.split("px")[0]);
    // const amountOfLinesTilAdjust = 2;

    // if (element.offsetHeight >= lineHeightParsed * amountOfLinesTilAdjust) {
    //   console.log(element, " should wrap!");
    // }

    let rects = element.getClientRects();

    if (rects.length > 1) {
      templateElement.wraps = true;
      templateElement.lines = rects.length;
    }

    //console.log(element, rects);

    // templateElement.addOffstet =

    if (element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "SELECT") {
      templateElement.width = (elementPos.width / rootPos.width) * pdfWidth;
      templateElement.height = (elementPos.height / pixelBreakPage) * pdfHeight;
    }

    //console.log(templateElement);

    template["data"][element.id] = templateElement;
  });

  return template;
}

function getLeaves(element, result) {
  let childs = element.children;

  for (let i = 0; i < childs.length; i++) {
    if (childs[i].children.length === 0) {
      result.push(childs[i]);
    } else {
      getLeaves(childs[i], result);
    }
  }

  return result;
}

export function dbDataToValMap(data) {
  let valmap = {};

  data.forEach((element) => {
    valmap[element.step] = JSON.parse(element.data);
  });

  return valmap;
}

export function toKanjiDate(date) {
  let parts = date.split("/");
  return parts[0] + "年" + parts[1] + "月" + parts[2] + "日";
}

export async function checkFontCache() {
  let regularStatus = await caches.match(fontRegular);
  let boldStatus = await caches.match(fontBold);

  if (!regularStatus || !boldStatus) {
    alert("フオントはまだダウンロード出来ていないのでしばらくたってからまたPDFダウンロードしてみてください！");
    caches.open("fonts").then((cache) => {
      cache.match(fontRegular).then((res) => {
        if (!res) cache.add(fontRegular);
      });
      cache.match(fontBold).then((res) => {
        if (!res) cache.add(fontBold);
      });
    });
    return false;
  }

  return true;
}

export function createPdf(precreate, rootId, template, props, options, signData, name, pageName) {
  checkFontCache().then((res) => {
    if (res) {
      precreate.call();

      new HTML2Pdf(document.getElementById(rootId), template, props, options, signData, name, pageName);
    }
  });
}
