import React from "react";
import Signature from "../../Signature/Signature";
import { toTemplate } from "../../../core/Utils";
import { Col, Table, Form } from "react-bootstrap";

import Datetime from "react-datetime";

export const label = "bd";

export function BD(props) {
  return (
    <React.Fragment>
      <Form.Row>
        <h2 id="h2_title">銀行振込承諾書</h2>
      </Form.Row>
      {/* <Form.Row>
        <Col id="date1" style={{ textAlign: "right" }} md={{ span: 4, offset: 8 }}>
          令和
          <Form.Group>
            <Datetime initialValue={props["date#date"]} inputProps={{ name: "date", id: "date" }} timeFormat={false} />
          </Form.Group>
        </Col>
      </Form.Row> */}
      <Form.Row>
        <Col id="label_interviewee" htmlFor="interviewee" style={{ textAlign: "right" }} md={{ span: 4, offset: 8 }}>
          <Form.Label id="label_interviewee" htmlFor="interviewee">
            源氏名
          </Form.Label>

          <Form.Group>
            <Form.Control
              defaultValue={props["interviewee#interviewee"]}
              name="interviewee"
              id="interviewee"
              placeholder=""
              type="text"
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Label id="subtitle1">業務委託報酬は以下の銀行振込口座に振り込んでくださいますようお願いいたします。</Form.Label>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="label_bankNum" htmlFor="bankNum">
            銀行名 (銀行番号)
          </Form.Label>
          <Form.Group>
            <Form.Control defaultValue={props["bankNum#bankNum"]} name="bankNum" id="bankNum" placeholder="" type="text" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Label id="label_bankBranch" htmlFor="bankBranch">
            支店名 (支店番号)
          </Form.Label>
          <Form.Group>
            <Form.Control defaultValue={props["bankBranch#bankBranch"]} name="bankBranch" id="bankBranch" type="text" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group as={Form.Row}>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={!props["account_type#ordinary"] ? true : props["account_type#current"]}
                required
                type="radio"
                value="current"
                name="account_type"
                id="current"
              />
              <Form.Label id="label_ordinary" htmlFor="current">
                普通預金
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Check
                inline
                defaultChecked={props["account_type#ordinary"]}
                required
                type="radio"
                value="ordinary"
                name="account_type"
                id="ordinary"
              />
              <Form.Label id="label_current" htmlFor="ordinary">
                当座預金
              </Form.Label>
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_cardNumber" htmlFor="cardNumber">
            口座番号
          </Form.Label>
          <Form.Group>
            <Form.Control defaultValue={props["cardNumber#cardNumber"]} name="cardNumber" id="cardNumber" type="text" />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Label id="label_accountHolder" htmlFor="accountHolder">
            口座名義 (ｶﾀｶﾅ)
          </Form.Label>
          <Form.Group>
            <Form.Control
              defaultValue={props["accountHolder#accountHolder"]}
              name="accountHolder"
              id="accountHolder"
              type="text"
            />
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Label id="endp1">
            <b>※</b> 口座は本人のものに限る
          </Form.Label>
        </Col>
      </Form.Row>

      <Col md={4}>
        <Form.Label id="hour_pay_label" htmlFor="hour_pay">
          時給
        </Form.Label>
        <Form.Control id="hour_pay" defaultValue={props["hour_pay#hour_pay"]} type="text" name="hour_pay" />
      </Col>

      <Signature {...props} />

      {/*CLICK INVISIBLE BUTTON TO GENERATE PDF DATA
      <div
        style={{ width: 500 + "px", height: 100 + "px", backgroundColor: +"red" }}
        onClick={() => {
          document.getElementById("press").innerHTML = JSON.stringify(
            toTemplate(document.getElementById("pageContent"), [595.28, 841.89])
          );
        }}
      ></div>
      <div>
        <pre id="press"></pre>
      </div>
      */}
    </React.Fragment>
  );
}

const component = {};
component[label] = (props) => <BD {...props}></BD>;

component[label + "PageName"] = "銀行振込承諾書";
component[label + "Template"] = {
  data: {
    interviewed: { page: 0, normalized: { x: 0, y: 0.10952168749999422 }, wraps: false, x: 40, y: 801.78 },
    h2_title: { page: 0, normalized: { x: 0, y: 4.680861687499994 }, wraps: false, x: 40, y: 797.21 },
    label_interviewee: {
      page: 0,
      normalized: { x: 490.66928716216216, y: 40.640482416666664 },
      wraps: false,
      x: 530.67,
      y: 761.25,
    },
    interviewee: {
      page: 0,
      normalized: { x: 345.8410810810811, y: 57.19571720833333 },
      wraps: false,
      x: 385.84,
      y: 744.69,
      width: 167.11058445945946,
      height: 21.737674062500002,
    },
    subtitle1: { page: 0, normalized: { x: 0, y: 87.06021793749998 }, wraps: false, x: 40, y: 714.83 },
    label_bankNum: { page: 0, normalized: { x: 2.321081081081081, y: 99.75838460416666 }, wraps: false, x: 42.32, y: 702.13 },
    bankNum: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 116.31361939583333 },
      wraps: false,
      x: 42.32,
      y: 685.58,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    label_bankBranch: {
      page: 0,
      normalized: { x: 259.96108108108103, y: 99.75838460416666 },
      wraps: false,
      x: 299.96,
      y: 702.13,
    },
    bankBranch: {
      page: 0,
      normalized: { x: 259.96108108108103, y: 116.31361939583333 },
      wraps: false,
      x: 299.96,
      y: 685.58,
      width: 252.9978378378378,
      height: 21.737674062500002,
    },
    current: {
      page: 0,
      normalized: { x: 4.642162162162162, y: 146.178120125 },
      wraps: false,
      x: 44.64,
      y: 655.71,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_ordinary: {
      page: 0,
      normalized: { x: 15.319135135135134, y: 142.32105199999998 },
      wraps: false,
      x: 55.32,
      y: 659.57,
    },
    ordinary: {
      page: 0,
      normalized: { x: 174.84993918918917, y: 146.178120125 },
      wraps: false,
      x: 214.85,
      y: 655.71,
      width: 8.355891891891892,
      height: 9.14268,
    },
    label_current: {
      page: 0,
      normalized: { x: 185.52691216216215, y: 142.32105199999998 },
      wraps: false,
      x: 225.53,
      y: 659.57,
    },
    label_cardNumber: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 162.63811866666666 },
      wraps: false,
      x: 42.32,
      y: 639.25,
    },
    cardNumber: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 179.19335345833332 },
      wraps: false,
      x: 42.32,
      y: 622.7,
      width: 510.6378378378378,
      height: 21.737674062500002,
    },
    label_accountHolder: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 204.69285939583332 },
      wraps: false,
      x: 42.32,
      y: 597.2,
    },
    accountHolder: {
      page: 0,
      normalized: { x: 2.321081081081081, y: 221.2480941875 },
      wraps: false,
      x: 42.32,
      y: 580.64,
      width: 510.6378378378378,
      height: 21.737674062500002,
    },
    endp1: { page: 0, normalized: { x: 2.321081081081081, y: 246.747600125 }, wraps: false, x: 42.32, y: 555.14 },
    hour_pay_label: { page: 0, normalized: { x: 6.963243243243244, y: 258.937840125 }, wraps: false, x: 46.96, y: 542.95 },
    hour_pay: {
      page: 0,
      normalized: { x: 6.963243243243244, y: 275.49307491666667 },
      wraps: false,
      x: 46.96,
      y: 526.4,
      width: 243.7135135135135,
      height: 21.737674062500002,
    },
    label_signature: {
      page: 0,
      normalized: { x: 483.24908108108104, y: 297.2307489791667 },
      wraps: false,
      x: 523.25,
      y: 504.66,
    },
  },
  options: {
    rootId: "pageContent",
    format: [595.28, 841.89],
    margin: 40,
    width: 515.28,
    height: 761.89,
    inputColor: { type: "RGB", red: 1, green: 0, blue: 0 },
    pixelBreakPage: 1500,
  },
};

export default component;
